import {
    Button,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStateRef from 'react-usestateref';
import {
    CommonDispatcher,
    UploadImages
} from '../../../../redux/Common/action';
import {
    SET_FUND_EQUITY_DEAL_DATA,
    SET_FUND_EQUITY_DEAL_FORM_ERROR,
    UPDATE_FUNDS_WIZARD_FORM_DATA
} from '../../../../redux/FundDeals/actionTypes';
import { UpdateDeal } from '../../../../redux/FundDeals/actions';
import { RootState } from '../../../../redux/store';
import { getQuery, removeNonNumeric } from '../../../../utils/common';
import InfoIconComponent from '../../../Common/InfoIconComponent';
import RenderAutocompleteTextField from '../../../InputComponents/AutocompleteTextField';
import RenderCheckboxComponent from '../../../InputComponents/CheckboxComponent';
import RenderDatePicker from '../../../InputComponents/DatePickerComponent/RenderDatePicker';
import RenderMaskedTextField from '../../../InputComponents/MaskedTextField';
import RenderSelectField from '../../../InputComponents/SelectField/RenderSelectField';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import RenderToggleSwitch from '../../../InputComponents/ToggleSwitch/RenderToggleSwitch';
import { UpdatePost } from '../Common';
import { validateSecondStep } from '../Common/validate';
import countriesArray from './../../../../utils/countries.json';
import currenciesData from './../../../../utils/currency.json';
import style from './Style.module.scss';

const lastFundGenerationList = [
    {
        title: 'Not Applicable',
        value: 'N/A'
    },
    {
        title: 'I',
        value: 'I'
    },
    {
        title: 'II',
        value: 'II'
    },
    {
        title: 'III',
        value: 'III'
    },
    {
        title: 'IV',
        value: 'IV'
    },
    {
        title: 'V',
        value: 'V'
    },
    {
        title: 'VI',
        value: 'VI'
    },
    {
        title: 'VII',
        value: 'VII'
    },
    {
        title: 'VIII',
        value: 'VIII'
    },
    {
        title: 'IX',
        value: 'IX'
    },
    {
        title: 'X',
        value: 'X'
    },
    {
        title: 'XI',
        value: 'XI'
    },
    {
        title: 'XII',
        value: 'XII'
    },
    {
        title: 'XIII',
        value: 'XIII'
    },
    {
        title: 'XIV',
        value: 'XIV'
    },
    {
        title: 'XV',
        value: 'XV'
    },
    {
        title: 'XVI',
        value: 'XVI'
    },
    {
        title: 'XVII',
        value: 'XVII'
    },
    {
        title: 'XVIII',
        value: 'XVIII'
    },
    {
        title: 'XIX',
        value: 'XIX'
    },
    {
        title: 'XX',
        value: 'XX'
    }
];

const currentFundGenerationList = [...lastFundGenerationList].slice(
    1,
    [...lastFundGenerationList].length
);

const fundList = [
    { title: 'Yes', value: 'YES', disabled: false },
    { title: 'No', value: 'NO', disabled: false }
];

const investorTypeListArray = [
    { title: 'Generalist', value: 1 },
    { title: 'Sector specialist', value: 2 },
    { title: 'Emerging Manager​', value: 3 },
    { title: 'Secondary​', value: 4 },
    { title: 'Distressed', value: 5 }
];

const StepTwoComponent = ({
    equity_id: post_id,
    CommonDispatcher,
    UpdateDeal,
    UploadImages
}: any) => {
    const history = useHistory();
    const query = getQuery(history);

    const state = useSelector((store: RootState) => store);
    let companyFoundedList = state?.commonAppData?.foundedList || [];
    let sdgGoalsReduxData = state?.commonAppData?.sdgGoals || [];
    let sectorsReduxData = state?.commonAppData?.sectors || [];
    let regionsReduxData = state?.commonAppData?.regions || [];

    let [, ...restCompanyFoundedList] = companyFoundedList;
    const [lastFundVintageList, setLastFundVintageList] = useState(
        [
            {
                title: 'Not Applicable',
                value: 'N/A'
            }
        ].concat(restCompanyFoundedList)
    );
    const sdgGoalsArray = sdgGoalsReduxData || [];
    const regionsArray = regionsReduxData || [];
    const activeStep = state?.FundDeals?.wizard?.currentStep || 0;
    const deal_data_error = state?.FundDeals?.deal_data_error || {};
    const businessModelLisData = state?.commonAppData?.businessModel || [];

    const [selectedCloseEndFund, setCloseEndFund] = useState(
        fundList?.[0]?.value
    );
    const [selectedFundStructure, setFundStructure] = useState(
        fundList?.[0]?.value
    );
    const [errors, setError, errorRef] = useStateRef({
        ...deal_data_error[activeStep]
    }) as [any, any, any];
    const { control, register, reset, getValues } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    const [businessModel, setBusinessModel] = useState([]);

    const {
        fields: investorsFields,
        append: investorsAppend,
        remove: investorsRemove
    } = useFieldArray({
        control,
        name: 'investors'
    });

    const sectorsArray = sectorsReduxData;
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const currencies = currenciesData || [];
    const CURRENTLY_RAISING_LIST = state?.commonAppData?.stages || [];
    const [sdgGoals, setSdgGoals] = useState([]);
    const [sdgGoalsList, setSdgGoalsList] = useState([]);
    const [investorType, setInvestorType] = useState([]);
    const [investorTypeList, setInvestorTypeList] = useState(
        investorTypeListArray
    );
    const [regions, setRegions] = useState([]);
    const [preferred_stage, setCurrentFundingStageId] = useState([]);
    const [no_commitments, setNoCommitments] = useState(false);
    const [first_time_fund, setFirstTimeFund] = useState(false);

    const [maturity_date, setMaturityDate] = useState(
        moment().add(182, 'days')
    );
    const [sectorsList, setSectorsList] = useState([]);
    const [sectors, setSectors] = useState([]);
    const MAX_HQ_GEO_TAGS_SIZE = 2;
    const [currency_id, setRaisingCurrency] = useState();
    const [target_raise, setTargetRaise] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState();
    const [currencyList, setCurrencyList] = useState([]);
    const steps = state?.FundDeals?.wizard?.formSteps || [];
    const errorStep = state?.FundDeals?.wizard?.errorStep || [];
    const deal_data = state?.FundDeals?.deal_data || {};
    const [countriesList, setCountriesList] = useState([]);
    const [regionsList, setRegionsList] = useState([]);
    const [ops_geos, setOperationalGeos] = useState([]);
    const [selectedOpsGeosList, setSelectedOpsGeosList] =
        useState(countriesList);

    const { setValue } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleSdgGoalsChange = (value) => {
        setSdgGoals(value);
    };

    let fieldsToValidate = [
        'target_raise',
        'last_fund_generation',
        'last_fund_vintage',
        'is_closed_fund',
        'is_fund_lp_gp',
        'current_fund_generation',
        'investors',
        'currency_id',
        'num_planned_invesments',
        'min_fund_size',
        'hard_cap_of_the_fund',
        'investor_type',
        'sectors',
        'stages_target',
        'ops_geos',
        'sdg_sectors',
        'regions',
        'business_model',
        'first_time_fund',
        'commitments_till_date',
        'no_commitments'
    ];

    /* if (no_commitments) {
        fieldsToValidate = fieldsToValidate.filter(
            (d) => d !== 'commitments_till_date'
        );
    } else if (
        !no_commitments &&
        fieldsToValidate.some((d) => d !== 'commitments_till_date')
    ) {
        fieldsToValidate.push('commitments_till_date');
    } */

    useEffect(() => {
        let obj: any = {};

        Object.entries(deal_data).forEach(([k]) => {
            if (fieldsToValidate.includes(k)) {
                obj[k] = deal_data[k];
            }
        });

        obj.currency_id = parseInt(obj.currency_id);

        if (obj.sdg_sectors && obj.sdg_sectors.length) {
            obj.sdg_sectors = [
                ...sdgGoalsArray.filter((d) => {
                    return obj.sdg_sectors.includes(d?.sdg_sector_id);
                })
            ].map((d) => ({
                title: d?.label,
                value: d?.sdg_sector_id
            }));
            setSdgGoals(obj.sdg_sectors);
        }

        if (obj?.business_model && obj?.business_model.length) {
            obj.business_model = obj?.business_model?.map((d) => d?.id);
        }
        if (obj?.investor_type && obj?.investor_type.length) {
            obj.investor_type = obj?.investor_type?.map((d) => d?.id);
        }
        if (obj?.stages_target && obj?.stages_target.length) {
            obj.stages_target = obj?.stages_target?.map(
                (d) => d?.funding_stage_id
            );
        }

        if (obj?.regions && obj?.regions.length) {
            obj.regions = obj?.regions?.map((d) => d?.region_id);
        }

        if (obj.business_model && obj.business_model.length) {
            obj.business_model = [
                ...businessModelLisData.filter((d) =>
                    obj.business_model.includes(d?.value)
                )
            ];
            setBusinessModel(obj.business_model);
        }

        obj.equity_id = deal_data?.equity_id;

        obj?.is_closed_fund === false
            ? setCloseEndFund('NO')
            : setCloseEndFund('YES');
        obj?.is_fund_lp_gp === false
            ? setFundStructure('NO')
            : setFundStructure('YES');

        if (obj?.investors && obj?.investors?.length) {
            obj.investors = obj?.investors.filter(
                (d) => Boolean(d?.name) && Boolean(d?.profile_link)
            );
        }
        let currencyData = [];
        currencies.forEach((d) => {
            currencyData.push({ ...d, title: d?.label, value: d?.currency_id });
        });
        setCurrencyList(currencyData);

        setSdgGoalsList(sdgGoalsArray);

        setRegionsList(regionsArray);

        let countriesArrayData = countriesArray
            .map((d) => ({ title: d?.title, value: d?.id }))
            .sort((a, b) => a?.value - b?.value);
        setCountriesList(countriesArrayData);
        if (obj.ops_geos && obj.ops_geos.length) {
            obj.ops_geos = [
                ...countriesArray.filter((d) => obj.ops_geos.includes(d?.id))
            ].map((d) => ({
                title: d?.title,
                value: d?.id
            }));

            setOperationalGeos(obj.ops_geos);
        }

        if (obj.regions && obj.regions.length) {
            obj.regions = [
                ...regionsArray.filter((d) =>
                    obj.regions.includes(d?.region_id)
                )
            ].map((d) => ({
                title: d?.label,
                value: d?.region_id
            }));
            setRegions(obj.regions);
        }
        if (obj.sectors && obj.sectors.length) {
            obj.sectors = [
                ...sectorsArray.filter((d) => obj.sectors.includes(d?.id))
            ];
            setSectors(obj.sectors);
        }

        if (obj.investor_type && obj.investor_type.length) {
            obj.investor_type = [
                ...investorTypeListArray.filter((d) =>
                    obj.investor_type.includes(d?.value)
                )
            ];

            setInvestorType(obj.investor_type);
        }
        if (obj.stages_target && obj.stages_target.length) {
            const a = CURRENTLY_RAISING_LIST.filter((d) =>
                obj.stages_target.includes(d?.value)
            );

            obj.stages_target = [...a];

            setCurrentFundingStageId(obj.stages_target);
        }
        let sectorsData = [];
        sectorsArray.forEach((d) => {
            sectorsData.push({ title: d?.title, value: d?.id });
        });
        sectorsData = sectorsData.sort((a, b) => a?.value - b?.value);
        setSectorsList(sectorsData);
        if (obj?.target_raise) {
            obj.target_raise = removeNonNumeric(
                parseInt(obj?.target_raise.slice(0, 18))
            );
        }

        if (deal_data?.commitments_till_date) {
            setNoCommitments(deal_data?.no_commitments);
            obj.commitments_till_date = deal_data?.commitments_till_date;
        } else {
            if (obj?.commitments_till_date) {
                obj.commitments_till_date = removeNonNumeric(
                    parseInt(obj?.commitments_till_date.slice(0, 18))
                );
            }
        }

        if (deal_data?.first_time_fund) {
            setFirstTimeFund(deal_data?.first_time_fund);

            obj.last_fund_generation = deal_data?.first_time_fund
                ? 'N/A'
                : null;
            obj.last_fund_vintage = deal_data?.first_time_fund ? 'N/A' : null;
        } else {
            obj.last_fund_generation = deal_data?.last_fund_generation || null;
            obj.last_fund_vintage = deal_data?.last_fund_vintage || null;
        }

        if (obj?.currency_id) {
            let curr =
                currencyData.find((d) => d?.value === obj?.currency_id) || {};
            setCurrencySymbol(curr?.symbol);
        } else {
            obj.currency_id = currencyData[0].value;
        }

        if (!obj?.investors || (obj?.investors && !obj?.investors.length)) {
            obj.investors = [
                {
                    name: '',
                    profile_link: ''
                }
            ];
        }

        reset(obj);
        setRaisingCurrency(currencyData[0].value);
        setCurrencySymbol(currencyData[0].symbol);
    }, [deal_data]);

    useEffect(() => {
        if (activeStep > 1) setWizardError();
    }, [errorStep]);
    const handleBusinessModelChange = (value) => {
        setBusinessModel(value);
    };

    const handleCloseEndFund = (e, type) => {
        setCloseEndFund(type);
    };

    const handleFundStructure = (e, type) => {
        setFundStructure(type);
    };

    const setWizardError = () => {
        let { ...rest } = errorRef?.current;
        CommonDispatcher(SET_FUND_EQUITY_DEAL_FORM_ERROR, {
            [activeStep]: rest
        });
        const errItemsCount = Object.keys(rest).filter((d) => rest[d])?.length;
        let errorStepObj = { ...errorStep, [activeStep]: errItemsCount };
        CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
            errorStep: errorStepObj
        });
    };

    const next = () => {
        if (activeStep < steps?.length) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep + 1
            });
        }
    };

    const handleBack = (flag = false) => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep - 1
            });
        }

        let data: any = getFormValues(getValues());

        const err = validateSecondStep(data, CURRENTLY_RAISING_LIST);
        setError(err);
        if (data?.investors && data?.investors.length) {
            data.investors = data?.investors.filter(
                (d) => Boolean(d?.name) && Boolean(d?.profile_link)
            );
        } else data.investors = [];

        if (selectedCloseEndFund) {
            data.is_closed_fund = selectedCloseEndFund === 'YES' ? true : false;
        }
        if (selectedFundStructure) {
            data.is_fund_lp_gp = selectedFundStructure === 'YES' ? true : false;
        }
        if (data?.investor_type && data?.investor_type.length) {
            data.investor_type = data?.investor_type.map((d) => d?.value);
        }

        if (data?.sdg_sectors && data?.sdg_sectors.length) {
            data.sdg_sectors = data?.sdg_sectors.map((d) => d?.value);
        }

        if (data?.sectors && data?.sectors.length) {
            data.sectors = data?.sectors.map((d) => d?.value);
        }
        if (data?.stages_target && data?.stages_target.length) {
            data.stages_target = data?.stages_target.map((d) => d?.value);
        }
        if (data?.ops_geos && data?.ops_geos.length) {
            data.ops_geos = data?.ops_geos.map((d) => d?.value);
        }
        CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, data);
        flag && setWizardError();
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getFormValues(getValues());

        data.equity_id = post_id || deal_data?.equity_id;
        if (selectedCloseEndFund) {
            data.is_closed_fund = selectedCloseEndFund === 'YES' ? true : false;
        }
        if (selectedFundStructure) {
            data.is_fund_lp_gp = selectedFundStructure === 'YES' ? true : false;
        }
        if (data?.investor_type && data?.investor_type.length) {
            data.investor_type = data?.investor_type.map((d) => d?.value);
        }

        if (data?.sdg_sectors && data?.sdg_sectors.length) {
            data.sdg_sectors = data?.sdg_sectors.map((d) => d?.value);
        }

        if (data?.regions && data?.regions.length) {
            data.regions = data?.regions.map((d) => d?.id);
        }

        if (data?.sectors && data?.sectors.length) {
            data.sectors = data?.sectors.map((d) => d?.id || d?.value);
        }

        if (data?.stages_target && data?.stages_target.length) {
            data.stages_target = data?.stages_target.map((d) => d?.value);
        }

        if (ops_geos && ops_geos.length) {
            data.ops_geos = ops_geos.map((d) => d?.value);
        }

        if (data?.investors && data?.investors?.length) {
            data.investors = data?.investors.filter(
                (d) => Boolean(d?.name) && Boolean(d?.profile_link)
            );
        } else data.investors = null;

        if (no_commitments) {
            data.commitments_till_date = null;
        }

        data.first_time_fund = first_time_fund;
        if (first_time_fund) {
            data.last_fund_generation = 'N/A';
            data.last_fund_vintage = 'N/A';
        }

        if (data?.equity_id) {
            setLoading(true);
            UpdatePost(
                (data) => UpdateDeal(token, data),
                CommonDispatcher,
                data,
                UploadImages,
                setLoading,
                next,
                setWizardError
            );
        }
    };

    const getData = (data) => {
        return data && data.length ? data.map((d) => d?.value) : [];
    };

    const getFormValues = (obj) => {
        let data = {};
        obj.business_model =
            obj?.business_model && obj?.business_model?.length
                ? getData(obj?.business_model)
                : null;
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            setError({ ...errorRef.current, [key]: !value });
            data[key] = value;
        });
        return data;
    };

    const onCurrencyChange = (e) => {
        const value = e.target.value;
        const symbol = currencyList.find((d) => d?.currency_id === value);
        setCurrencySymbol(symbol?.symbol);
        setRaisingCurrency(symbol.value);
    };

    const handleCurrentFundingStageChange = (value) => {
        setCurrentFundingStageId(value);
    };

    const validateInvestorsProfileLink = (index) => {
        const inValidInvestors = errors?.inValidInvestors;
        if (inValidInvestors && inValidInvestors.length) {
            const invalidUrl = inValidInvestors?.[index]?.profile_link
                ? 'Profile link is mandatory'
                : inValidInvestors?.[index]?.inValidProfileUrl
                ? 'Invalid profile link'
                : null;
            return (
                <FormHelperText
                    error={true}
                    className={invalidUrl ? 'visible' : 'invisible'}
                >
                    {invalidUrl}
                </FormHelperText>
            );
        }
    };

    const handleRegionsChange = (values) => {
        setRegions(values);
        let geos = values
            .map((d) => d.countries)
            .flat()
            .map((d) => ({
                title: d?.label,
                value: d?.country_id
            }))
            .filter((d) => d.value && d.title);
        const distinct = [];
        for (let i = 0; i < geos.length; i++) {
            if (!distinct.map((d: any) => d.value).includes(geos[i].value)) {
                distinct.push(geos[i]);
            }
        }
        setOperationalGeos(distinct);
        setSelectedOpsGeosList(distinct);

        setValue('ops_geos', distinct);
    };

    const handleOperationalGeosChange = (values) => {
        setOperationalGeos(values);
    };

    const handleMaturityDate = (date) => {
        const exp_date = moment(date);
        setMaturityDate(exp_date);
    };

    const handleSetNoCommitment = (isChecked) => {
        setNoCommitments(isChecked);
    };

    const handleFirstTimeFund = (isChecked) => {
        let values = getValues();
        if (isChecked) {
            values.last_fund_generation = 'N/A';
            values.last_fund_vintage = 'N/A';
        } else {
            values.last_fund_generation = null;
            values.last_fund_vintage = null;
        }
        reset(values);
        setFirstTimeFund(isChecked);
    };

    const handleInvestorTypeChange = (value) => {
        setInvestorType(value);
    };

    const handleSectorsChange = (value) => {
        setSectors(value);
    };

    return (
        <Container maxWidth="md">
            <Grid item xs={12} sm={10} className="m-auto pb-5">
                <div className="">
                    <form>
                        <div data-aos="fade-left" data-aos-duration="200">
                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="last_fund_generation"
                                        render={({ onChange, value }) => {
                                            return (
                                                <RenderSelectField
                                                    id="last_fund_generation"
                                                    label={
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            Last Fund Generation
                                                            *
                                                            <div className="d-flex align-items-center flex-direction-row-reverse px-0 pt-0">
                                                                <span>
                                                                    First time
                                                                    fund
                                                                </span>
                                                                <Controller
                                                                    name={`first_time_fund`}
                                                                    render={({
                                                                        onChange,
                                                                        value
                                                                    }) => {
                                                                        return (
                                                                            <RenderCheckboxComponent
                                                                                name={`first_time_fund`}
                                                                                classes={{
                                                                                    root: style.checkboxHeight
                                                                                }}
                                                                                checked={
                                                                                    first_time_fund
                                                                                }
                                                                                onChange={(
                                                                                    e: any
                                                                                ) => {
                                                                                    const isChecked =
                                                                                        e
                                                                                            ?.target
                                                                                            ?.checked;
                                                                                    onChange(
                                                                                        isChecked
                                                                                    );
                                                                                    handleFirstTimeFund(
                                                                                        isChecked
                                                                                    );
                                                                                }}
                                                                                color="primary"
                                                                            />
                                                                        );
                                                                    }}
                                                                    control={
                                                                        control
                                                                    }
                                                                    defaultValue={
                                                                        first_time_fund
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    disabled={first_time_fund}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-raising-currency"
                                                    dropdownListHeight={250}
                                                    dataArray={
                                                        lastFundGenerationList
                                                    }
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.currency_id ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.currency_id &&
                                            'Select currency'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="last_fund_vintage"
                                        render={({ onChange, value }) => {
                                            return (
                                                <RenderSelectField
                                                    id="last_fund_vintage"
                                                    label={
                                                        'Last fund vintage *'
                                                    }
                                                    disabled={first_time_fund}
                                                    value={value}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                    }}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-raising-currency"
                                                    dropdownListHeight={250}
                                                    dataArray={
                                                        lastFundVintageList
                                                    }
                                                />
                                            );
                                        }}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <RenderToggleSwitch
                                        id="raiseing_close_fund"
                                        label="Are you raising a closed end fund?"
                                        value={selectedCloseEndFund}
                                        onChange={handleCloseEndFund}
                                        dataArray={fundList}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <RenderToggleSwitch
                                        id="raiseing_lpgp_fund"
                                        label="Is the fund a LP/GP structure?"
                                        value={selectedFundStructure}
                                        onChange={handleFundStructure}
                                        dataArray={fundList}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1 mt-5">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="current_fund_generation"
                                        render={({ onChange, value }) => {
                                            return (
                                                <RenderSelectField
                                                    id="current_fund_generation"
                                                    label="Current fund generation *"
                                                    value={value}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                    }}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-raising-currency"
                                                    dropdownListHeight={250}
                                                    dataArray={
                                                        currentFundGenerationList
                                                    }
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={currency_id}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="planned_first_closing"
                                        render={({ onChange, value }) => {
                                            return (
                                                <RenderDatePicker
                                                    id="planned_first_closing"
                                                    shrink={true}
                                                    value={maturity_date}
                                                    removeMarginAndPadding={
                                                        true
                                                    }
                                                    showInputLabel={true}
                                                    onChange={(data) => {
                                                        onChange(data);
                                                        handleMaturityDate(
                                                            data
                                                        );
                                                    }}
                                                    label="Planned first closing *"
                                                    format="DD-MM-YYYY"
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={maturity_date}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="currency_id"
                                        render={({ onChange, value }) => {
                                            return (
                                                <RenderSelectField
                                                    id="currency_id"
                                                    label="Fund Currency *"
                                                    value={value}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                        onCurrencyChange(e);
                                                    }}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-raising-currency"
                                                    dropdownListHeight={250}
                                                    dataArray={currencyList}
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={currency_id}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.currency_id ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.currency_id &&
                                            'Select currency'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="target_raise"
                                        as={
                                            <RenderMaskedTextField
                                                id="target_raise"
                                                value={target_raise}
                                                autoComplete="off"
                                                startAdornment={currencySymbol}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Target fund size in Million *"
                                            />
                                        }
                                        control={control}
                                        defaultValue={target_raise}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.target_raise ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.target_raise &&
                                            'Target Raise is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="num_planned_invesments"
                                        as={
                                            <RenderMaskedTextField
                                                id="num_planned_invesments"
                                                value={target_raise}
                                                autoComplete="off"
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Number of planned investments at target fund size"
                                            />
                                        }
                                        control={control}
                                        defaultValue={target_raise}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.target_raise ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.target_raise &&
                                            'Target Raise is mandatory'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="commitments_till_date"
                                        as={
                                            <RenderMaskedTextField
                                                disabled={no_commitments}
                                                autoComplete="off"
                                                startAdornment={currencySymbol}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label={
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        Commitments till-date *
                                                        <div className="d-flex align-items-center flex-direction-row-reverse px-0 pt-0">
                                                            <span>None</span>
                                                            <Controller
                                                                name={`no_commitments`}
                                                                render={({
                                                                    onChange,
                                                                    value
                                                                }) => {
                                                                    return (
                                                                        <RenderCheckboxComponent
                                                                            classes={{
                                                                                root: style.checkboxHeight
                                                                            }}
                                                                            checked={
                                                                                no_commitments
                                                                            }
                                                                            onChange={(
                                                                                e: any
                                                                            ) => {
                                                                                const isChecked =
                                                                                    e
                                                                                        ?.target
                                                                                        ?.checked;
                                                                                onChange(
                                                                                    isChecked
                                                                                );
                                                                                handleSetNoCommitment(
                                                                                    isChecked
                                                                                );
                                                                            }}
                                                                            color="primary"
                                                                        />
                                                                    );
                                                                }}
                                                                control={
                                                                    control
                                                                }
                                                                defaultValue={
                                                                    no_commitments
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        }
                                        control={control}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.commitments_till_date
                                                ? 'mb-1'
                                                : ''
                                        }
                                    >
                                        {errors?.commitments_till_date &&
                                            'Commitments is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="min_fund_size"
                                        as={
                                            <RenderMaskedTextField
                                                id="min_fund_size"
                                                autoComplete="off"
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Minimum fund size / Planned first closing"
                                            />
                                        }
                                        control={control}
                                        defaultValue={null}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="hard_cap_of_the_fund"
                                        as={
                                            <RenderMaskedTextField
                                                id="hard_cap_of_the_fund"
                                                autoComplete="off"
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Hard-Cap of the fund"
                                            />
                                        }
                                        control={control}
                                        defaultValue={null}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} className="py-1">
                                    <Controller
                                        name="investor_type"
                                        render={({ onChange }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="investor_type"
                                                    value={investorType}
                                                    label="What type of investor are you?"
                                                    size="small"
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    paperClass={
                                                        style.autocompleteBackground
                                                    }
                                                    options={investorTypeList}
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleInvestorTypeChange(
                                                            data
                                                        );
                                                    }}
                                                    inputPlaceholder="Search sectors"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.sectors ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.sectors &&
                                            'Sectors is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} className="py-1">
                                    <Controller
                                        name="sectors"
                                        render={({ onChange }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="sectors"
                                                    value={sectors}
                                                    label="Sectors targeted *"
                                                    size="small"
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    paperClass={
                                                        style.autocompleteBackground
                                                    }
                                                    options={sectorsList}
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleSectorsChange(
                                                            data
                                                        );
                                                    }}
                                                    inputPlaceholder="Search sectors"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.sectors ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.sectors &&
                                            'Sectors is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="stages_target"
                                        render={({ onChange }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="stages_target"
                                                    value={preferred_stage}
                                                    label="Stages targeted *"
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    size="small"
                                                    options={
                                                        CURRENTLY_RAISING_LIST
                                                    }
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleCurrentFundingStageChange(
                                                            data
                                                        );
                                                    }}
                                                    inputPlaceholder="Search stages"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.sectors ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.sectors &&
                                            'Sectors is mandatory'}
                                    </FormHelperText>
                                </Grid>

                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="business_model"
                                        render={({ onChange }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="business_model"
                                                    value={businessModel}
                                                    label="Business Model *"
                                                    size="small"
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    paperClass={
                                                        style.autocompleteBackground
                                                    }
                                                    options={
                                                        businessModelLisData
                                                    }
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleBusinessModelChange(
                                                            data
                                                        );
                                                    }}
                                                    inputPlaceholder="Search business model"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.business_model ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.business_model &&
                                            'Business Model is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} className="py-1">
                                    <Controller
                                        name="sdg_sectors"
                                        render={({ onChange }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="sdg_sectors"
                                                    value={sdgGoals}
                                                    label={
                                                        <span>
                                                            SDG Goals{' '}
                                                            <InfoIconComponent />
                                                        </span>
                                                    }
                                                    size="small"
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    paperClass={
                                                        style.autocompleteBackground
                                                    }
                                                    options={sdgGoalsList}
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleSdgGoalsChange(
                                                            data
                                                        );
                                                    }}
                                                    inputPlaceholder="Search sdg goals"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.ops_geos ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.ops_geos &&
                                            'SDG Goals is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} className="py-1">
                                    <Controller
                                        name="regions"
                                        render={({ onChange, value }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="regions"
                                                    value={regions}
                                                    label="Operational Regions *"
                                                    size="small"
                                                    inputAutoComplete="off"
                                                    paperClass={
                                                        style.autocompleteBackground
                                                    }
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleRegionsChange(
                                                            data
                                                        );
                                                    }}
                                                    options={regionsList}
                                                    inputPlaceholder="Search operational regions"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.regions ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.regions &&
                                            'Region is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            {regions && regions.length ? (
                                <Grid container spacing={2} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        <Controller
                                            name="ops_geos"
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        value={ops_geos}
                                                        label="Operational Countries *"
                                                        size="small"
                                                        inputAutoComplete="off"
                                                        paperClass={
                                                            style.autocompleteBackground
                                                        }
                                                        disableCloseOnSelect={
                                                            !smallScreen
                                                        }
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleOperationalGeosChange(
                                                                data
                                                            );
                                                        }}
                                                        options={
                                                            selectedOpsGeosList
                                                        }
                                                        inputPlaceholder="Search operational countries"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.ops_geos ? 'mb-1' : ''
                                            }
                                        >
                                            {errors?.ops_geos &&
                                                'Operational geographies is mandatory'}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                            ) : null}

                            <Grid container spacing={2} className="mt-5 mb-3">
                                <Grid
                                    item
                                    xs={12}
                                    className="py-1 d-flex align-items-center"
                                >
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        className="colorPrimary font-weight-bold fs-16 mr-3"
                                    >
                                        Lead LP investors * :
                                    </Typography>
                                    <FormHelperText error={true}>
                                        {errors?.investors &&
                                            !errors?.investors.length &&
                                            'At least 1 investor is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                className="my-1 bgColorLightGray"
                            >
                                <Grid item xs={12} className="py-1">
                                    {investorsFields.map((item, index) => {
                                        const nameError =
                                            errors?.inValidInvestors?.[index]
                                                ?.name;
                                        return (
                                            <div
                                                key={item?.id}
                                                className="appCard p-3 mb-4"
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Controller
                                                            as={
                                                                <RenderTextField
                                                                    id={`investors[${index}].name`}
                                                                    inputContainerClassName={
                                                                        'w-100'
                                                                    }
                                                                    autoComplete="off"
                                                                    label={`Investment firm ${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                />
                                                            }
                                                            name={`investors[${index}].name`}
                                                            control={control}
                                                            defaultValue={
                                                                item.name ||
                                                                null
                                                            }
                                                        />
                                                        <FormHelperText
                                                            error={true}
                                                            className={
                                                                nameError
                                                                    ? 'mb-1'
                                                                    : ''
                                                            }
                                                        >
                                                            {nameError &&
                                                                'Founder name is mandatory'}
                                                        </FormHelperText>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Controller
                                                            name={`investors[${index}].profile_link`}
                                                            as={
                                                                <RenderTextField
                                                                    id={`investors[${index}].profile_link`}
                                                                    inputContainerClassName={
                                                                        'w-100'
                                                                    }
                                                                    autoComplete="off"
                                                                    placeholder={`e.g. https://www.yourwebsitename.com`}
                                                                    label={`Website ${
                                                                        index +
                                                                        1
                                                                    } (eg. LinkedIn/Website)`}
                                                                />
                                                            }
                                                            control={control}
                                                            inputRef={register}
                                                            defaultValue={
                                                                item.profile_link ||
                                                                null
                                                            }
                                                        />
                                                        {validateInvestorsProfileLink(
                                                            index
                                                        )}
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            color="secondary"
                                                            variant="text"
                                                            type="button"
                                                            className="float-right"
                                                            size="small"
                                                            onClick={() => {
                                                                investorsRemove(
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            <Delete className="mr-2" />{' '}
                                                            Delete
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="mb-3">
                                <Grid item xs={12} className="mt-3">
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        type="button"
                                        size="small"
                                        disabled={investorsFields?.length >= 10}
                                        className="float-right"
                                        onClick={() => {
                                            investorsAppend({
                                                name: '',
                                                profile_link: ''
                                            });
                                        }}
                                    >
                                        <Add className="mr-2" />
                                        Add a new founder
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="mt-5 py-4 border-top mb-sm-12 d-flex align-items-center justify-content-center btn-submit-post w-100">
                            <Button
                                color="secondary"
                                variant="text"
                                type="button"
                                className={`fs-16 text-inherit`}
                                onClick={() => handleBack(true)}
                            >
                                Back
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onSave}
                                disabled={isLoading}
                                className="fs-16 text-inherit btn-submit-style ml-5"
                            >
                                Next{' '}
                                {isLoading ? (
                                    <CircularProgress
                                        size="1.3rem"
                                        className="circular-progress"
                                    />
                                ) : null}
                            </Button>
                        </div>
                    </form>
                </div>
            </Grid>
        </Container>
    );
};

export default connect(null, {
    CommonDispatcher,
    UpdateDeal,
    UploadImages
})(StepTwoComponent);
