import * as ACTIONS from './actionTypes';
import moment from 'moment';

const wizard = {
    currentStep: 0,
    errorStep: [],
    formSteps: [
        'Basic info',
        'Financial info',
        'Links',
        'Investors',
        'Review',
        'Review'
    ]
};
const initialValues = {
    wizard,
    deal_data: {},
    tableHeader: [
        { id: 'actions1', label: '' },
        { id: 'admin_status', label: 'Admin Status' },
        { id: 'status', label: 'Deal Status' },
        { id: 'deal_owner_name', label: 'Sponsored By' },
        { id: 'actions2', label: 'Actions' },
        { id: 'received_at', label: 'Received Date' },
        { id: 'deadline', label: 'Deadline' },
        { id: 'hq_geos', label: 'HQ Country' },
        { id: 'company_logo', label: 'Company Logo' },
        { id: 'company_name', label: 'Company Name' },
        { id: 'target_raise', label: 'Target Raise' },
        { id: 'commitment', label: 'Commitment Received' },
        { id: 'current_funding_stage', label: 'Stage' },
        { id: 'sectors', label: 'Sector' }
    ],
    listingsData: {
        current_page: 0,
        total_pages: 0,
        posts: [
            {
                received_date: moment(),
                deal_status: 'APPROVED',
                post_actions: 'Approve',
                stage: 'Series-A',
                sector: 'Blockchain',
                hqCountry: 'Singapore',
                company_name: 'Pinegray',
                target_raise: '$150m',
                commitment_received: '$180m',
                deadline: moment().add(1, 'days'),
                shared_with: 'VC, Impact'
            }
        ],
        count: 0
    },
    allDealsList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    unApprovedDealsList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    pinegrayDealsList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    othersDealsList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    declinedDealsList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    deal_data_error: {}
};

const FundDealsReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.FUNDS_ALL:
            return {
                ...state,
                allDealsList: {
                    posts: action?.payload?.posts,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.page_size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.FUNDS_UN_APPROVED:
            return {
                ...state,
                unApprovedDealsList: {
                    posts: action?.payload?.posts,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.page_size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.FUNDS_PINEGRAY:
            return {
                ...state,
                pinegrayDealsList: {
                    posts: action?.payload?.posts,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.page_size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.FUNDS_OTHERS:
            return {
                ...state,
                othersDealsList: {
                    posts: action?.payload?.posts,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.page_size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.FUNDS_DECLINED:
            return {
                ...state,
                declinedDealsList: {
                    posts: action?.payload?.posts,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.page_size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.SET_FUND_EQUITY_DEAL_DATA:
            return {
                ...state,
                deal_data: { ...state?.deal_data, ...action?.payload }
            };
        case ACTIONS.UPDATE_FUNDS_WIZARD_FORM_DATA:
            return {
                ...state,
                wizard: { ...state?.wizard, ...action?.payload }
            };
        case ACTIONS.RESET_FUND_EQUITY_DATA:
            return {
                ...state,
                deal_data_error: action?.payload,
                deal_data: action?.payload,
                wizard
            };
        default:
            return state;
    }
};

export default FundDealsReducer;
