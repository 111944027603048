import {
    Button,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import useStateRef from 'react-usestateref';
import { Add, Delete } from '@material-ui/icons';
import { UpdatePost } from '../Common';
import { RootState } from '../../../../redux/store';
import {
    SET_STARTUP_EQUITY_DEAL_DATA,
    UPDATE_WIZARD_FORM_DATA
} from '../../../../redux/Deals/actionTypes';
import {
    addCommas,
    isValidURL,
    removeNonNumeric
} from '../../../../utils/common';
import currencies from './../../../../utils/currency.json';
import countriesArray from './../../../../utils/countries.json';
import {
    CommonDispatcher,
    UploadImages
} from '../../../../redux/Common/action';
import RenderSelectField from '../../../InputComponents/SelectField/RenderSelectField';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import { UpdateDeal } from '../../../../redux/Deals/actions';
import { useCookies } from 'react-cookie';
import { CURRENTLY_RAISING_LIST, STAGE_LIST } from '../../../../constants';

const StepTwoComponent = ({
    equity_id: post_id,
    CommonDispatcher,
    UpdateDeal,
    UploadImages
}: any) => {
    const state = useSelector((store: RootState) => store);
    const activeStep = state?.deals?.wizard?.currentStep || 0;
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const [errors, setError, errorRef] = useStateRef({}) as [any, any, any];
    const {
        control,
        register,
        reset,
        getValues,
        errors: formError,
        formState: { isValid }
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    const {
        fields: foundersFields,
        append: foundersAppend,
        remove: foundersRemove
    } = useFieldArray({
        control,
        name: 'founders'
    });

    const [country_id, setLegalEntityLocation] = useState('');
    const [last_funding_stage_id, setLastFundingStage] = useState('');
    const [current_funding_stage_id, setCurrentlyRaising] = useState();
    const [head_count, setHeadCount] = useState();
    const [currency_id, setRaisingCurrency] = useState();
    const [last12_month_revenue, setLast12MonthRevenue] = useState('');
    const [next12_month_revenue, setNext12MonthRevenue] = useState('');
    const [mom_revenue_growth, setMomGrowth] = useState();
    const [mom_user_growth, setMomUserGrowth] = useState();
    const MAX_CURRENCY_SIZE = 1;
    const [pre_money, setPremoney] = useState('');
    const [target_raise, setTargetRaise] = useState('');
    const [soft_circled, setSoftCircled] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState();
    const [currencyList, setCurrencyList] = useState([]);
    const steps = state?.deals?.wizard?.formSteps || [];
    const deal_data = state?.deals?.deal_data || {};

    useEffect(() => {
        let currencyData = [];
        currencies.forEach((d) => {
            currencyData.push({ ...d, title: d?.label, value: d?.currency_id });
        });
        setCurrencyList(currencyData);
        let obj: any = { ...deal_data };

        if (obj?.target_raise) {
            obj.target_raise = addCommas(
                removeNonNumeric(parseInt(obj?.target_raise.slice(0, 18)))
            );
        }
        if (obj?.pre_money) {
            obj.pre_money = addCommas(
                removeNonNumeric(parseInt(obj?.pre_money.slice(0, 18)))
            );
        }
        if (obj?.soft_circled) {
            obj.soft_circled = addCommas(
                removeNonNumeric(parseInt(obj?.soft_circled.slice(0, 18)))
            );
        }
        if (obj?.last12_month_revenue) {
            obj.last12_month_revenue = addCommas(
                removeNonNumeric(
                    parseInt(obj?.last12_month_revenue.slice(0, 18))
                )
            );
        }
        if (obj?.next12_month_revenue) {
            obj.next12_month_revenue = addCommas(
                removeNonNumeric(
                    parseInt(obj?.next12_month_revenue.slice(0, 18))
                )
            );
        }
        if (post_id) {
            if (obj?.currency_id) {
                let curr =
                    currencyData.find((d) => d?.value === obj?.currency_id) ||
                    {};
                setCurrencySymbol(curr?.symbol);
            } else {
                obj.currency_id = currencyData[0].value;
            }

            if (obj?.founders && !obj?.founders.length) {
                obj.founders = [
                    {
                        name: '',
                        profile_link: ''
                    }
                ];
            } else if (!obj?.founders) {
                obj.founders = [
                    {
                        name: '',
                        profile_link: ''
                    }
                ];
            }
        }
        reset(obj);
        setRaisingCurrency(currencyData[0].value);
        setCurrencySymbol(currencyData[0].symbol);
    }, [deal_data]);

    const next = () => {
        if (activeStep < steps?.length) {
            CommonDispatcher(UPDATE_WIZARD_FORM_DATA, {
                currentStep: activeStep + 1
            });
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_WIZARD_FORM_DATA, {
                currentStep: activeStep - 1
            });
        }
        let data: any = getFormValues(getValues());
        if (data?.founders && data?.founders.length) {
            data.founders = data?.founders.filter(
                (d) => d?.name && d?.profile_link
            );
        }
        CommonDispatcher(SET_STARTUP_EQUITY_DEAL_DATA, data);
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getFormValues(getValues());

        if (data?.founders && data?.founders?.length) {
            data.founders = data?.founders.filter(
                (d) => d?.name && d?.profile_link
            );
        } else data.founders = [];

        if (data?.target_raise) {
            data.target_raise = data?.target_raise.split(',').join('');
        }
        if (data?.pre_money) {
            data.pre_money = data?.pre_money.split(',').join('');
        }
        if (data?.soft_circled) {
            data.soft_circled = data?.soft_circled.split(',').join('');
        }
        if (data?.last12_month_revenue) {
            data.last12_month_revenue = data?.last12_month_revenue
                .split(',')
                .join('');
        }
        if (data?.next12_month_revenue) {
            data.next12_month_revenue = data?.next12_month_revenue
                .split(',')
                .join('');
        }

        data.equity_id = post_id || deal_data?.equity_id;
        if (data?.equity_id) {
            setLoading(true);
            UpdatePost(
                UpdateDeal,
                CommonDispatcher,
                data,
                UploadImages,
                setLoading,
                next,
                token
            );
        }
    };

    const getFormValues = (obj) => {
        let data = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            setError({ ...errorRef.current, [key]: !value });
            data[key] = value;
        });
        return data;
    };

    const onCurrencyChange = (e) => {
        const value = e.target.value;
        const symbol = currencyList.find((d) => d?.currency_id === value);
        setCurrencySymbol(symbol?.symbol);
        setRaisingCurrency(symbol.value);
    };

    const validateLink = (index) => {
        const link = getValues(`founders[${index}].profile_link`);
        const profileError =
            formError?.founders &&
            formError?.founders?.length &&
            formError?.founders[index]?.profile_link;
        return (
            <FormHelperText
                error={profileError}
                className={profileError ? 'mb-1' : ''}
            >
                {link && !isValidURL(link) && 'Invalid profile link.'}
            </FormHelperText>
        );
    };

    const onPreMoneyInput = (e) => {
        const updatedValue = addCommas(
            removeNonNumeric(e?.target?.value.slice(0, 18))
        );
        e.target.value = updatedValue;
        setPremoney(updatedValue);
    };

    const onTargetRaiseInput = (e) => {
        const updatedValue = addCommas(
            removeNonNumeric(e?.target?.value.slice(0, 18))
        );
        e.target.value = updatedValue;
        setTargetRaise(updatedValue);
    };

    const onSoftCircledInput = (e) => {
        const updatedValue = addCommas(
            removeNonNumeric(e?.target?.value.slice(0, 18))
        );
        e.target.value = updatedValue;
        setSoftCircled(updatedValue);
    };

    const onLast12Input = (e) => {
        const updatedValue = addCommas(
            removeNonNumeric(e?.target?.value.slice(0, 18))
        );
        e.target.value = updatedValue;
        setLast12MonthRevenue(updatedValue);
    };

    const onNext12Input = (e) => {
        const updatedValue = addCommas(
            removeNonNumeric(e?.target?.value.slice(0, 18))
        );
        e.target.value = updatedValue;
        setNext12MonthRevenue(updatedValue);
    };

    return (
        <Container maxWidth="sm">
            <Grid container spacing={3} className="mb-5">
                <Grid item xs={12} md={10} sm={10} className="m-auto pb-5">
                    <div className="">
                        <form>
                            <div data-aos="fade-left" data-aos-duration="200">
                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="last_funding_stage_id"
                                            as={
                                                <RenderSelectField
                                                    id="last_funding_stage_id"
                                                    label="Last Funding Stage *"
                                                    value={
                                                        last_funding_stage_id
                                                    }
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-company-founded"
                                                    dropdownListHeight={250}
                                                    dataArray={STAGE_LIST}
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.last_funding_stage_id
                                                    ? 'mb-1'
                                                    : ''
                                            }
                                        >
                                            {errors?.last_funding_stage_id &&
                                                'Last Funding Stage is mandatory.'}
                                        </FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="current_funding_stage_id"
                                            as={
                                                <RenderSelectField
                                                    id="current_funding_stage_id"
                                                    label="Currently Raising stage *"
                                                    value={
                                                        current_funding_stage_id
                                                    }
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-currently-raising"
                                                    dropdownListHeight={250}
                                                    dataArray={
                                                        CURRENTLY_RAISING_LIST
                                                    }
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.current_funding_stage_id
                                                    ? 'mb-1'
                                                    : ''
                                            }
                                        >
                                            {errors?.current_funding_stage_id &&
                                                'Currently Raising stage is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="country_id"
                                            as={
                                                <RenderSelectField
                                                    id="country_id"
                                                    label="Legal Entity Location *"
                                                    value={country_id}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-company-founded"
                                                    dropdownListHeight={250}
                                                    dataArray={countriesArray}
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.country_id ? 'mb-1' : ''
                                            }
                                        >
                                            {errors?.country_id &&
                                                'Legal Entity Location is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="head_count"
                                            as={
                                                <RenderTextField
                                                    id="head_count"
                                                    type="number"
                                                    value={head_count}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="Head Count *"
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.head_count ? 'mb-1' : ''
                                            }
                                        >
                                            {errors?.head_count &&
                                                'Head Count is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="currency_id"
                                            render={({ onChange, value }) => {
                                                return (
                                                    <RenderSelectField
                                                        id="currency_id"
                                                        label="Currency *"
                                                        value={value}
                                                        onChange={(e: any) => {
                                                            onChange(e);
                                                            onCurrencyChange(e);
                                                        }}
                                                        inputContainerClassName={
                                                            'w-100'
                                                        }
                                                        inputId="select-raising-currency"
                                                        dropdownListHeight={250}
                                                        dataArray={currencyList}
                                                    />
                                                );
                                            }}
                                            control={control}
                                            defaultValue={currency_id}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.currency_id
                                                    ? 'mb-1'
                                                    : ''
                                            }
                                        >
                                            {errors?.currency_id &&
                                                'Select currency.'}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="pre_money"
                                            as={
                                                <RenderTextField
                                                    id="pre_money"
                                                    value={pre_money}
                                                    onInput={onPreMoneyInput}
                                                    startAdornment={
                                                        currencySymbol
                                                    }
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="Premoney *"
                                                />
                                            }
                                            control={control}
                                            defaultValue={pre_money}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.pre_money ? 'mb-1' : ''
                                            }
                                        >
                                            {errors?.pre_money &&
                                                'Premoney is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="target_raise"
                                            as={
                                                <RenderTextField
                                                    id="target_raise"
                                                    value={target_raise}
                                                    onInput={onTargetRaiseInput}
                                                    startAdornment={
                                                        currencySymbol
                                                    }
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="Target Raise *"
                                                />
                                            }
                                            control={control}
                                            defaultValue={target_raise}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.target_raise
                                                    ? 'mb-1'
                                                    : ''
                                            }
                                        >
                                            {errors?.target_raise &&
                                                'Target Raise is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="soft_circled"
                                            as={
                                                <RenderTextField
                                                    id="soft_circled"
                                                    value={soft_circled}
                                                    onInput={onSoftCircledInput}
                                                    startAdornment={
                                                        currencySymbol
                                                    }
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="Commitments till-date"
                                                /> /*Soft Circled*/
                                            }
                                            control={control}
                                            defaultValue={soft_circled}
                                            rules={{
                                                required: false
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="last12_month_revenue"
                                            as={
                                                <RenderTextField
                                                    id="last12_month_revenue"
                                                    value={last12_month_revenue}
                                                    onInput={onLast12Input}
                                                    startAdornment={
                                                        currencySymbol
                                                    }
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="Last 12 months revenue"
                                                />
                                            }
                                            control={control}
                                            defaultValue={last12_month_revenue}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="next12_month_revenue"
                                            as={
                                                <RenderTextField
                                                    id="next12_month_revenue"
                                                    value={next12_month_revenue}
                                                    onInput={onNext12Input}
                                                    startAdornment={
                                                        currencySymbol
                                                    }
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="Next 12 months revenue"
                                                />
                                            }
                                            control={control}
                                            defaultValue={next12_month_revenue}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="mom_revenue_growth"
                                            as={
                                                <RenderTextField
                                                    id="mom_revenue_growth"
                                                    type="number"
                                                    inputMode="numeric"
                                                    value={mom_revenue_growth}
                                                    endAdornment="%"
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="MoM Revenue Growth %"
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="mom_user_growth"
                                            as={
                                                <RenderTextField
                                                    id="mom_user_growth"
                                                    type="number"
                                                    inputMode="numeric"
                                                    value={mom_user_growth}
                                                    endAdornment="%"
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="MoM User Growth %"
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    spacing={3}
                                    className="mt-5 mb-3"
                                >
                                    <Grid item xs={12} className="py-1">
                                        <Typography
                                            variant="body2"
                                            component="p"
                                            className="colorPrimary font-weight-bold fs-16"
                                        >
                                            Founders :
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        {foundersFields.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="w-100 d-flex flex-direction-xs-column align-items-flex-start"
                                                >
                                                    <div className="mt-2 mr-3 w-100">
                                                        <Controller
                                                            as={
                                                                <RenderTextField
                                                                    id={`founders[${index}].name`}
                                                                    inputContainerClassName={
                                                                        'w-100'
                                                                    }
                                                                    label={`Founder name ${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                />
                                                            }
                                                            name={`founders[${index}].name`}
                                                            control={control}
                                                            defaultValue={
                                                                item.name ||
                                                                null
                                                            }
                                                            rules={{
                                                                required: false
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="mt-2 mr-3 w-100">
                                                        <Controller
                                                            name={`founders[${index}].profile_link`}
                                                            as={
                                                                <RenderTextField
                                                                    id={`investor_profile[${index}].profile_link`}
                                                                    inputContainerClassName={
                                                                        'w-100'
                                                                    }
                                                                    label={`Profile link ${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                />
                                                            }
                                                            control={control}
                                                            inputRef={register}
                                                            defaultValue={
                                                                item.profile_link ||
                                                                null
                                                            }
                                                            rules={{
                                                                validate: (
                                                                    link
                                                                ) => {
                                                                    return isValidURL(
                                                                        link
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        {validateLink(index)}
                                                    </div>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() =>
                                                            foundersRemove(
                                                                index
                                                            )
                                                        }
                                                        className="d-flex align-self-flex-xs-end mt-5 mt-xs-0"
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </div>
                                            );
                                        })}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="mb-3">
                                    <Grid item xs={12} className="mt-3">
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            type="button"
                                            disabled={
                                                foundersFields?.length >= 10
                                            }
                                            className="float-right"
                                            onClick={() => {
                                                foundersAppend({
                                                    name: '',
                                                    profile_link: ''
                                                });
                                            }}
                                        >
                                            <Add className="mr-2" />
                                            Add a new founder
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="mt-5 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <Button
                                        color="primary"
                                        variant="text"
                                        className={`fs-16 text-inherit`}
                                        onClick={() => handleBack()}
                                    >
                                        Back
                                    </Button>
                                </div>
                                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={onSave}
                                        disabled={
                                            isLoading || (post_id && !isValid)
                                        }
                                        className={`fs-16 text-inherit ${
                                            post_id && !isValid
                                                ? ''
                                                : 'btn-submit-style'
                                        }`}
                                    >
                                        Next{' '}
                                        {isLoading ? (
                                            <CircularProgress
                                                size="1.3rem"
                                                className="circular-progress"
                                            />
                                        ) : null}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default connect(null, {
    CommonDispatcher,
    UploadImages,
    UpdateDeal
})(StepTwoComponent);
