import { Cookies } from 'react-cookie';
import { SET_STARTUP_EQUITY_DEAL_DATA } from '../../../../redux/Deals/actionTypes';

export const getCommonDetailsData = async (ctx, url) => {
    // const {query: {post_id}} = ctx;
    let isAuthenticated = false;
    if (ctx?.req?.headers?.cookie) {
        const cookies: any = new Cookies(ctx?.req?.headers?.cookie);
        isAuthenticated = cookies?.cookies?.pgAdminToken;
    }
    const getProps = (jsonData) => {
        if (isAuthenticated) {
            if (
                !jsonData?.data &&
                (jsonData?.response?.status === 400 ||
                    jsonData?.response?.data?.error)
            ) {
                return {
                    props: {
                        isAuthenticated: true,
                        meta: { url },
                        jsonData: {
                            posts: [],
                            total_pages: 0,
                            current_page: 1,
                            next_page: 1,
                            page_size: 0,
                            count: 0,
                            error: jsonData?.response?.data?.error
                        }
                    }
                };
            } else {
                return {
                    props: {
                        meta: { url },
                        isAuthenticated: true,
                        jsonData: jsonData?.data || {}
                    }
                };
            }
        } else {
            return {
                props: {
                    meta: { url },
                    isAuthenticated: false
                }
            };
        }
    };

    // const ssrData = await GetSSRData(PATH + ApiRoutes.startupEquity.GET_DETAILS_DATA.replace("id", post_id), null, null, ctx);
    // return getProps(ssrData);
};

export const UpdatePost = (
    UpdateStartupEquityPost,
    CommonDispatcher,
    payload,
    UploadImages,
    setLoading,
    next,
    token
) => {
    console.log('getValues', payload);
    const data = { ...payload };
    delete data.images;
    delete data.company_logo;

    UpdateStartupEquityPost(token, data).then((r) => {
        if (r?.type === 'success') {
            uploadImagesData(
                UploadImages,
                CommonDispatcher,
                r?.response?.equity_id,
                payload,
                setLoading,
                next,
                token
            );
        } else {
            setLoading(false);
        }
    });
};

const uploadImagesData = (
    UploadImages,
    CommonDispatcher,
    equity_id,
    payload,
    setLoading,
    next,
    token
) => {
    const images = payload?.images;
    if (images && images?.length && images[0]?.apiName) {
        UploadImages(token, getImages(images, equity_id)).then((d) => {
            payload.company_logo = d?.response?.file_url;
            CommonDispatcher(SET_STARTUP_EQUITY_DEAL_DATA, payload);
            setLoading(false);
            if (d?.type === 'success') {
                next();
            }
        });
    } else {
        CommonDispatcher(SET_STARTUP_EQUITY_DEAL_DATA, payload);
        setLoading(false);
        next();
    }
};

const getImages = (images, equity_id) => {
    let formData = new FormData();
    formData.append('equity_id', equity_id);
    images.forEach((image: any) => {
        formData.append('files', image.file as any, image.apiName);
    });
    return formData;
};
