import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { RootState } from '../../../redux/store';
import { Chip, Table } from '@material-ui/core';
import TableHeaderFilter from '../../Common/TableHeaderFilter';
import { CommonTableStyle } from '../../Common/CommonTableStyle';
import moment from 'moment';
import { GetUnknownUserReferral } from '../../../redux/Referral/actions';
import { useCookies } from 'react-cookie';

const LinkReferralComponent = ({
    sectionBaseUrl,
    GetUnknownUserReferral
}: any) => {
    const classes: any = CommonTableStyle();
    const tableHeader = useSelector(
        (store: RootState) => store?.referral.linkReferralTableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.referral.linkReferral
    );
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsLoading(true);
        GetUnknownUserReferral(token).then(() => {
            setIsLoading(false);
        });
    };

    return (
        <Grid container spacing={0} className="h-100">
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <TableHeaderFilter sectionBaseUrl={sectionBaseUrl} />
                    <TableContainer className={classes.container}>
                        {isLoading ? (
                            <div className="mainLoader">
                                <div className="loader" />
                            </div>
                        ) : (
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {tableHeader?.map((column) => (
                                            <TableCell key={column.id}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listingsData?.posts &&
                                    listingsData?.posts.length ? (
                                        listingsData?.posts?.map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row?.user_id}
                                                    style={{
                                                        padding: 0,
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        N/A
                                                    </TableCell>
                                                    <TableCell
                                                        className={`d-flex flex-direction-column ${classes.tableCell}`}
                                                    >
                                                        <span>
                                                            {row?.full_name}
                                                        </span>
                                                        <div className="d-flex align-items-center">
                                                            <span className="mr-2">
                                                                {row?.email}
                                                            </span>
                                                            <span>
                                                                <Chip
                                                                    size="small"
                                                                    label={
                                                                        row?.referral_status
                                                                    }
                                                                    clickable
                                                                    color={
                                                                        'primary'
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        N/A
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row?.joining_date
                                                            ? moment(
                                                                  row?.joining_date
                                                              ).format(
                                                                  'DD MMM, YYYY'
                                                              )
                                                            : '-'}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={tableHeader.length + 1}
                                                className="dataNotAvailable"
                                            >
                                                Data not available
                                            </td>
                                        </tr>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
};
export default connect(null, { GetUnknownUserReferral })(LinkReferralComponent);
