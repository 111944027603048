import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    Grid,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import style from './Style.module.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { GetSingleDeal } from '../../../redux/Deals/actions';
import { useCookies } from 'react-cookie';
import {
    createExternalLink,
    mapDocumentsInvestorsAndFoundersData
} from '../../../utils/common';
import { Routes } from '../../../constants';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CommonDealDetailComponent from '../../Common/CommonDealDetailComponent';
import RenderDealReadMoreLess from '../../Common/RenderDealReadMoreLess';
import DealNotFound from './../../../resources/deal-not-found.svg';
import { RootState } from '../../../redux/store';
import OverflowDataComponent from '../../Common/OverflowDataComponent';
import InfoIconComponent from '../../Common/InfoIconComponent';
import { ExternalUrl } from '../../Common/ExternalUrl';

const DealsDetails = ({
    match,
    jsonData,
    hideDealStatus,
    GetSingleDeal
}: any) => {
    const [d, setDeal]: any = useState({});
    const [value, setValue] = useState(0);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [isLoading, setIsLoading] = useState(false);
    const [dealNotFound, setDealNotFound] = useState(false);
    const {
        params: { equity_id }
    } = match;
    const history = useHistory();
    const store = useSelector((state: RootState) => state);
    const CURRENTLY_RAISING_LIST =
        store?.commonAppData?.stages?.CURRENTLY_RAISING_LIST || [];

    const [readMore, setReadMore] = React.useState(false);
    const handleReadMore = () => {
        setReadMore(!readMore);
    };

    useEffect(() => {
        if (jsonData?.deal && Object.keys(jsonData?.deal).length) {
            let d = mapDocumentsInvestorsAndFoundersData(jsonData?.deal);
            setDeal(d);
        } else {
            setIsLoading(true);
            GetSingleDeal(token, { equity_id }).then((r) => {
                if (r?.response?.data?.error) {
                    setDealNotFound(true);
                    setIsLoading(false);
                } else {
                    const deal = mapDocumentsInvestorsAndFoundersData(
                        r?.response?.deal
                    );
                    setDeal(deal);
                    setIsLoading(false);
                }
            });
        }
    }, []);

    const getBusinessModal = () => {
        return `${d?.b2b ? 'B2B' : ''}${
            d?.b2c ? (d?.b2b ? `, B2C` : 'B2C') : ''
        }${d?.b2b2c ? (d?.b2b || d?.b2c ? `, B2B2C` : 'B2B2C') : ''}`;
    };

    const getStartupType = () => {
        return `${d?.technology ? 'Technology' : ''}${
            d?.impact && d?.technology ? `, Impact` : d?.impact ? 'Impact' : ''
        }`;
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                scrollToSection(sections[0]?.value);
                break;
            case 1:
                scrollToSection(sections[1]?.value);
                break;
            case 2:
                scrollToSection(sections[2]?.value);
                break;
            case 3:
                scrollToSection(sections[3]?.value);
                break;
        }
    };

    const sections = [
        { title: 'Basic Info', value: 'basicInfo' },
        { title: 'Financial Info', value: 'financialInfo' },
        { title: 'Team', value: 'team' },
        { title: 'Documents', value: 'documents' }
    ];

    const scrollToSection = (id) => {
        const el = document.getElementById(id);
        if (el) {
            el.scrollIntoView(true);
        }
    };

    let raised: number;
    raised = d?.soft_circled ? (d?.soft_circled / d?.target_raise) * 100 : 0;

    const renderStatus = () => {
        if (d?.status && !hideDealStatus) {
            let label;
            let color = d?.status;
            switch (d?.status) {
                case 'COMPLETE':
                    label = 'ADDED';
                    color = 'default';
                    break;
                case 'DRAFT':
                    label = 'DRAFT';
                    color = 'primary';
                    break;
                case 'INVITED':
                    label = 'DRAFT';
                    color = 'default';
                    break;
                default:
                    label = 'VIEWED';
                    break;
            }
            return (
                <Chip
                    label={label}
                    color={color}
                    className={`mt-2 ${
                        d?.status === 'COMPLETE'
                            ? 'border-0 bgColorGreen colorWhite'
                            : 'bgColorLightGray colorPrimary'
                    }`}
                />
            );
        }
    };

    const handleRedirect = (pathname) => {
        history.push(`${pathname}/${equity_id}`);
    };

    const website = (
        <ExternalUrl
            className="text-decoration-none text-hover-underline"
            data={[{ title: d?.company_name, url: d?.website }]}
        />
    );

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <div className={`h-100 mt-5 ${style.pageContainer}`}>
            <Grid container id="listingContainer" className="h-100">
                <Grid item xs={12} md={10} className="m-auto">
                    {dealNotFound ? (
                        <div className="d-flex align-items-center flex-direction-column">
                            <img
                                src={DealNotFound}
                                alt="Deal not found"
                                height={250}
                                width={250}
                            />
                            <h1>This deal is no longer available!</h1>
                        </div>
                    ) : (
                        <>
                            <Card>
                                <div className="mt-5 py-5">
                                    <CommonDealDetailComponent
                                        data={d}
                                        raised={raised}
                                        renderStatus={renderStatus}
                                    />
                                </div>
                            </Card>

                            <Card className="mt-5 p-5">
                                <div className={style.cardContainer}>
                                    <div
                                        className="d-flex align-items-center justify-content-end
                                justify-content-xs-center"
                                    >
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            className="text-inherit mr-3"
                                            onClick={() =>
                                                handleRedirect(
                                                    `${Routes.deals.deals}/edit-deal`
                                                )
                                            }
                                        >
                                            Edit Deal
                                        </Button>

                                        <Button
                                            color="default"
                                            variant="contained"
                                            className="text-inherit"
                                            size="small"
                                            onClick={() =>
                                                handleRedirect(
                                                    `${Routes.deals.deals}/cap-table`
                                                )
                                            }
                                        >
                                            Cap Table
                                        </Button>
                                    </div>

                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        className="mt-5"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="on"
                                    >
                                        {sections.map((item: any, index) => {
                                            return (
                                                <Tab
                                                    key={index}
                                                    label={item?.title}
                                                    disableRipple={true}
                                                    disableFocusRipple={true}
                                                />
                                            );
                                        })}
                                    </Tabs>

                                    <CardContent className="px-0">
                                        <Container
                                            maxWidth="md"
                                            className="px-0"
                                        >
                                            <Grid
                                                container
                                                spacing={3}
                                                className="mb-5"
                                            >
                                                <Grid item>
                                                    <h3 className="text-uppercase my-2">
                                                        Description
                                                    </h3>
                                                    {d?.description ? (
                                                        <RenderDealReadMoreLess
                                                            description={
                                                                d?.description
                                                            }
                                                            handleReadMore={
                                                                handleReadMore
                                                            }
                                                            readMore={readMore}
                                                        />
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={3}
                                                id={sections[0]?.value}
                                            >
                                                <Grid item>
                                                    <h3 className="text-uppercase my-2">
                                                        Basic Info
                                                    </h3>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Company
                                                        Name&nbsp;:&nbsp;
                                                        {website}
                                                        {/* {d?.company_name ||
                                                                'N/A'} */}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Founded in&nbsp;
                                                        <b className="colorBlue">
                                                            {d?.foundation_year ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Legal Entity
                                                        Location&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d
                                                                ?.legal_entity_location
                                                                ?.label ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Head Count&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d?.head_count ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Startup
                                                        Type&nbsp;:&nbsp;
                                                        {d?.startup_type &&
                                                        d?.startup_type
                                                            .length ? (
                                                            <div className="mt-1">
                                                                <OverflowDataComponent
                                                                    data={d?.startup_type.map(
                                                                        (d) =>
                                                                            d?.name
                                                                    )}
                                                                />
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Business
                                                        Modal&nbsp;:&nbsp;
                                                        {d?.business_model &&
                                                        d?.business_model
                                                            .length ? (
                                                            <div>
                                                                <OverflowDataComponent
                                                                    data={d?.business_model.map(
                                                                        (d) =>
                                                                            d?.name
                                                                    )}
                                                                />
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Sectors&nbsp;:&nbsp;
                                                        {d?.sectors &&
                                                        d?.sectors.length ? (
                                                            <div className="mt-1">
                                                                <OverflowDataComponent
                                                                    data={d?.sectors.map(
                                                                        (d) =>
                                                                            d?.label
                                                                    )}
                                                                />
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        SDG Sectors&nbsp;
                                                        <InfoIconComponent />
                                                        &nbsp;
                                                        {d?.sdg_sectors &&
                                                        d?.sdg_sectors
                                                            .length ? (
                                                            <div className="mt-1">
                                                                <OverflowDataComponent
                                                                    data={d?.sdg_sectors.map(
                                                                        (d) =>
                                                                            d?.label
                                                                    )}
                                                                />
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        SDG Goals&nbsp;
                                                        <InfoIconComponent />
                                                        &nbsp;
                                                        {d?.sectors &&
                                                        d?.sectors.length ? (
                                                            <div className="mt-1">
                                                                <OverflowDataComponent
                                                                    data={d?.sectors.map(
                                                                        (d) =>
                                                                            d?.label
                                                                    )}
                                                                />
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Operational
                                                        Regions&nbsp;:&nbsp;
                                                        {d?.regions &&
                                                        d?.regions.length ? (
                                                            <div className="mt-1">
                                                                <OverflowDataComponent
                                                                    data={d?.regions.map(
                                                                        (d) =>
                                                                            d?.label
                                                                    )}
                                                                />
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Operational
                                                        Geographies&nbsp;:&nbsp;
                                                        {d?.operational_geos &&
                                                        d?.operational_geos
                                                            .length ? (
                                                            <div className="mt-1">
                                                                <OverflowDataComponent
                                                                    data={d?.operational_geos.map(
                                                                        (d) =>
                                                                            d?.label
                                                                    )}
                                                                />
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        HQ
                                                        Geography&nbsp;:&nbsp;
                                                        {d?.hq_geos &&
                                                        d?.hq_geos.length ? (
                                                            <div className="mt-1">
                                                                <OverflowDataComponent
                                                                    data={d?.hq_geos.map(
                                                                        (d) =>
                                                                            d?.label
                                                                    )}
                                                                />
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Last Funding
                                                        Stage&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d
                                                                ?.last_funding_stage
                                                                ?.label ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={3}
                                                id={sections[1]?.value}
                                            >
                                                <Grid item>
                                                    <h3 className="text-uppercase my-2">
                                                        Financial Info
                                                    </h3>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Currently Raising
                                                        Stage&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d
                                                                ?.current_funding_stage
                                                                ?.label ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Last 12 months
                                                        revenue&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d?.display_last12_month_revenue ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Last Funding
                                                        Stage&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d
                                                                ?.last_funding_stage
                                                                ?.label ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Next 12 months
                                                        revenue&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d?.display_next12_month_revenue ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Target
                                                        Raise&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d?.display_target_raise ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        MoM Revenue
                                                        Growth&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d?.mom_revenue_growth
                                                                ? `${d?.mom_revenue_growth}%`
                                                                : 'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Premoney&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d?.display_pre_money ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        MoM User
                                                        Growth&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d?.mom_user_growth
                                                                ? `${d?.mom_user_growth}%`
                                                                : 'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p"
                                                    >
                                                        Commitments
                                                        till-date&nbsp;:&nbsp;
                                                        <b className="colorBlue">
                                                            {d?.display_soft_circled ||
                                                                'N/A'}
                                                        </b>
                                                    </Typography>
                                                </Grid>

                                                {d?.expiry_date ? (
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            component="p"
                                                        >
                                                            Expiry
                                                            Date&nbsp;:&nbsp;
                                                            <b className="colorBlue">
                                                                {moment(
                                                                    d?.expiry_date
                                                                ).format(
                                                                    'Do MMMM YYYY'
                                                                )}
                                                                {d?.is_expired ? (
                                                                    <span className="colorDanger">
                                                                        &nbsp;(Deal
                                                                        Closed
                                                                        on{' '}
                                                                        {moment(
                                                                            d?.expiry_date
                                                                        ).format(
                                                                            'Do MMMM YYYY'
                                                                        )}
                                                                        )
                                                                    </span>
                                                                ) : null}
                                                            </b>
                                                        </Typography>
                                                    </Grid>
                                                ) : null}

                                                {d?.current_funding_stage_id ===
                                                CURRENTLY_RAISING_LIST?.[0]
                                                    ?.value ? (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                component="p"
                                                            >
                                                                Convertible
                                                                Discount&nbsp;:&nbsp;
                                                                <b className="colorBlue">
                                                                    {d?.convertible_discount
                                                                        ? `${d?.convertible_discount}%`
                                                                        : 'N/A'}
                                                                </b>
                                                            </Typography>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                component="p"
                                                            >
                                                                Convertible
                                                                Cap&nbsp;:&nbsp;
                                                                <b className="colorBlue">
                                                                    {!d?.no_cap
                                                                        ? d?.display_convertible_cap
                                                                            ? d?.display_convertible_cap
                                                                            : 'N/A'
                                                                        : 'No Cap'}
                                                                </b>
                                                            </Typography>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                component="p"
                                                            >
                                                                Convertible
                                                                Interest&nbsp;:&nbsp;
                                                                <b className="colorBlue">
                                                                    {!d?.no_interest
                                                                        ? d?.convertible_interest
                                                                            ? `${d?.convertible_interest}%`
                                                                            : 'N/A'
                                                                        : 'No Interest'}
                                                                </b>
                                                            </Typography>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                component="p"
                                                            >
                                                                Maturity
                                                                date&nbsp;:&nbsp;
                                                                <b className="colorBlue">
                                                                    {d?.maturity_date
                                                                        ? moment(
                                                                              d?.maturity_date
                                                                          ).format(
                                                                              'Do MMMM YYYY'
                                                                          )
                                                                        : 'N/A'}
                                                                </b>
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={3}
                                                id={sections[2]?.value}
                                            >
                                                <Grid item>
                                                    <h3 className="text-uppercase mb-2 mt-5">
                                                        Team
                                                    </h3>
                                                    {d?.founders &&
                                                    d?.founders.length ? (
                                                        <div className="d-flex align-items-center mt-3 mb-5">
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                component="p"
                                                            >
                                                                Founders /
                                                                CEO&nbsp;:&nbsp;
                                                                <ExternalUrl
                                                                    className="text-decoration-none text-hover-underline"
                                                                    data={
                                                                        d?.founders
                                                                    }
                                                                />
                                                                {/* <b>
                                                                    {getFounders()}
                                                                </b> */}
                                                            </Typography>
                                                        </div>
                                                    ) : null}
                                                    {d?.investors &&
                                                    d?.investors.length ? (
                                                        <div className="d-flex align-items-center mt-3 mb-5">
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                component="p"
                                                            >
                                                                Investors&nbsp;:&nbsp;
                                                                <ExternalUrl
                                                                    className="text-decoration-none text-hover-underline"
                                                                    data={
                                                                        d?.investors
                                                                    }
                                                                />
                                                            </Typography>
                                                        </div>
                                                    ) : null}
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={3}
                                                id={sections[3]?.value}
                                            >
                                                <Grid item>
                                                    {d?.documents &&
                                                    d?.documents.length ? (
                                                        <>
                                                            <h3 className="text-uppercase mb-2 mt-5">
                                                                Document Links
                                                            </h3>
                                                            <div className="d-flex align-items-center mt-3 mb-5">
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    component="p"
                                                                >
                                                                    <ExternalUrl
                                                                        className="text-decoration-none text-hover-underline"
                                                                        data={
                                                                            d?.documents
                                                                        }
                                                                    />
                                                                </Typography>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </CardContent>
                                </div>
                            </Card>
                        </>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default connect(null, { GetSingleDeal })(DealsDetails);
