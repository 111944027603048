export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const CREATE_COMMON_CATEGORIES = 'CREATE_COMMON_CATEGORIES';
export const TOGGLE_SEARCH_FILTER = 'TOGGLE_SEARCH_FILTER';
export const SET_CATEGORIES_DATA = 'SET_CATEGORIES_DATA';
export const SET_SECTORS_DATA = 'SET_SECTORS_DATA';
export const SET_MEET_PEOPLE_INTERESTS_DATA = 'SET_MEET_PEOPLE_INTERESTS_DATA';
export const SET_FOUNDED_YEAR_LIST = 'SET_FOUNDED_YEAR_LIST';
export const SET_ADMIN_PROFILE = 'SET_ADMIN_PROFILE';
export const SET_STARTUP_TYPE = 'SET_STARTUP_TYPE';
export const SET_FIRMS_CLASSIFY = 'SET_FIRMS_CLASSIFY';

export const BUISNESS_MODEL_MASTER = 'BUISNESS_MODEL_MASTER';
export const INVESTMENT_ASSETS_CLASS_DATA = 'INVESTMENT_ASSETS_CLASS_DATA';
export const SET_SECTORS = 'SET_SECTORS';
export const SET_STAGES = 'SET_STAGES';
export const SET_SDG_GOALS = 'SET_SDG_GOALS';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_INVESTMENTS_TYPE_LIST = 'SET_INVESTMENTS_TYPE_LIST';
export const SET_ECOSYSTEM_TYPE_LIST = 'SET_ECOSYSTEM_TYPE_LIST';
