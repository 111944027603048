import {
    Step,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CommonDispatcher } from '../../../redux/Common/action';
import {
    BUISNESS_MODEL_MASTER,
    INVESTMENT_ASSETS_CLASS_DATA,
    SET_REGIONS,
    SET_SDG_GOALS,
    SET_STAGES
} from '../../../redux/Common/actionTypes';
import {
    RESET_FUND_EQUITY_DATA,
    SET_FUND_EQUITY_DEAL_DATA,
    UPDATE_FUNDS_WIZARD_FORM_DATA
} from '../../../redux/FundDeals/actionTypes';
import { GetSingleDeal } from '../../../redux/FundDeals/actions';
import { RootState } from '../../../redux/store';
import {
    GetBusinessModelMaster,
    GetRegions,
    GetSdgGoals,
    GetStagesTarget,
    investingAssetClassData
} from './Common';
import ReviewComponents from './ReviewComponents';
import StepFiveComponent from './StepFiveComponent';
import StepFourComponent from './StepFourComponent';
import StepSixComponent from './StepSixComponent';
import StepThreeComponent from './StepThreeComponent';
import StepTwoComponent from './StepTwoComponent';
import style from './Style.module.scss';
import { ENV } from '../../../utils/constants';

const AddEdit = ({ match, GetSingleDeal, CommonDispatcher, jsonData }: any) => {
    const dispatch = useDispatch();
    const {
        params: { equity_id }
    } = match;
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const store = useSelector((store: RootState) => store);
    const [isLoading, setIsLoading] = useState(true);

    const activeStep = store?.FundDeals?.wizard?.currentStep;
    const deal_data = store?.FundDeals?.deal_data || {};
    const [deal, setDeal] = useState(deal_data);
    const [isLoaded, setIsLoaded] = useState(false);
    const steps = store?.FundDeals?.wizard?.investFormSteps || [];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
    const errorStep = store?.FundDeals?.wizard?.errorStep || [];
    const isDealShared =
        deal?.is_shared_with_pinegray ||
        (deal?.deal_accesses && deal?.deal_accesses.length);

    useEffect(() => {
        if (equity_id) {
            setIsLoading(true);
            GetSingleDeal(token, { equity_id }).then(async (r) => {
                const investingAssetClass = await investingAssetClassData(
                    token
                );
                const buisnessModel = await GetBusinessModelMaster(token);
                const stages = await GetStagesTarget(token);
                const sdgGoalsArray = await GetSdgGoals(token);
                const regions = await GetRegions(token);

                CommonDispatcher(BUISNESS_MODEL_MASTER, buisnessModel);
                CommonDispatcher(SET_STAGES, stages);
                CommonDispatcher(SET_SDG_GOALS, sdgGoalsArray);
                CommonDispatcher(SET_REGIONS, regions);

                CommonDispatcher(
                    INVESTMENT_ASSETS_CLASS_DATA,
                    investingAssetClass
                );

                let data = { ...r?.response?.deal };
                if (data?.hq_geos && data?.hq_geos.length) {
                    data.hq_geos = data?.hq_geos.map((d) => d?.country_id);
                }
                if (data?.operational_geos && data?.operational_geos.length) {
                    data.ops_geos = data?.operational_geos.map(
                        (d) => d?.country_id
                    );
                    delete data?.operational_geos;
                }
                if (data?.sectors && data?.sectors.length) {
                    data.sectors = data?.sectors.map((d) => d?.sector_id);
                }
                CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, data);
                if (jsonData?.status === 'DRAFT') {
                    CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                        currentStep: jsonData?.page_number - 1
                    });
                }
                setIsLoading(false);
            });
        }
        return () => {
            CommonDispatcher(RESET_FUND_EQUITY_DATA, {});
        };
    }, [jsonData]);

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 1:
                return <StepTwoComponent equity_id={equity_id} />;
            case 2:
                return <StepFourComponent equity_id={equity_id} />;
            case 3:
                return <StepFiveComponent equity_id={equity_id} />;
            case 4:
                return <StepSixComponent equity_id={equity_id} />;
            case 5:
                return <StepThreeComponent equity_id={equity_id} />;
            case 6:
                return <ReviewComponents equity_id={equity_id} />;
            case 0:
            default:
                return <ReviewComponents equity_id={equity_id} />;
        }
    }

    const showCommonErrorMessage = () => {
        if (
            Object.keys(errorStep).length &&
            (errorStep[0] || errorStep[1] || errorStep[2])
        ) {
            return (
                <div className={`mb-5 ${!equity_id ? 'pb-5' : ''}`}>
                    <Alert severity="warning" className="w-50 w-xs-90 m-auto">
                        Mandatory fields are missing.
                    </Alert>
                </div>
            );
        }
    };

    const getStepper = () => {
        return (
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                className={`${style.wizardStepper} ${
                    isSmallScreen
                        ? 'w-100 d-none d-sm-flex px-0 py-3'
                        : 'pb-2 w-75 m-auto d-flex d-sm-none'
                }`}
            >
                {steps.map((label, i) => (
                    <Step key={i}>
                        <StepLabel
                            onClick={() => {
                                ENV === 'DEVELOPMENT' &&
                                    dispatch({
                                        type: UPDATE_FUNDS_WIZARD_FORM_DATA,
                                        payload: {
                                            currentStep: i
                                        }
                                    });
                            }}
                            StepIconProps={{
                                classes: {
                                    completed: 'colorGreen px-0 cursor-pointer'
                                }
                            }}
                            error={errorStep[i]}
                            classes={{
                                label: isExtraSmall ? 'fs-12' : '',
                                active:
                                    activeStep === i ? 'font-weight-bold' : ''
                            }}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    };

    return (
        <div className={`bgWhite w-70 w-sm-100 mx-auto`}>
            {getStepper()}
            <div className={style.appBarSpacerStepper} id="appContent">
                <Typography variant="h5" className="mt-5 pb-5 text-center">
                    {steps[activeStep]}
                </Typography>
                {showCommonErrorMessage()}
                {equity_id ? (
                    <div className="mb-5 pb-5">
                        <Alert
                            severity="warning"
                            className="w-70 w-xs-90 m-auto"
                        >
                            Deal is{' '}
                            {deal_data?.status === 'COMPLETE'
                                ? 'LIVE'
                                : 'in DRAFT'}{' '}
                            {isDealShared ? 'and shared with others' : ''}, all
                            updates will be immediately reflected to others in
                            the Marketplace. Update with caution. Draft saving
                            not available in Edit/Update mode.
                        </Alert>
                    </div>
                ) : null}
            </div>
            {getStepContent(activeStep)}
        </div>
    );
};
export default connect(null, { GetSingleDeal, CommonDispatcher })(AddEdit);
