import * as Actions from './actionTypes';
import {
    USERS_ACTIVE,
    USERS_DECLINED,
    USERS_DELETED,
    USERS_SUSPENDED,
    USERS_UN_APPROVED
} from './actionTypes';
import axios from 'axios';
import { API_PATH, UsersTab } from '../../constants';

export const GetUsersList = (token: string, data: any) => {
    let type = Actions.USERS_ALL;
    switch (data?.tab) {
        case UsersTab.TYPE_UN_APPROVED:
            type = USERS_UN_APPROVED;
            break;
        case UsersTab.TYPE_ACTIVE:
            type = USERS_ACTIVE;
            break;
        case UsersTab.TYPE_SUSPENDED:
            type = USERS_SUSPENDED;
            break;
        case UsersTab.TYPE_DECLINED:
            type = USERS_DECLINED;
            break;
        case UsersTab.TYPE_DELETED:
            type = USERS_DELETED;
            break;
    }
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/users`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type,
                payload
            });
            return { type: 'success', response: payload };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetSingleUser = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/user`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateStrategy = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-strategy`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateUserProfile = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-user`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const ChangeUserEmail = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/secure/update-user`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const UpdateUserPermission = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-permission`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const DeleteUser = (token: string, user_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data: { user_id },
                url: `${API_PATH}/admin/delete-user`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const VerifyUser = (token: string, user_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { user_id },
                url: `${API_PATH}/admin/verify/user`,
                headers: { Authorization: token }
            };
            await axios(config as any);
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetUserSessions = (token: string, user_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: user_id,
                url: `${API_PATH}/admin/user-sessions`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.GET_USER_SESSIONS,
                payload
            });
            return { type: 'success', response: payload };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const LogoutSingleUser = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data,
                url: `${API_PATH}/admin/user-sessions/delete`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const LogoutAllUsers = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data,
                url: `${API_PATH}/admin/user-sessions/deleteAll`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetAdminSessions = (token: string) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/admin-sessions`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.GET_USER_SESSIONS,
                payload
            });
            return { type: 'success', response: payload };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const GetDownloadUsers = (token: string, data) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/users/export`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data;

            return { type: 'success', response: payload };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const LogoutSingleAdmin = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data,
                url: `${API_PATH}/admin/admin-sessions/delete`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const LogoutAllAdmins = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data,
                url: `${API_PATH}/admin/admin-sessions/deleteAll`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
