import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Close, Search } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import _ from 'lodash';
import { getQuery } from '../../../utils/common';
import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import RenderSelectField from '../../InputComponents/SelectField/RenderSelectField';
import { Routes } from '../../../constants';
import { UsersTypeList } from '../../../utils/constants';
import FilterUserModal from '../../Users/FilterUserModal';
import TagsPopup from '../TagsPopup';
import TagsFilter from '../TagsFilter';
import DownloadUserModal from '../../Users/DownloadUserModal';
import FirmsFilterModal from '../../Users/FirmsFilterModal';

const TableHeaderFilter = ({
    filterDataArray = UsersTypeList,
    placeholder
}: any) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const history = useHistory();
    const location = useLocation();
    const [keyword, setKeyword] = useState('');
    const query: any = getQuery(history);
    const type = query.get('type');

    const [showMeValue, setShowMeValue] = useState(
        type ? type : filterDataArray?.[0]?.value
    );

    useEffect(() => {
        setShowMeValue(type ? type : filterDataArray?.[0]?.value);
        // eslint-disable-next-line
    }, [type]);
    const delayedQuery = useCallback(
        _.debounce((k) => handleSearch(k), 400),
        []
    );

    useEffect(() => {
        const keyword = query.get('keyword');
        setKeyword(keyword);
        // eslint-disable-next-line
    }, [history?.location?.search]);

    const handleSearch = (keyword) => {
        let searchParams = new URLSearchParams(location.search);
        searchParams.set('keyword', keyword);
        history.replace({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    };

    const doSearch = (e) => {
        const keyword = e?.target?.value;
        setKeyword(keyword);
        delayedQuery(keyword);
    };

    const handleClear = () => {
        let searchParams = new URLSearchParams(location.search);
        searchParams.delete('keyword');
        setKeyword('');
        history.replace({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    };
    const clearAllFilter = () => {
        let params = new URLSearchParams(location.search);
        params.delete('filter');
        params.delete('keyword');
        params.delete('type');
        params.delete('tags_ids');

        const newQueryString = params.toString();

        history.replace({
            pathname: location.pathname,
            search: newQueryString
        });
    };

    const endAdornment = (
        <IconButton
            aria-label="toggle password visibility"
            onClick={handleClear}
            className={`p-2 ${keyword ? 'visible' : 'invisible'}`}
            edge="end"
        >
            <Close />
        </IconButton>
    );

    const startAdornment = (
        <IconButton
            aria-label="toggle password visibility"
            color="primary"
            edge="start"
        >
            <Search />
        </IconButton>
    );

    const handleShowMeChange = (e) => {
        const value = e.target.value;
        setShowMeValue(value);
        let searchParams = new URLSearchParams(location.search);
        if (value && value !== filterDataArray?.[0]?.value) {
            searchParams.set('type', value);
            history.replace({
                pathname: location.pathname,
                search: searchParams.toString()
            });
        } else {
            if (searchParams.has('type')) {
                searchParams.delete('type');
                history.replace({
                    pathname: location.pathname,
                    search: searchParams.toString()
                });
            }
        }
    };

    const hideShowMe = [Routes.users.users, Routes.firms.firms].some((d) =>
        location.pathname.includes(d)
    );
    const hideShowMeFilter = [Routes.users.users].some((d) =>
        location.pathname.includes(d)
    );
    const showFilterByTags = [Routes.firms.firms].some((d) =>
        location.pathname.includes(d)
    );
    const filterTags = (id) => {
        const searchParams = new URLSearchParams(location.search);
        const tagsIds = searchParams.get('tags_ids');

        if (tagsIds) {
            const arr = tagsIds.split(',');
            const includesNumber = arr.includes(String(id));
            if (!includesNumber) {
                arr.push(id);
                searchParams.set('tags_ids', arr.join(','));
            }
        } else {
            searchParams.set('tags_ids', id);
        }

        history.replace({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    };

    return (
        <Container maxWidth="lg">
            <Grid
                container
                className={`my-1 flex-direction-xs-column align-items-xs-center flex-wrap ${
                    !hideShowMe ? 'justify-content-center' : ''
                }`}
                spacing={3}
            >
                {hideShowMe ? (
                    <Grid
                        item
                        xs={12}
                        sm={smallScreen ? 12 : 4}
                        className="d-flex justify-content-end"
                    >
                        <span className="f-16 mr-2 w-00">Show me</span>
                        <RenderSelectField
                            value={showMeValue}
                            inputId="select-category"
                            inputContainerClassName={!smallScreen ? 'w-50' : ''}
                            dropdownListHeight={250}
                            onChange={handleShowMeChange}
                            dataArray={filterDataArray}
                        />
                    </Grid>
                ) : null}
                <Grid
                    item
                    xs={12}
                    sm={8}
                    className="d-flex align-items-center flex-wrap row-gap-10"
                >
                    <div className="w-xs-100">
                        <RenderTextField
                            id="keyword"
                            value={keyword || ''}
                            startAdornment={startAdornment}
                            endAdornment={endAdornment}
                            // autoFocus={true}
                            autoComplete={'off'}
                            size="small"
                            // label={label || "Search"}
                            placeholder={
                                placeholder || 'Enter your search keyword'
                            }
                            onChange={doSearch}
                        />
                    </div>

                    {showFilterByTags ? (
                        <div className="ml-4 mt-1">
                            <TagsPopup
                                d={null}
                                label="Filter by Tags"
                                filterByAllTags={true}
                                filterTags={filterTags}
                            />
                        </div>
                    ) : null}
                    <div>
                        {query?.size > 0 && hideShowMe ? (
                            <span className="ml-4">
                                Filter Are Active
                                <span
                                    className={`text-inherit ml-1 fs-14 colorPurple p-2 cursor-pointer
                        border-radius-4 text-hover-underline `}
                                    onClick={clearAllFilter}
                                >
                                    Remove
                                </span>
                            </span>
                        ) : null}
                    </div>
                    <TagsFilter addressBook={true} />
                    {hideShowMeFilter ? <FilterUserModal /> : null}
                    {hideShowMeFilter ? <FirmsFilterModal /> : null}

                    {hideShowMeFilter ? <DownloadUserModal /> : null}
                </Grid>
            </Grid>
        </Container>
    );
};

export default TableHeaderFilter;
