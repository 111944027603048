import * as ACTIONS from "./actionTypes";

const initialValues = {
    tableHeader: [
        {id: "referrer_email", label: "Referred By"},
        {id: "reffere_email", label: "Referees"}
    ],
    linkReferralTableHeader: [
        {id: "referrer_email", label: "Referred By"},
        {id: "reffere_email", label: "Referees"},
        {id: "referral_date", label: "Invited Date"},
        {id: "joining_date", label: "Joined Date"}
    ],
    emailReferral: {
        posts: []
    },
    linkReferral: {
        posts: []
    }
};

const ReferralReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.EMAIL_REFERRAL:
            return {
                ...state,
                emailReferral: {
                    posts: createPosts(action?.payload)
                }
            };
        case ACTIONS.LINK_REFERRAL:
            return {
                ...state,
                linkReferral: {
                    posts: action?.payload
                }
            };
        default:
            return state;
    }
};

const createPosts = (data) => {
    let referrals = [];
    if (Object.keys(data).length) {
        Object.entries(data).forEach(posts => {
            const referral = posts[1][0];
            let obj = {
                user_id: referral?.user_id,
                referrer: referral?.referrer,
                referrer_email: referral?.referrer_email,
                reffere_email: []
            };
            let email = [];
            if (posts[1] && Array.isArray(posts[1]) && posts[1].length) {
                posts[1].forEach(d => {
                    email.push({
                        name: d?.name,
                        email: d?.reffere_email,
                        status: d?.referral_status,
                        joining_date: d?.joining_date,
                        referral_date: d?.referral_date
                    });
                });
                obj.reffere_email = email;
            }
            referrals.push(obj);
        });
    }
    return referrals;
};

export default ReferralReducer;