import {
    BUISNESS_MODEL_MASTER,
    INVESTMENT_ASSETS_CLASS_DATA,
    SET_ADMIN_PROFILE,
    SET_CATEGORIES_DATA,
    SET_ECOSYSTEM_TYPE_LIST,
    SET_FILTER_DATA,
    SET_FIRMS_CLASSIFY,
    SET_FOUNDED_YEAR_LIST,
    SET_INVESTMENTS_TYPE_LIST,
    SET_MEET_PEOPLE_INTERESTS_DATA,
    SET_REGIONS,
    SET_SDG_GOALS,
    SET_SECTORS,
    SET_SECTORS_DATA,
    SET_STAGES,
    SET_STARTUP_TYPE,
    SHOW_SNACKBAR
} from './actionTypes';
import { createAllCommonCategories, sortData } from './action';

const initialValues = {
    snackbar: {
        isShowing: false,
        message: '',
        type: ''
    },
    sectors: [],
    categories: {
        services: [],
        buySell: [],
        offers: [],
        events: [],
        stories: [],
        realEstate: [],
        requestOffer: []
    },
    meetPeople: [],
    foundedList: [],
    profile: null,
    auth: {},
    sdgGoals: [],
    businessModel: [],
    investingAssetClass: [],
    firmsClassifyList: [],
    stages: { stages: [], STAGE_LIST: [], CURRENTLY_RAISING_LIST: [] },
    startupType: [],
    regions: [],
    filter: {},
    investmentsTypeList: [],
    ecoSystemTypeList: []
};

export const CommonAppData = (state = initialValues, action) => {
    const payload = action?.payload;
    switch (action.type) {
        case SET_FILTER_DATA:
            return { ...state, filter: { ...payload } };
        case SET_INVESTMENTS_TYPE_LIST:
            return { ...state, investmentsTypeList: payload };
        case SET_ECOSYSTEM_TYPE_LIST:
            return { ...state, ecoSystemTypeList: payload };
        case SET_REGIONS:
            return { ...state, regions: payload };
        case SET_SDG_GOALS:
            return { ...state, sdgGoals: payload };
        case SET_STAGES:
            return { ...state, stages: payload };
        case SET_SECTORS:
            return { ...state, sectors: payload };
        case SET_STARTUP_TYPE:
            return { ...state, startupType: payload };
        case SET_FIRMS_CLASSIFY:
            return { ...state, firmsClassifyList: payload };
        case BUISNESS_MODEL_MASTER:
            return { ...state, businessModel: payload };
        case INVESTMENT_ASSETS_CLASS_DATA:
            return { ...state, investingAssetClass: payload };
        case SET_ADMIN_PROFILE:
            return { ...state, profile: { ...payload } };
        case SHOW_SNACKBAR:
            return { ...state, snackbar: { ...payload } };
        case SET_SECTORS_DATA:
            return { ...state, sectors: payload?.sectors };
        case SET_CATEGORIES_DATA:
            return { ...state, categories: createAllCommonCategories(payload) };
        case SET_MEET_PEOPLE_INTERESTS_DATA:
            const categoryListData =
                payload && payload.length
                    ? sortData(payload, 'url_identifier').map((d) => ({
                          title: d?.label,
                          value: d?.interest_id,
                          name: d?.url_identifier
                      }))
                    : [];
            return { ...state, meetPeople: categoryListData };
        case SET_FOUNDED_YEAR_LIST:
            let foundedList = [];
            foundedList.push({
                title: 'Select Founded Year',
                value: 'select',
                disabled: true
            });
            const currentYear = new Date().getFullYear();
            for (let i = currentYear; i >= 1950; i--) {
                foundedList.push({
                    title: i,
                    value: i,
                    disabled: false,
                    selected: currentYear === i
                });
            }
            return { ...state, foundedList };
        default:
            return state;
    }
};
