import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Route, useHistory } from 'react-router-dom';
import EmailReferralComponent from './EmailReferralComponent';
import LinkReferralComponent from './LinkReferralComponent';
import { Routes } from '../../constants';
import { Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useCookies } from 'react-cookie';
import {
    GetUnknownUserReferral,
    GetUserReferral
} from '../../redux/Referral/actions';
import axios from 'axios';

const Referral = ({ GetUserReferral, GetUnknownUserReferral }) => {
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const emailReferral = useSelector(
        (store: RootState) => store?.referral.emailReferral?.posts
    );
    const linkReferral = useSelector(
        (store: RootState) => store?.referral.linkReferral?.posts
    );

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsLoading(true);
        let arr = [];
        arr.push(GetUserReferral(token));
        arr.push(GetUnknownUserReferral(token));
        axios.all(arr).then(() => {
            setIsLoading(false);
            setTab();
        });
    };

    const setTab = () => {
        switch (history.location?.pathname) {
            case Routes.referral.link:
                setValue(1);
                break;
            default:
            case Routes.referral.email:
                setValue(0);
                break;
        }
    };

    const handleChange = (newValue) => {
        setValue(newValue);
        let pathname;
        switch (newValue) {
            case 1:
                pathname = Routes.referral.link;
                break;
            case 0:
            default:
                pathname = Routes.referral.email;
                break;
        }
        history.push(pathname);
    };

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <>
            <Tabs
                value={value}
                onChange={(e, v) => handleChange(v)}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                classes={{
                    flexContainer: !isSmallScreen
                        ? 'justify-content-center'
                        : ''
                }}
            >
                <Tab
                    label={`Email Referrals (${emailReferral?.length})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    label={`Link Referrals (${linkReferral?.length})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
            </Tabs>

            <>
                <Route
                    path={Routes.referral.email}
                    exact
                    render={() => (
                        <EmailReferralComponent
                            sectionBaseUrl={Routes.referral.email}
                        />
                    )}
                />

                <Route
                    path={Routes.referral.link}
                    exact
                    render={() => (
                        <LinkReferralComponent
                            sectionBaseUrl={Routes.referral.link}
                        />
                    )}
                />
            </>
        </>
    );
};
export default connect(null, { GetUserReferral, GetUnknownUserReferral })(
    Referral
);
