import { Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { ROWS_PER_PAGE, Routes, UsersTab } from '../../constants';
import { GetEcosystemTypeMaster, OpenModal } from '../../redux/Common/action';
import { OPEN_ALERT_MODAL } from '../../redux/ModalReducer/actionTypes';
import { GetUsersList } from '../../redux/Users/actions';
import { RootState } from '../../redux/store';
import { getQuery } from '../../utils/common';
import { getSelectedTab } from '../Common/commonUtils';
import ActiveComponent from './Active';
import AllUsersComponent from './AllUsers';
import AwaitingApprovalComponent from './AwaitingApproval';
import DeclinedComponent from './Declined';
import DeletedComponent from './Deleted';
import SuspendedComponent from './Suspended';

const Users = ({ OpenModal, GetUsersList, GetEcosystemTypeMaster }) => {
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    const allUsersList = useSelector(
        (store: RootState) => store?.users.allUsersList
    );
    const unApprovedUsersList = useSelector(
        (store: RootState) => store?.users.unApprovedUsersList
    );
    const activeUsersList = useSelector(
        (store: RootState) => store?.users.activeUsersList
    );
    const suspendedUsersList = useSelector(
        (store: RootState) => store?.users.suspendedUsersList
    );
    const declinedUsersList = useSelector(
        (store: RootState) => store?.users.declinedUsersList
    );
    const deletedUsersList = useSelector(
        (store: RootState) => store?.users.deletedUsersList
    );
    const query = getQuery(history);
    const location = useLocation();
    const tags = query.get('tags_ids');

    const type = query.get('type') || '';
    const keyword = query.get('keyword');
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filter');
    let decodedFilters;
    if (filterParam) {
        decodedFilters = JSON.parse(decodeURIComponent(filterParam));
    }

    useEffect(() => {
        getData({ page: 1, keyword, type, filter: decodedFilters });
        [
            UsersTab.TYPE_UN_APPROVED,
            UsersTab.TYPE_ACTIVE,
            UsersTab.TYPE_SUSPENDED,
            UsersTab.TYPE_DECLINED,
            UsersTab.TYPE_DELETED
        ].forEach((tab) => {
            getData({ page: 1, keyword, type, tab, filter: decodedFilters });
        });
        GetEcosystemTypeMaster();
    }, []);

    useEffect(() => {
        if (keyword || type || type === '' || decodedFilters) {
            setIsFilterApplied(true);
            getData({
                page: 1,
                keyword,
                type,
                tab: getSelectedTab(history?.location?.pathname),
                filter: decodedFilters
            });
        }
        // eslint-disable-next-line
    }, [keyword, type, filterParam]);

    // useEffect(() => {
    //     console.log('dddd');
    //     getData({
    //         page: 1,
    //         keyword,
    //         type,
    //         tab: getSelectedTab(history?.location?.pathname),
    //         filter: { tags_ids: tags ? tags?.split(',') : null }
    //     });
    //     // eslint-disable-next-line
    // }, [tags]);

    const getAllTabsData = async (data) => {
        if (!data?.tab) {
            getData(data, false);
        }
        [
            UsersTab.TYPE_UN_APPROVED,
            UsersTab.TYPE_ACTIVE,
            UsersTab.TYPE_SUSPENDED,
            UsersTab.TYPE_DECLINED,
            UsersTab.TYPE_DELETED
        ].forEach((tab) => {
            getData({ page: 1, tab }, false);
        });
    };

    const getData = async (data, isLoading = true) => {
        data.size = ROWS_PER_PAGE;
        setIsLoading(isLoading);
        await GetUsersList(token, data).then(() => {
            setIsLoading(false);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
            setTab();
        });
    };

    const setTab = () => {
        switch (history.location?.pathname) {
            case Routes.users.awaitingApproval:
                setValue(1);
                break;
            case Routes.users.active:
                setValue(2);
                break;
            case Routes.users.suspended:
                setValue(3);
                break;
            case Routes.users.declined:
                setValue(4);
                break;
            case Routes.users.deleted:
                setValue(5);
                break;
            default:
            case Routes.users.users:
                setValue(0);
                break;
        }
    };

    const handleChange = (newValue) => {
        setValue(newValue);
        let pathname;
        switch (newValue) {
            case 1:
                pathname = Routes.users.awaitingApproval;
                break;
            case 2:
                pathname = Routes.users.active;
                break;
            case 3:
                pathname = Routes.users.suspended;
                break;
            case 4:
                pathname = Routes.users.declined;
                break;
            case 5:
                pathname = Routes.users.deleted;
                break;
            case 0:
            default:
                pathname = Routes.users.users;
                break;
        }
        history.push(pathname);
    };

    return (
        <>
            <Tabs
                value={value}
                onChange={(e, v) => handleChange(v)}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                classes={{
                    flexContainer: !isSmallScreen
                        ? 'justify-content-center'
                        : ''
                }}
            >
                <Tab
                    label={`All Users (${allUsersList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    label={`Users awaiting approval (${unApprovedUsersList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    label={`Active (${activeUsersList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    label={`Suspended (${suspendedUsersList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    label={`Declined (${declinedUsersList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    label={`Deleted (${deletedUsersList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
            </Tabs>

            <>
                <Route
                    path={Routes.users.users}
                    exact
                    render={() => (
                        <AllUsersComponent
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                            sectionBaseUrl={Routes.users.users}
                            loading={isLoading}
                        />
                    )}
                />

                <Route
                    path={Routes.users.awaitingApproval}
                    exact
                    render={() => (
                        <AwaitingApprovalComponent
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                            sectionBaseUrl={Routes.users.awaitingApproval}
                            loading={isLoading}
                        />
                    )}
                />

                <Route
                    path={Routes.users.active}
                    exact
                    render={() => (
                        <ActiveComponent
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                            sectionBaseUrl={Routes.users.active}
                            loading={isLoading}
                        />
                    )}
                />

                <Route
                    path={Routes.users.suspended}
                    exact
                    render={() => (
                        <SuspendedComponent
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                            sectionBaseUrl={Routes.users.suspended}
                            loading={isLoading}
                        />
                    )}
                />

                <Route
                    path={Routes.users.declined}
                    exact
                    render={() => (
                        <DeclinedComponent
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                            sectionBaseUrl={Routes.users.declined}
                            loading={isLoading}
                        />
                    )}
                />

                <Route
                    path={Routes.users.deleted}
                    exact
                    render={() => (
                        <DeletedComponent
                            isFilterApplied={isFilterApplied}
                            sectionBaseUrl={Routes.users.deleted}
                            loading={isLoading}
                        />
                    )}
                />
            </>
        </>
    );
};
export default connect(null, {
    OpenModal,
    GetUsersList,
    GetEcosystemTypeMaster
})(Users);
