import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { RootState } from '../../../redux/store';
import { Button, Chip, Table } from '@material-ui/core';
import TableHeaderFilter from '../../Common/TableHeaderFilter';
import moment from 'moment';
import { CommonTableStyle } from '../../Common/CommonTableStyle';
import { GetUserReferral } from '../../../redux/Referral/actions';
import { useCookies } from 'react-cookie';

const EmailReferralComponent = ({ sectionBaseUrl, GetUserReferral }: any) => {
    const classes: any = CommonTableStyle();
    const tableHeader = useSelector(
        (store: RootState) => store?.referral.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.referral.emailReferral
    );
    const [toggleEmails, setToggleEmails] = useState({});
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsLoading(true);
        GetUserReferral(token).then(() => {
            setIsLoading(false);
        });
    };
    const handleToggleEmails = (id) => {
        setToggleEmails({ [id]: !toggleEmails[id] });
    };

    const renderReffereEmail = (data, user_id) => {
        let reffereData = [...data];
        if (reffereData.length > 3) {
            if (Object.keys(toggleEmails).length && toggleEmails[user_id]) {
                return renderRow(user_id, data);
            } else {
                reffereData.length = 3;
                return renderRow(user_id, reffereData);
            }
        } else {
            return renderRow(user_id, data);
        }
    };

    const renderRow = (user_id, data) => {
        return data.map((d, index) => {
            const id = `${user_id}_${index}`;
            return (
                <Grid container spacing={2} className="h-100 my-2" key={id}>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        className="d-flex flex-direction-column"
                    >
                        <span>{d?.email}</span>
                        <span>
                            <Chip
                                size="small"
                                label={d?.status}
                                className="font-weight-bold mt-1"
                                clickable
                                color={
                                    d?.status === 'PENDING'
                                        ? 'secondary'
                                        : 'primary'
                                }
                            />
                        </span>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        className="d-flex flex-direction-column"
                    >
                        <span>
                            <span className="font-weight-bold mr-2">
                                Referral Date:
                            </span>
                            {d?.referral_date
                                ? moment(d?.referral_date).format(
                                      'DD MMM, YYYY'
                                  )
                                : '-'}
                        </span>
                        <span>
                            <span className="font-weight-bold mr-2">
                                Joined Date:
                            </span>
                            {d?.joining_date
                                ? moment(d?.joining_date).format('DD MMM, YYYY')
                                : 'N/A'}
                        </span>
                    </Grid>
                </Grid>
            );
        });
    };

    return (
        <Grid container spacing={0} className="h-100">
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <TableHeaderFilter sectionBaseUrl={sectionBaseUrl} />
                    <TableContainer className={classes.container}>
                        {isLoading ? (
                            <div className="mainLoader">
                                <div className="loader" />
                            </div>
                        ) : (
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {tableHeader?.map((column) => (
                                            <TableCell key={column.id}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listingsData?.posts &&
                                    listingsData?.posts.length ? (
                                        listingsData?.posts?.map((row) => {
                                            let pending = 0,
                                                joined = 0;

                                            if (
                                                row?.reffere_email &&
                                                row?.reffere_email.length
                                            ) {
                                                row?.reffere_email.forEach(
                                                    (d) => {
                                                        if (
                                                            d?.status ===
                                                            'PENDING'
                                                        ) {
                                                            pending += 1;
                                                        } else if (
                                                            d?.status ===
                                                            'JOINED'
                                                        ) {
                                                            joined += 1;
                                                        }
                                                    }
                                                );
                                            }

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row?.user_id}
                                                    style={{
                                                        padding: 0,
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        <span>
                                                            {row?.referrer}
                                                        </span>
                                                        <span className="d-block">
                                                            {
                                                                row?.referrer_email
                                                            }
                                                        </span>
                                                        <span className="font-weight-bold d-block">
                                                            {pending} Pending,{' '}
                                                            {joined} Joined
                                                        </span>
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row?.reffere_email &&
                                                        row?.reffere_email
                                                            .length ? (
                                                            <div className="d-flex flex-direction-column">
                                                                {renderReffereEmail(
                                                                    row?.reffere_email,
                                                                    row?.user_id
                                                                )}
                                                                {row
                                                                    ?.reffere_email
                                                                    .length >
                                                                3 ? (
                                                                    <div>
                                                                        <Button
                                                                            onClick={() =>
                                                                                handleToggleEmails(
                                                                                    row?.user_id
                                                                                )
                                                                            }
                                                                            color="primary"
                                                                            size="small"
                                                                            className="mt-2"
                                                                        >
                                                                            Show{' '}
                                                                            {row
                                                                                ?.reffere_email
                                                                                .length >
                                                                                3 &&
                                                                            Object.keys(
                                                                                toggleEmails
                                                                            )
                                                                                .length &&
                                                                            toggleEmails[
                                                                                row
                                                                                    ?.user_id
                                                                            ]
                                                                                ? 'Less'
                                                                                : 'More'}
                                                                        </Button>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={tableHeader.length + 1}
                                                className="dataNotAvailable"
                                            >
                                                Data not available
                                            </td>
                                        </tr>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
};
export default connect(null, { GetUserReferral })(EmailReferralComponent);
