import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { GetSingleUser, UpdateStrategy } from '../../../redux/Users/actions';

import {
    Button,
    CircularProgress,
    Container,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import axios from 'axios';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Routes, SOCIAL_MEDIA_LIST } from '../../../constants';
import {
    CommonDispatcher,
    GetBusinessModelMaster,
    GetFirmsList,
    GetInvestmentsTypeMaster,
    GetOneFirm,
    GetRegions,
    GetSdgGoals,
    GetSectors,
    GetStages,
    GetStartupTypeMaster,
    GetFirmsClassify
} from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { RootState } from '../../../redux/store';
import { addCommas, isValidURL, removeNonNumeric } from '../../../utils/common';
import { ACCOUNT_TYPE } from '../../../utils/constants';
import InfoIconComponent from '../../Common/InfoIconComponent';
import RenderAutocompleteTextField from '../../InputComponents/AutocompleteTextField';
import RenderCreatableAutocomplete from '../../InputComponents/CreatableAutocomplete';
import CreatableAutocompleteWithoutFocus from '../../InputComponents/CreatableAutocompleteWithoutFocus';
import RenderMaskedTextField from '../../InputComponents/MaskedTextField';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import RenderCheckboxComponent from '../../InputComponents/CheckboxComponent';
import style from './Style.module.scss';
import { Link } from 'react-router-dom';

const EditStrategy = ({
    firm_id = null,
    GetStartupTypeMaster,
    GetBusinessModelMaster,
    GetInvestmentsTypeMaster,
    GetSectors,
    GetStages,
    GetSdgGoals,
    GetRegions,
    GetOneFirm,
    match,
    GetSingleUser,
    GetFirmsList,
    CommonDispatcher,
    UpdateStrategy,
    GetFirmsClassify
}) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const {
        params: { user_id }
    } = match;

    const [firmsList, setFirmsList] = useState([]);
    const [firm_type, setFirmType] = useState([]);
    const [sdgGoalsList, setSdgGoalsList] = useState([]);

    const [company_name, setCompanyName] = useState([]);
    const [website, setFirmWebsite] = useState('');
    const [accountType, setAccountType] = useState('');
    const [firmTypeSize, setFirmTypeSize] = useState<number>(0);
    const MAX_FIRM_TYPE_TAGS_SIZE = 2;
    const MAX_STARTUP_TYPE_TAGS_SIZE = 1;
    const type = accountType || ACCOUNT_TYPE.INVESTOR;
    const isStartup = type === ACCOUNT_TYPE?.STARTUP;

    const [STAGE_LIST, setStageList] = useState([]);
    const [FIRM_TYPE_LIST, setFirmTypeList] = useState([]);

    const [investment_class, setInvestmentAssets] = useState([]);
    const [business_model, setBusinessModel] = useState([]);
    const [preferred_sector, setSectors] = useState([]);
    const [preferred_stage, setCurrentFundingStageId] = useState([]);
    const [sectorsList, setSectorsList] = useState([]);
    const [investment_geos, setHqGeo] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [is_not_fundraising, setFundraising] = useState(false);

    const [min, setMin] = React.useState(null);
    const [max, setMax] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [investmentsType, setInvestmentsType] = useState([]);
    const [sdgGoals, setSdgGoals] = useState([]);
    const [selectedOpsGeosList, setSelectedOpsGeosList] = useState([]);

    const [regionsList, setRegionsList] = useState([]);
    const store = useSelector((store: RootState) => store);

    const startupType = store?.commonAppData?.startupType || [];
    const businessModel = store?.commonAppData?.businessModel || [];
    const CURRENTLY_RAISING_LIST =
        store?.commonAppData?.stages?.stages?.CURRENTLY_RAISING_LIST || [];
    const sdgGoalsArray = store?.commonAppData?.sdgGoals || [];

    const [regions, setRegions] = useState([]);

    const [investmentsTypeList, setInvestmentsTypeList] = useState([]);

    const history = useHistory();

    const {
        control,
        reset,
        setValue,
        getValues,
        handleSubmit,
        register,
        errors
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'investor_profile'
    });
    useEffect(() => {
        let sdgGoalsData = [];
        sdgGoalsArray.forEach((d) => {
            sdgGoalsData.push({ title: d?.title, value: d?.id });
        });
        sdgGoalsData = sdgGoalsData.sort((a, b) => a?.value - b?.value);
        setSdgGoalsList(sdgGoalsData);
    }, [sdgGoalsArray]);
    useEffect(() => {
        setIsLoading(true);
        const arr = [];
        arr.push(GetStartupTypeMaster());
        arr.push(GetBusinessModelMaster());
        arr.push(GetSectors());
        arr.push(GetStages());
        arr.push(GetSdgGoals());
        arr.push(GetRegions());
        arr.push(GetFirmsClassify());

        if (!isStartup) {
            arr.push(GetInvestmentsTypeMaster());
        }
        axios.all(arr).then((resp) => {
            const countries = resp?.[0]?.response?.startupType;
            const regions = resp?.[5]?.response?.regions;

            setStageList(resp?.[3]?.response?.stages?.STAGE_LIST);
            setFirmTypeList(resp?.[6]?.response);
            let sectorsData = [];
            resp?.[2]?.response?.sectors?.forEach((d) => {
                sectorsData.push({ title: d?.title, value: d?.id });
            });
            setSectorsList(sectorsData);
            let regionsData = [];
            regions.forEach((d) => {
                regionsData.push({
                    title: d?.title,
                    value: d?.id,
                    countries: d?.countries
                });
            });
            regionsData = regionsData.sort((a, b) => a?.value - b?.value);
            setRegionsList(regionsData);
            setInvestmentsTypeList(resp?.[7]?.response?.investmentsType);

            if (user_id) {
                GetSingleUser(token, { user_id }).then((r) => {
                    let data = { ...r?.response };

                    setSingleFirm(data, countries, regionsData);
                });
            } else {
                setSingleFirm({});
                setIsLoading(false);
            }
        });
    }, []);
    const fieldsToValidate = ['min_ticket_size', 'max_ticket_size'];
    const setSingleFirm = (
        data: any = {},
        countriesList = [],
        regionsArray = []
    ) => {
        let jsonData: any = {};
        setFundraising(data?.is_not_fundraising);
        Object.entries(data).forEach(([k]) => {
            if (fieldsToValidate.includes(k)) {
                jsonData[k] = data[k];
            }
        });

        jsonData.sdg_sectors = data?.sdg_sectors;
        const investment_class_assets =
            jsonData?.investment_class && jsonData?.investment_class.length
                ? jsonData?.investment_class.map((r) => ({
                      value: r?.id,
                      title: r?.label
                  }))
                : [];

        const business_model_list =
            jsonData?.business_model && jsonData?.business_model.length
                ? jsonData?.business_model.map((r) => ({
                      value: r?.id,
                      title: r?.label
                  }))
                : [];

        const preferred_sector_list =
            jsonData?.preferred_sector && jsonData?.preferred_sector.length
                ? jsonData?.preferred_sector.map((r) => ({
                      value: r?.sector_id,
                      title: r?.label
                  }))
                : [];

        const sdg_sectors_list =
            jsonData?.sdg_sectors && jsonData?.sdg_sectors.length
                ? jsonData?.sdg_sectors.map((r) => ({
                      value: r?.sdg_sector_id,
                      title: r?.label
                  }))
                : [];

        const preferred_stage_list =
            jsonData?.preferred_stage && jsonData?.preferred_stage.length
                ? jsonData?.preferred_stage.map((r) => ({
                      value: r?.funding_stage_id,
                      title: r?.label
                  }))
                : [];

        const investment_geos_list =
            jsonData?.investment_geos && jsonData?.investment_geos.length
                ? countriesList.filter((d) =>
                      jsonData?.investment_geos
                          .map((a) => a.country_id)
                          .includes(d?.value)
                  )
                : [];

        const regions_list =
            jsonData?.regions && jsonData?.regions.length
                ? regionsArray.filter((d) =>
                      jsonData?.regions
                          .map((a) => a.region_id)
                          .includes(d?.value)
                  )
                : [];
        setRegions(regions_list);

        const investors = isStartup
            ? jsonData?.last_funding_stage &&
              jsonData?.last_funding_stage.length
                ? jsonData?.last_funding_stage.map((r) => ({
                      value: r?.funding_stage_id,
                      title: r?.label
                  }))
                : []
            : jsonData?.investor_type && jsonData?.investor_type.length
            ? jsonData?.investor_type.map((r) => ({
                  value: r?.firm_classify_id,
                  title: r?.label
              }))
            : [];
        let obj: any = {
            company_name: jsonData?.company_name,
            website: jsonData?.website,
            min_ticket_size: jsonData?.min_ticket_size,
            max_ticket_size: jsonData?.max_ticket_size,
            business_model: business_model_list,
            investment_class: investment_class_assets,
            preferred_sector: preferred_sector_list,
            sdg_sectors: sdg_sectors_list,
            preferred_stage: preferred_stage_list,
            regions: regions_list,
            investment_geos: investment_geos_list,
            investor_profile:
                jsonData?.investor_profile && jsonData?.investor_profile.length
                    ? jsonData?.investor_profile
                    : [{ type: '', profile_url: '' }]
        };
        if (!isStartup) {
            obj.investor_type = investors;
            const investmentsType =
                jsonData?.investments_type && jsonData?.investments_type.length
                    ? jsonData?.investments_type.map((r) => ({
                          title: r?.name,
                          value: r?.id
                      }))
                    : [];
            obj.investments_type = investmentsType;
            setInvestmentsType(investmentsType);
        } else {
            obj.last_funding_stage = investors;
        }

        setSelectedOpsGeosList(countriesList);
        setInvestmentAssets(investment_class_assets);
        setCompanyName(jsonData?.company_name);
        setFirmWebsite(jsonData?.website);

        setBusinessModel(business_model_list);
        setSectors(preferred_sector_list);
        setSdgGoals(sdg_sectors_list);
        setCurrentFundingStageId(preferred_stage_list);
        setHqGeo(investment_geos_list);
        setFirmType(investors);

        if (jsonData?.min_ticket_size) {
            let min = addCommas(
                removeNonNumeric(
                    jsonData?.min_ticket_size.toString().slice(0, 18)
                )
            );
            setMin(min);
        }
        if (jsonData?.max_ticket_size) {
            let max = addCommas(
                removeNonNumeric(
                    jsonData?.max_ticket_size.toString().slice(0, 18)
                )
            );
            setMax(max);
        }

        reset(obj);
        setIsLoading(false);
    };

    const handleHQGeographiesChange = (value) => {
        setHqGeo(value);
    };

    const handleInvestmentChange = (value) => {
        setInvestmentAssets(value);
    };
    const handleSdgGoalsChange = (value) => {
        setSdgGoals(value);
    };
    const handleRegionsChange = (values) => {
        setRegions(values);
        let geos = values
            .map((d) => d.countries)
            .flat()
            .map((d) => ({
                title: d?.label,
                value: d?.country_id
            }))
            .filter((d) => d.value && d.title);
        const distinct = [];
        for (let i = 0; i < geos.length; i++) {
            if (!distinct.map((d: any) => d.value).includes(geos[i].value)) {
                distinct.push(geos[i]);
            }
        }
        setHqGeo(distinct);
        setSelectedOpsGeosList(distinct);
        setValue('investment_geos', distinct);
    };

    const handleBusinessModelChange = (value) => {
        setBusinessModel(value);
    };

    const handleSectorsChange = (value) => {
        setSectors(value);
    };

    const handleCurrentFundingStageChange = (value) => {
        setCurrentFundingStageId(value);
    };

    const handleFirmTypeChange = (value) => {
        setFirmType(value);
        setFirmTypeSize(value.length as number);
    };
    const handleInvestmentsTypeChange = (value) => {
        setInvestmentsType(value);
    };

    const validateLink = (index) => {
        const type =
            errors?.investor_profile &&
            errors?.investor_profile?.length &&
            errors?.investor_profile[index]?.profile_url?.type;
        const required = type === 'required';
        const validate = type === 'validate';
        const websiteError = required
            ? 'Social profile link is mandatory.'
            : validate
            ? 'Invalid social profile link.'
            : '';

        return (
            <FormHelperText
                error={true}
                className={required || validate ? 'visible' : 'invisible'}
            >
                {websiteError}
            </FormHelperText>
        );
    };

    const createAccount = (d) => {
        // e && e.preventDefault();
        // const obj = { ...d };

        // setSubmitLoading(true);

        // if (obj?.investor_type && obj?.investor_type.length) {
        //     obj.investor_type = obj?.investor_type.map((d) => ({
        //         type: d?.title
        //     }));
        // }
        // if (obj?.investment_geos && obj?.investment_geos.length) {
        //     obj.investment_geos = obj?.investment_geos.map((d) => d?.value);
        // }
        // if (obj?.investment_class && obj?.investment_class.length) {
        //     obj.investment_class = obj?.investment_class.map((d) => ({
        //         investment_class: d?.title
        //     }));
        // }
        // if (obj?.business_model && obj?.business_model.length) {
        //     obj.business_model = obj?.business_model.map((d) => ({
        //         business_model: d?.title
        //     }));
        // }
        // if (obj?.preferred_sector && obj?.preferred_sector.length) {
        //     obj.preferred_sector = obj?.preferred_sector.map((d) => ({
        //         type: d?.title
        //     }));
        // }
        // if (obj?.preferred_stage && obj?.preferred_stage.length) {
        //     obj.preferred_stage = obj?.preferred_stage.map((d) => ({
        //         stage: d?.title
        //     }));
        // }

        // if (obj.min_ticket_size) {
        //     obj.min_ticket_size = parseFloat(
        //         obj.min_ticket_size.toString().replace(/,/g, '')
        //     );
        // }
        // if (obj.max_ticket_size) {
        //     obj.max_ticket_size = parseFloat(
        //         obj.max_ticket_size.toString().replace(/,/g, '')
        //     );
        // }
        // let data: any = getValues();
        const obj = { ...d };

        obj.user_id = user_id;
        obj.company_name = company_name;
        obj.website = website;
        obj.is_not_fundraising = is_not_fundraising;

        if (isStartup) {
            if (obj?.last_funding_stage && obj?.last_funding_stage.length) {
                obj.last_funding_stage = obj?.last_funding_stage.map(
                    (d) => d?.value
                );
            }
        } else {
            if (obj?.investor_type && obj?.investor_type.length) {
                obj.investor_type = obj?.investor_type.map((d) => d?.value);
            }
        }
        if (obj?.investment_geos && obj?.investment_geos.length) {
            obj.investment_geos = obj?.investment_geos.map((d) => d?.value);
        }
        if (obj?.regions && obj?.regions.length) {
            obj.regions = obj?.regions.map((d) => d?.value);
        }
        if (obj?.investment_class && obj?.investment_class.length) {
            obj.investment_class = obj?.investment_class.map((d) => d.value);
        }
        if (obj?.business_model && obj?.business_model.length) {
            obj.business_model = obj?.business_model.map((d) => d?.value);
        }

        if (obj?.preferred_sector && obj?.preferred_sector.length) {
            obj.preferred_sector = obj?.preferred_sector.map((d) => d?.value);
        }
        if (obj?.sdg_sectors && obj?.sdg_sectors.length) {
            obj.sdg_sectors = obj?.sdg_sectors.map((d) => d?.value);
        }

        if (obj?.investments_type && obj?.investments_type.length) {
            obj.investments_type = obj?.investments_type.map((d) => d?.value);
        }
        if (obj?.preferred_stage && obj?.preferred_stage.length) {
            obj.preferred_stage = obj?.preferred_stage.map((d) => d.value);
        } else {
            obj.preferred_stage = [];
        }

        if (obj.min_ticket_size) {
            obj.min_ticket_size = parseFloat(
                obj.min_ticket_size.toString().replace(/,/g, '')
            );
        } else {
            obj.min_ticket_size = null;
        }
        if (obj.max_ticket_size) {
            obj.max_ticket_size = parseFloat(
                obj.max_ticket_size.toString().replace(/,/g, '')
            );
        } else {
            obj.max_ticket_size = null;
        }
        if (obj?.investor_profile && obj?.investor_profile.length) {
            obj.investor_profile = obj?.investor_profile.filter(
                (d) => d?.type && d?.profile_url
            );
        } else {
            obj.investor_profile = [];
        }

        UpdateStrategy(token, {
            type,
            firm_id,
            ...obj
        }).then((r: any) => {
            if (r?.type === 'success') {
                GetSingleUser(token, { user_id }).then(() => {
                    setSubmitLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Details updated successfully.',
                        hideAlert: true
                    });
                });
            } else {
                setSubmitLoading(false);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message:
                        r?.response?.data?.error || 'Failed to update details.',
                    hideAlert: true,
                    error: true
                });
            }
        });
    };

    const handleCompanyNameChange = (value) => {
        setCompanyName(value?.title);
        setFirmWebsite(value?.value2);
        setValue('website', website);
    };

    const handleFirmWebsiteChange = (e) => {
        setFirmWebsite(e?.target?.value);
        setValue('website', e?.target?.value);
    };

    const toggle = () => {
        const flag = !is_not_fundraising;
        if (flag) {
            handleCurrentFundingStageChange([]);
            setValue('preferred_stage', []);
            setValue('min_ticket_size', '');
            setValue('max_ticket_size', '');
        }
        setFundraising(flag);
    };

    const handleInputChange = (value) => {
        GetFirmsList(token, value).then((r) => {
            const firms = r?.response?.firms;
            if (firms && firms.length) {
                const firmsData = firms.map((d) => ({
                    title: d?.label,
                    value: d?.firm_id,
                    value2: d?.website
                }));
                setFirmsList(firmsData);
            }
        });
    };

    const handleRedirect = (pathname) => {
        history.push(`${pathname}/${user_id}`);
    };

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <form onSubmit={handleSubmit(createAccount)}>
            <Container maxWidth="md" className="my-5 pb-5">
                <Grid item xs={12} sm={8} className="m-auto">
                    <h2>Edit Strategy</h2>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            className="d-flex align-items-center justify-content-end
                                justify-content-xs-center mb-5"
                        >
                            <Link
                                to={
                                    `${Routes.users.users}/edit-user/${user_id}` ||
                                    ''
                                }
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className="text-inherit mr-2"
                                    // onClick={() =>
                                    //     handleRedirect(
                                    //         `${Routes.users.users}/edit-user`
                                    //     )
                                    // }
                                >
                                    Edit User
                                </Button>
                            </Link>
                            <Link
                                to={
                                    `${Routes.users.users}/view/${user_id}` ||
                                    ''
                                }
                            >
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    className="text-inherit"
                                    // onClick={() =>
                                    //     handleRedirect(`${Routes.users.users}/view`)
                                    // }
                                >
                                    View User
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Controller
                                name="company_name"
                                render={({ onChange }) => {
                                    return (
                                        <CreatableAutocompleteWithoutFocus
                                            id={`company_name`}
                                            label={`Name of your firm`}
                                            inputAutoComplete="off"
                                            value={company_name}
                                            options={firmsList}
                                            openOnFocus={false}
                                            disableClearable={true}
                                            disableCloseOnSelect={false}
                                            handleInputChange={
                                                handleInputChange
                                            }
                                            onBlur={(e: any) => {
                                                const value = e?.target?.value;
                                                onChange({
                                                    title: value,
                                                    value: value
                                                });
                                                setCompanyName(value);
                                            }}
                                            onChange={(e, data) => {
                                                onChange(data);
                                                handleCompanyNameChange(data);
                                            }}
                                            inputPlaceholder="Search or Add Company name"
                                        />
                                    );
                                }}
                                control={control}
                                // rules={{
                                //     required: true
                                // }}
                            />
                            {/* <FormHelperText
                                error={true}
                                className={
                                    errors?.company_name
                                        ? 'visible'
                                        : 'invisible'
                                }
                            >
                                Firm name is mandatory.
                            </FormHelperText> */}
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Controller
                                name="website"
                                render={({ onChange }) => {
                                    return (
                                        <RenderTextField
                                            inputContainerClassName={'w-100'}
                                            type="text"
                                            label={`Website`}
                                            value={website}
                                            onChange={(e) => {
                                                onChange(e);
                                                handleFirmWebsiteChange(e);
                                            }}
                                            autoComplete="off"
                                        />
                                    );
                                }}
                                control={control}
                                // rules={{
                                //     required: true
                                // }}
                            />
                            <FormHelperText
                                error={true}
                                className={
                                    getValues(`website`) &&
                                    !isValidURL(getValues(`website`))
                                        ? 'visible'
                                        : 'invisible'
                                }
                            >
                                {/* {errors?.website && 'Website is mandatory.'} */}
                                {getValues(`website`) &&
                                    !isValidURL(getValues(`website`)) &&
                                    'Invalid website url.'}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    {!isStartup ? (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Controller
                                    name="investor_type"
                                    render={({ onChange, value }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                id="investor_type"
                                                value={firm_type}
                                                label={`${
                                                    isStartup
                                                        ? 'What was your last funding stage'
                                                        : 'How do you classify your firm (up to 3)'
                                                }?`}
                                                inputAutoComplete="off"
                                                size="small"
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleFirmTypeChange(data);
                                                }}
                                                options={
                                                    firmTypeSize <
                                                    (isStartup
                                                        ? MAX_STARTUP_TYPE_TAGS_SIZE
                                                        : MAX_FIRM_TYPE_TAGS_SIZE)
                                                        ? isStartup
                                                            ? STAGE_LIST
                                                            : FIRM_TYPE_LIST
                                                        : []
                                                }
                                                inputPlaceholder="Search type of firm"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    inputRef={register}
                                    // rules={{
                                    //     required: true
                                    // }}
                                />
                                {/* <FormHelperText
                                    error={true}
                                    className={
                                        errors?.firm_type
                                            ? 'visible'
                                            : 'invisible'
                                    }
                                >
                                    Firm type is mandatory.
                                </FormHelperText> */}
                            </Grid>
                        </Grid>
                    ) : null}

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Controller
                                name="investments_type"
                                render={({ onChange, value }) => (
                                    <div className="w-100">
                                        <RenderAutocompleteTextField
                                            id="investments_type"
                                            value={investmentsType}
                                            label="Investing in"
                                            inputAutoComplete="off"
                                            // disableCloseOnSelect={!smallScreen}
                                            size="small"
                                            onChange={(e, data) => {
                                                onChange(data);
                                                handleInvestmentsTypeChange(
                                                    data
                                                );
                                            }}
                                            options={investmentsTypeList}
                                            inputPlaceholder="Search investment type"
                                        />
                                    </div>
                                )}
                                control={control}
                                inputRef={register}
                            />
                            {/* <FormHelperText
                                error={true}
                                className={
                                    errors?.investments_type
                                        ? 'visible'
                                        : 'invisible'
                                }
                            >
                                Investments type is mandatory.
                            </FormHelperText> */}
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {fields.map((item, index) => {
                                return (
                                    <div
                                        key={item?.id}
                                        className="w-100 d-flex flex-direction-xs-column align-items-flex-start"
                                    >
                                        <div className="mt-3 mr-3 mr-sm-0 w-100">
                                            <Controller
                                                name={`investor_profile[${index}].type`}
                                                render={({
                                                    onChange,
                                                    value
                                                }) => {
                                                    return (
                                                        <div className="w-100">
                                                            <RenderCreatableAutocomplete
                                                                id={`investor_profile[${index}].type`}
                                                                value={
                                                                    value || []
                                                                }
                                                                label={`Social profile ${
                                                                    index + 1
                                                                }`}
                                                                inputAutoComplete="off"
                                                                options={
                                                                    SOCIAL_MEDIA_LIST
                                                                }
                                                                disableCloseOnSelect={
                                                                    false
                                                                }
                                                                onBlur={(
                                                                    e: any
                                                                ) => {
                                                                    const value =
                                                                        e
                                                                            ?.target
                                                                            ?.value;
                                                                    onChange(
                                                                        value
                                                                    );
                                                                }}
                                                                onChange={(
                                                                    e,
                                                                    data
                                                                ) =>
                                                                    onChange(
                                                                        data
                                                                    )
                                                                }
                                                                inputPlaceholder="Search or Add Social Platform"
                                                            />
                                                        </div>
                                                    );
                                                }}
                                                control={control}
                                                defaultValue={item.type || null}
                                                onChange={([event, data]) => {
                                                    return data;
                                                }}
                                                // rules={{
                                                //     required: true
                                                // }}
                                            />
                                        </div>
                                        <div className="mt-3 mr-3 mr-sm-0 w-100">
                                            <Controller
                                                name={`investor_profile[${index}].profile_url`}
                                                as={
                                                    <RenderTextField
                                                        id={`investor_profile[${index}].profile_url`}
                                                        inputContainerClassName={
                                                            'w-100'
                                                        }
                                                        label={`Link/URL ${
                                                            index + 1
                                                        }`}
                                                    />
                                                }
                                                control={control}
                                                inputRef={register}
                                                defaultValue={
                                                    item.profile_url || null
                                                }
                                                rules={{
                                                    // required: true,
                                                    validate: (link) => {
                                                        return isValidURL(link);
                                                    }
                                                }}
                                            />
                                            {validateLink(index)}
                                        </div>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                remove(index);
                                            }}
                                            className="d-flex align-self-flex-xs-end mt-5 mt-xs-0"
                                        >
                                            <Delete />
                                        </IconButton>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="mb-3">
                        <Grid item xs={12} className="mt-3">
                            <Button
                                color="primary"
                                variant="outlined"
                                type="button"
                                disabled={fields?.length >= 10}
                                className="float-right"
                                size="small"
                                onClick={() => {
                                    append({ type: '', profile_url: '' });
                                }}
                            >
                                <Add className="mr-2" />
                                Add new
                            </Button>
                        </Grid>
                    </Grid>

                    <div className="mt-5">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={6}>
                                <Controller
                                    name="investment_class"
                                    render={({ onChange, value }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                id="investment_class"
                                                value={investment_class}
                                                label={`${
                                                    isStartup
                                                        ? 'Startup type'
                                                        : 'Preferred Asset Classes'
                                                }`}
                                                inputAutoComplete="off"
                                                size="small"
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleInvestmentChange(
                                                        data
                                                    );
                                                }}
                                                options={startupType}
                                                inputPlaceholder="Search investment assets"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    // rules={{
                                    //     required: true
                                    // }}
                                />
                                {/* <FormHelperText
                                    error={true}
                                    className={
                                        errors?.investment_class
                                            ? 'visible'
                                            : 'invisible'
                                    }
                                >
                                    Preferred Investment Asset Classes is
                                    mandatory.
                                </FormHelperText> */}
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <Controller
                                    name="business_model"
                                    render={({ onChange, value }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                id="business_model"
                                                value={business_model}
                                                label={`${
                                                    isStartup
                                                        ? 'Business Model'
                                                        : 'Preferred Business Models'
                                                }`}
                                                inputAutoComplete="off"
                                                size="small"
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleBusinessModelChange(
                                                        data
                                                    );
                                                }}
                                                options={businessModel}
                                                inputPlaceholder="Search business model"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    // rules={{
                                    //     required: true
                                    // }}
                                />
                                {/* <FormHelperText
                                    error={true}
                                    className={
                                        errors?.business_model
                                            ? 'visible'
                                            : 'invisible'
                                    }
                                >
                                    Preferred Business Models is mandatory.
                                </FormHelperText> */}
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Controller
                                    name="preferred_sector"
                                    render={({ onChange }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                id="preferred_sector"
                                                value={preferred_sector}
                                                label={`${
                                                    isStartup
                                                        ? 'Startup Sector'
                                                        : 'Preferred Sectors'
                                                }`}
                                                inputAutoComplete="off"
                                                size="small"
                                                options={sectorsList}
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleSectorsChange(data);
                                                }}
                                                inputPlaceholder="Search sectors"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    // rules={{
                                    //     required: true
                                    // }}
                                />
                                {/* <FormHelperText
                                    error={true}
                                    className={
                                        errors?.preferred_sector
                                            ? 'visible'
                                            : 'invisible'
                                    }
                                >
                                    Preferred preferred_sector is mandatory.
                                </FormHelperText> */}
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Controller
                                    name="preferred_stage"
                                    render={({ onChange }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                id="preferred_stage"
                                                value={preferred_stage}
                                                label={
                                                    <div className="d-flex position-relative">
                                                        <span>
                                                            {isStartup
                                                                ? 'Currently fundraising stage'
                                                                : 'Preferred Stages'}
                                                        </span>
                                                        {isStartup ? (
                                                            <InputLabel
                                                                shrink
                                                                htmlFor="price"
                                                                className="position-absolute right-0"
                                                            >
                                                                <FormControlLabel
                                                                    label="Is Not Fundraising"
                                                                    className={`position-absolute w200 right-0 ${style.top13} ${style.w200}`}
                                                                    labelPlacement="start"
                                                                    control={
                                                                        <RenderCheckboxComponent
                                                                            id={`is_not_fundraising`}
                                                                            name={`is_not_fundraising`}
                                                                            classes={{
                                                                                root: style.checkboxHeight
                                                                            }}
                                                                            checked={
                                                                                is_not_fundraising
                                                                            }
                                                                            onChange={(
                                                                                e: any
                                                                            ) => {
                                                                                toggle();
                                                                            }}
                                                                            color="secondary"
                                                                        />
                                                                    }
                                                                />
                                                            </InputLabel>
                                                        ) : null}
                                                    </div>
                                                }
                                                inputAutoComplete="off"
                                                size="small"
                                                disabled={is_not_fundraising}
                                                options={CURRENTLY_RAISING_LIST}
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleCurrentFundingStageChange(
                                                        data
                                                    );
                                                }}
                                                inputPlaceholder="Search stages"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    // rules={{
                                    //     required: true
                                    // }}
                                />
                                {/* <FormHelperText
                                    error={true}
                                    className={
                                        errors?.preferred_stage
                                            ? 'visible'
                                            : 'invisible'
                                    }
                                >
                                    Target Stages is mandatory.
                                </FormHelperText> */}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="sdg_sectors"
                                    render={({ onChange }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                id="sdg_sectors"
                                                value={sdgGoals}
                                                label={
                                                    <span>
                                                        SDG Goals{' '}
                                                        <InfoIconComponent />
                                                    </span>
                                                }
                                                size="small"
                                                inputAutoComplete="off"
                                                // disableCloseOnSelect={
                                                //     !smallScreen
                                                // }
                                                // paperClass={
                                                //     style.autocompleteBackground
                                                // }
                                                options={sdgGoalsList}
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleSdgGoalsChange(data);
                                                }}
                                                inputPlaceholder="Search sdg goals"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    defaultValue={null}
                                />
                                {/* <FormHelperText
                                    error={true}
                                    className={'invisible'}
                                >
                                    &nbsp;
                                </FormHelperText> */}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="my-1">
                            <Grid item xs={12} className="py-1">
                                <Controller
                                    name="regions"
                                    render={({ onChange, value }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                id=""
                                                value={regions}
                                                label={
                                                    isStartup
                                                        ? 'Active Regions'
                                                        : 'Preferred Regions'
                                                }
                                                size="small"
                                                inputAutoComplete="off"
                                                // paperClass={
                                                //     style.autocompleteBackground
                                                // }
                                                // disableCloseOnSelect={
                                                //     !smallScreen
                                                // }
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleRegionsChange(data);
                                                }}
                                                options={regionsList}
                                                inputPlaceholder="Search regions"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    defaultValue={null}
                                />
                                {/* <FormHelperText
                                    error={true}
                                    className={
                                        errors?.regions
                                            ? 'visible'
                                            : 'invisible'
                                    }
                                >
                                    Preferred Regions is mandatory.
                                </FormHelperText> */}
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Controller
                                    name="investment_geos"
                                    render={({ onChange, value }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                id="investment_geos"
                                                value={investment_geos}
                                                label={`${
                                                    isStartup
                                                        ? 'Startup active geographies'
                                                        : 'Preferred Geographies'
                                                }`}
                                                size="small"
                                                inputAutoComplete="off"
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleHQGeographiesChange(
                                                        data
                                                    );
                                                }}
                                                options={countriesList}
                                                inputPlaceholder="Search Geographies to invest in"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    // rules={{
                                    //     required: true
                                    // }}
                                />
                                {/* <FormHelperText
                                    error={true}
                                    className={
                                        errors?.investment_geos
                                            ? 'visible'
                                            : 'invisible'
                                    }
                                >
                                    Preferred Geographies is mandatory.
                                </FormHelperText> */}
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="min_ticket_size"
                                    as={
                                        <RenderMaskedTextField
                                            id="min_ticket_size"
                                            name="min_ticket_size"
                                            value={min}
                                            min={1}
                                            disabled={is_not_fundraising}
                                            error={min && min < 0}
                                            inputContainerClassName={'w-100'}
                                            label={`Min ${
                                                isStartup
                                                    ? 'round'
                                                    : 'investment'
                                            } size (USD)`}
                                        />
                                    }
                                    defaultValue={0}
                                    control={control}
                                />
                                {!isStartup ? (
                                    <></>
                                ) : // <FormHelperText
                                //     error={true}
                                //     className={
                                //         errors?.min_ticket_size
                                //             ? 'visible'
                                //             : 'invisible'
                                //     }
                                // >
                                //     {errors?.min_ticket_size &&
                                //         'Min Investment size is mandatory.'}
                                // </FormHelperText>
                                null}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="max_ticket_size"
                                    as={
                                        <RenderMaskedTextField
                                            id="max_ticket_size"
                                            name="max_ticket_size"
                                            value={max}
                                            min={1}
                                            disabled={is_not_fundraising}
                                            max={100000000000}
                                            error={max && max > 100000000000}
                                            inputContainerClassName={'w-100'}
                                            label={`Max ${
                                                isStartup
                                                    ? 'round'
                                                    : 'investment'
                                            } size (USD)`}
                                        />
                                    }
                                    defaultValue={0}
                                    control={control}
                                />
                                {!isStartup ? (
                                    <></>
                                ) : // <FormHelperText
                                //     error={true}
                                //     className={
                                //         errors?.max_ticket_size
                                //             ? 'visible'
                                //             : 'invisible'
                                //     }
                                // >
                                //     {errors?.max_ticket_size &&
                                //         'Max Investment size is mandatory.'}
                                // </FormHelperText>
                                null}
                            </Grid>
                        </Grid>

                        {(min && min < 0) || (max && max > 100000000000) ? (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormHelperText
                                        error={true}
                                        className={
                                            min && min < 0
                                                ? 'visible'
                                                : 'invisible'
                                        }
                                    >
                                        Min should not be less than 0.1
                                    </FormHelperText>

                                    <FormHelperText
                                        error={true}
                                        className={
                                            max && max > 100000000000
                                                ? 'visible'
                                                : 'invisible'
                                        }
                                    >
                                        Max should not be greater than
                                        100,000,000,000
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                        ) : null}
                    </div>

                    <div className="mt-5 w-100 d-flex align-items-center justify-content-end">
                        <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitLoading}
                            variant="contained"
                        >
                            Save
                            {isSubmitLoading ? (
                                <CircularProgress
                                    size="1.3rem"
                                    className="ml-2 circular-progress"
                                />
                            ) : null}
                        </Button>
                    </div>
                </Grid>
            </Container>
        </form>
    );
};

export default connect(null, {
    GetSingleUser,
    GetStartupTypeMaster,
    GetBusinessModelMaster,
    GetInvestmentsTypeMaster,
    GetSectors,
    GetStages,
    GetSdgGoals,
    GetRegions,
    GetOneFirm,
    GetFirmsList,
    CommonDispatcher,
    UpdateStrategy,
    GetFirmsClassify
})(EditStrategy);
