import {
    Button,
    Grid,
    Popover,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { Restore, Settings } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import style from './Styles.module.scss';
import RenderAutocompleteTextField from '../../InputComponents/AutocompleteTextField';
import { Controller, useForm } from 'react-hook-form';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import {
    CommonDispatcher,
    GetBusinessModelMaster,
    GetFirmsList,
    GetInvestmentsTypeMaster,
    GetOneFirm,
    GetRegions,
    GetSdgGoals,
    GetSectors,
    GetStages,
    GetStartupTypeMaster,
    GetFirmsClassify,
    GetEcosystemTypeMaster
} from '../../../redux/Common/action';
import { ACCOUNT_TYPE } from '../../../utils/constants';
import axios from 'axios';
import InfoIconComponent from '../../Common/InfoIconComponent';

const FirmsFilterModal = ({
    GetStartupTypeMaster,
    GetBusinessModelMaster,
    GetInvestmentsTypeMaster,
    GetSectors,
    GetStages,
    GetSdgGoals,
    GetRegions,
    GetFirmsClassify,
    GetEcosystemTypeMaster
}: any) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [decodedFilters, setDecodeFilter] = React.useState(null);

    const [statuses, setStatuses]: any = React.useState({});
    const [filters, setFilters] = React.useState({
        business_model_ids: '',
        ecosystem_type_ids: '',
        investment_class_ids: '',
        preferred_sector_ids: '',
        sdg_sectors_ids: '',
        preferred_stage_ids: '',
        regions_ids: '',
        investment_geos_ids: '',
        investor_type_ids: '',
        investments_type_ids: ''
    });
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [eco_system_type, setEcoType] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filter');
    // const ecoSystemTypeList = useSelector(
    //     (store: RootState) => store?.commonAppData.ecoSystemTypeList || []
    // );
    const [ecoSystemTypeList, setEcoSystemTypeList]: any = React.useState([]);

    const history = useHistory();
    const open = Boolean(anchorEl);
    const popUpId = open ? `popover` : undefined;
    const { register, control, reset } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    const handleOpenPopup = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const clearAllFilter = (event) => {
        let params = new URLSearchParams(location.search);
        params.delete('filter');
        const newQueryString = params.toString();

        history.replace({
            pathname: location.pathname,
            search: newQueryString
        });
        setStatuses({});
        setFilters({
            business_model_ids: '',
            ecosystem_type_ids: '',
            investment_class_ids: '',
            preferred_sector_ids: '',
            sdg_sectors_ids: '',
            preferred_stage_ids: '',
            regions_ids: '',
            investment_geos_ids: '',
            investor_type_ids: '',
            investments_type_ids: ''
        });
        setEcoType([]);
        setInvestmentsType([]);
        setFirmType([]);
        setInvestmentAssets([]);
        setBusinessModel([]);
        setSectors([]);
        setCurrentFundingStageId([]);
        setSdgGoals([]);
        setRegions([]);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleToggleRolesType = (e, key) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: e
        }));
    };
    useEffect(() => {
        const searchParams = new URLSearchParams();

        const hasFilters = Object.values(filters).some((value) => value !== '');
        if (hasFilters) {
            const filteredFilters = Object.keys(filters).reduce((acc, key) => {
                if (filters[key]) {
                    acc[key] = filters[key];
                }
                return acc;
            }, {});

            const filterQueryString = encodeURIComponent(
                JSON.stringify(filteredFilters)
            );

            searchParams.set('filter', filterQueryString);

            const newQueryString = searchParams.toString();

            history.replace({
                pathname: location.pathname,
                search: newQueryString
            });
        }
    }, [filters, history, location.pathname]);
    const delayedQuery = useCallback(
        _.debounce((value, key) => handleToggleRolesType(value, key), 1000),
        []
    );
    const handleFirmTypeChange = (key, value) => {
        setEcoType(value);
        if (key === 'ecosystem_type_ids') {
            value = value.map((d) => d?.value);
        }
        delayedQuery(value, key);
    };
    const [firm_type, setFirmType] = useState([]);
    const [sdgGoalsList, setSdgGoalsList] = useState([]);

    const [firmTypeSize, setFirmTypeSize] = useState<number>(0);
    const MAX_FIRM_TYPE_TAGS_SIZE = 2;
    const MAX_STARTUP_TYPE_TAGS_SIZE = 1;
    const type = ACCOUNT_TYPE.INVESTOR;
    const isStartup = type === ACCOUNT_TYPE?.STARTUP;

    const [STAGE_LIST, setStageList] = useState([]);
    const [FIRM_TYPE_LIST, setFirmTypeList] = useState([]);

    const [investment_class, setInvestmentAssets] = useState([]);
    const [business_model, setBusinessModel] = useState([]);
    const [preferred_sector, setSectors] = useState([]);
    const [preferred_stage, setCurrentFundingStageId] = useState([]);
    const [sectorsList, setSectorsList] = useState([]);
    const [investment_geos, setHqGeo] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [is_not_fundraising, setFundraising] = useState(false);

    const [investmentsType, setInvestmentsType] = useState([]);
    const [sdgGoals, setSdgGoals] = useState([]);

    const [regionsList, setRegionsList] = useState([]);
    const store = useSelector((store: RootState) => store);

    const startupType = store?.commonAppData?.startupType || [];
    const businessModel = store?.commonAppData?.businessModel || [];
    const CURRENTLY_RAISING_LIST =
        store?.commonAppData?.stages?.stages?.CURRENTLY_RAISING_LIST || [];
    const sdgGoalsArray = store?.commonAppData?.sdgGoals || [];

    const [regions, setRegions] = useState([]);

    const [investmentsTypeList, setInvestmentsTypeList] = useState([]);

    useEffect(() => {
        let sdgGoalsData = [];
        sdgGoalsArray.forEach((d) => {
            sdgGoalsData.push({ title: d?.title, value: d?.id });
        });
        sdgGoalsData = sdgGoalsData.sort((a, b) => a?.value - b?.value);
        setSdgGoalsList(sdgGoalsData);
    }, [sdgGoalsArray]);
    useEffect(() => {
        if (filterParam) {
            setDecodeFilter(JSON.parse(decodeURIComponent(filterParam)));
        }
    }, [filterParam]);
    const setSingleFirm = (
        data: any = {},
        countriesList = [],
        regionsArray = []
    ) => {
        let jsonData: any = {};
        setFundraising(data?.is_not_fundraising);

        jsonData.sdg_sectors = data?.sdg_sectors;
        const investment_class_assets =
            jsonData?.investment_class && jsonData?.investment_class.length
                ? jsonData?.investment_class.map((r) => ({
                      value: r?.id,
                      title: r?.label
                  }))
                : [];

        const business_model_list =
            jsonData?.business_model && jsonData?.business_model.length
                ? jsonData?.business_model.map((r) => ({
                      value: r?.id,
                      title: r?.label
                  }))
                : [];

        const preferred_sector_list =
            jsonData?.preferred_sector && jsonData?.preferred_sector.length
                ? jsonData?.preferred_sector.map((r) => ({
                      value: r?.sector_id,
                      title: r?.label
                  }))
                : [];

        const sdg_sectors_list =
            jsonData?.sdg_sectors && jsonData?.sdg_sectors.length
                ? jsonData?.sdg_sectors.map((r) => ({
                      value: r?.sdg_sector_id,
                      title: r?.label
                  }))
                : [];

        const preferred_stage_list =
            jsonData?.preferred_stage && jsonData?.preferred_stage.length
                ? jsonData?.preferred_stage.map((r) => ({
                      value: r?.funding_stage_id,
                      title: r?.label
                  }))
                : [];

        const investment_geos_list =
            jsonData?.investment_geos && jsonData?.investment_geos.length
                ? countriesList.filter((d) =>
                      jsonData?.investment_geos
                          .map((a) => a.country_id)
                          .includes(d?.value)
                  )
                : [];

        const regions_list =
            jsonData?.regions && jsonData?.regions.length
                ? regionsArray.filter((d) =>
                      jsonData?.regions
                          .map((a) => a.region_id)
                          .includes(d?.value)
                  )
                : [];
        setRegions(regions_list);

        const investors = isStartup
            ? jsonData?.last_funding_stage &&
              jsonData?.last_funding_stage.length
                ? jsonData?.last_funding_stage.map((r) => ({
                      value: r?.funding_stage_id,
                      title: r?.label
                  }))
                : []
            : jsonData?.investor_type && jsonData?.investor_type.length
            ? jsonData?.investor_type.map((r) => ({
                  value: r?.firm_classify_id,
                  title: r?.label
              }))
            : [];
        let obj: any = {
            business_model: business_model_list,
            investment_class: investment_class_assets,
            preferred_sector: preferred_sector_list,
            sdg_sectors: sdg_sectors_list,
            preferred_stage: preferred_stage_list,
            regions: regions_list,
            investment_geos: investment_geos_list,
            investor_profile:
                jsonData?.investor_profile && jsonData?.investor_profile.length
                    ? jsonData?.investor_profile
                    : [{ type: '', profile_url: '' }]
        };
        if (!isStartup) {
            obj.investor_type = investors;
            const investmentsType =
                jsonData?.investments_type && jsonData?.investments_type.length
                    ? jsonData?.investments_type.map((r) => ({
                          title: r?.name,
                          value: r?.id
                      }))
                    : [];
            obj.investments_type = investmentsType;
        } else {
            obj.last_funding_stage = investors;
        }

        setInvestmentAssets(investment_class_assets);

        setBusinessModel(business_model_list);
        setSectors(preferred_sector_list);
        setSdgGoals(sdg_sectors_list);
        setCurrentFundingStageId(preferred_stage_list);
        setHqGeo(investment_geos_list);
        // setFirmType(investors);
    };
    useEffect(() => {
        const arr = [];
        arr.push(GetStartupTypeMaster());
        arr.push(GetBusinessModelMaster());
        arr.push(GetSectors());
        arr.push(GetStages());
        arr.push(GetSdgGoals());
        arr.push(GetRegions());
        arr.push(GetFirmsClassify());

        if (!isStartup) {
            arr.push(GetInvestmentsTypeMaster());
        }
        arr.push(GetEcosystemTypeMaster());
        axios.all(arr).then((resp) => {
            const regions = resp?.[5]?.response?.regions;

            setStageList(resp?.[3]?.response?.stages?.STAGE_LIST);
            setFirmTypeList(resp?.[6]?.response);
            setFirmTypeList(resp?.[6]?.response);
            setEcoSystemTypeList(resp?.[8]?.response?.ecosystemTypes);

            let sectorsData = [];
            resp?.[2]?.response?.sectors?.forEach((d) => {
                sectorsData.push({ title: d?.title, value: d?.id });
            });
            setSectorsList(sectorsData);
            let regionsData = [];
            regions.forEach((d) => {
                regionsData.push({
                    title: d?.title,
                    value: d?.id,
                    countries: d?.countries
                });
            });
            regionsData = regionsData.sort((a, b) => a?.value - b?.value);
            setRegionsList(regionsData);
            setInvestmentsTypeList(resp?.[7]?.response?.investmentsType);

            setSingleFirm({});
        });
    }, []);

    useEffect(() => {
        const hasFilters = Object.values(filters).some((value) => value !== '');
        if (hasFilters) {
            InitializeState(
                setEcoType,
                ecoSystemTypeList,
                decodedFilters?.ecosystem_type_ids
            );

            InitializeState(
                setInvestmentsType,
                investmentsTypeList,
                decodedFilters?.investments_type_ids
            );

            InitializeState(
                setFirmType,
                FIRM_TYPE_LIST,
                decodedFilters?.investor_type_ids
            );

            InitializeState(
                setInvestmentAssets,
                startupType,
                decodedFilters?.investment_class_ids
            );

            InitializeState(
                setBusinessModel,
                businessModel,
                decodedFilters?.business_model_ids
            );

            InitializeState(
                setSectors,
                sectorsList,
                decodedFilters?.preferred_sector_ids
            );
            InitializeState(
                setCurrentFundingStageId,
                CURRENTLY_RAISING_LIST,
                decodedFilters?.preferred_stage_ids
            );
            InitializeState(
                setSdgGoals,
                sdgGoalsList,
                decodedFilters?.sdg_sectors_ids
            );
            InitializeState(
                setRegions,
                regionsList,
                decodedFilters?.regions_ids,
                'regions'
            );
        }
    }, [anchorEl]);

    const InitializeState = (setter, list, filterList, type = null) => {
        let filteredItems = [];
        list?.forEach((item) => {
            if (filterList?.includes(item?.value)) {
                return filteredItems.push(item);
            }
        });
        setter(filteredItems);

        if (type === 'regions') {
            let geos = filteredItems
                .map((d) => d.countries)
                .flat()
                .map((d) => ({
                    title: d?.label,
                    value: d?.country_id
                }))
                .filter((d) => d.value && d.title);
            const distinct = [];
            for (let i = 0; i < geos.length; i++) {
                if (
                    !distinct.map((d: any) => d.value).includes(geos[i].value)
                ) {
                    distinct.push(geos[i]);
                }
            }
            setHqGeo(distinct);
        }
    };

    const handleHQGeographiesChange = (key, value) => {
        setHqGeo(value);

        value = value.map((d) => d?.value);

        delayedQuery(value, key);
    };

    const handleInvestmentChange = (key, value) => {
        setInvestmentAssets(value);

        value = value.map((d) => d?.value);

        delayedQuery(value, key);
    };
    const handleSdgGoalsChange = (key, value) => {
        setSdgGoals(value);

        value = value.map((d) => d?.value);

        delayedQuery(value, key);
    };
    const handleRegionsChange = (key, values) => {
        setRegions(values);
        let geos = values
            .map((d) => d.countries)
            .flat()
            .map((d) => ({
                title: d?.label,
                value: d?.country_id
            }))
            .filter((d) => d.value && d.title);
        const distinct = [];
        for (let i = 0; i < geos.length; i++) {
            if (!distinct.map((d: any) => d.value).includes(geos[i].value)) {
                distinct.push(geos[i]);
            }
        }
        setHqGeo(distinct);

        values = values.map((d) => d?.value);

        delayedQuery(values, key);
    };

    const handleBusinessModelChange = (key, value) => {
        setBusinessModel(value);

        value = value.map((d) => d?.value);

        delayedQuery(value, key);
    };

    const handleSectorsChange = (key, value) => {
        setSectors(value);

        value = value.map((d) => d?.value);

        delayedQuery(value, key);
    };

    const handleCurrentFundingStageChange = (key, value) => {
        setCurrentFundingStageId(value);

        value = value.map((d) => d?.value);

        delayedQuery(value, key);
    };

    const handleInvestmentsTypeChange = (key, value) => {
        setInvestmentsType(value);

        value = value.map((d) => d?.value);

        delayedQuery(value, key);
    };
    const handleinvestorTypeChange = (key, value) => {
        setFirmType(value);
        setFirmTypeSize(value.length as number);

        value = value.map((d) => d?.value);

        delayedQuery(value, key);
    };

    return (
        <div className={`${style.container}`}>
            <ToggleButton
                classes={{ selected: 'bgColorWhite' }}
                style={{ borderRadius: '10px' }}
                onClick={handleOpenPopup}
                className="ml-3"
            >
                <Settings className="colorPrimary" />
            </ToggleButton>
            {open ? (
                <Popover
                    id={popUpId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {open ? (
                        <div className={`p-3`}>
                            {!isStartup ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="investor_type_ids"
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="investor_type_ids"
                                                        value={firm_type}
                                                        label={`${
                                                            isStartup
                                                                ? 'What was your last funding stage'
                                                                : 'How do you classify your firm (up to 3)'
                                                        }? *`}
                                                        inputAutoComplete="off"
                                                        size="small"
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleinvestorTypeChange(
                                                                'investor_type_ids',
                                                                data
                                                            );
                                                        }}
                                                        options={
                                                            firmTypeSize <
                                                            (isStartup
                                                                ? MAX_STARTUP_TYPE_TAGS_SIZE
                                                                : MAX_FIRM_TYPE_TAGS_SIZE)
                                                                ? isStartup
                                                                    ? STAGE_LIST
                                                                    : FIRM_TYPE_LIST
                                                                : []
                                                        }
                                                        inputPlaceholder="Search type of firm"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            inputRef={register}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="investments_type_ids"
                                        render={({ onChange, value }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="investments_type_ids"
                                                    value={investmentsType}
                                                    label="Investing in *"
                                                    inputAutoComplete="off"
                                                    // disableCloseOnSelect={!smallScreen}
                                                    size="small"
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleInvestmentsTypeChange(
                                                            'investments_type_ids',
                                                            data
                                                        );
                                                    }}
                                                    options={
                                                        investmentsTypeList
                                                    }
                                                    inputPlaceholder="Search investment type"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        inputRef={register}
                                    />
                                </Grid>
                            </Grid>
                            <div className="my-5">
                                <Controller
                                    name={'ecosystem_type_ids'}
                                    render={({ onChange }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                value={eco_system_type}
                                                label="EcoSystem Type"
                                                inputAutoComplete="off"
                                                disableCloseOnSelect={
                                                    !smallScreen
                                                }
                                                size="small"
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleFirmTypeChange(
                                                        'ecosystem_type_ids',
                                                        data
                                                    );
                                                }}
                                                options={ecoSystemTypeList}
                                                inputPlaceholder="Search investment type"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    inputRef={register}
                                />
                            </div>
                            <div className="mt-5">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="investment_class_ids"
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="investment_class_ids"
                                                        value={investment_class}
                                                        label={`${
                                                            isStartup
                                                                ? 'Startup type'
                                                                : 'Preferred Asset Classes'
                                                        } *`}
                                                        inputAutoComplete="off"
                                                        size="small"
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleInvestmentChange(
                                                                'investment_class_ids',
                                                                data
                                                            );
                                                        }}
                                                        options={startupType}
                                                        inputPlaceholder="Search investment assets"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="business_model_ids"
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="business_model_ids"
                                                        value={business_model}
                                                        label={`${
                                                            isStartup
                                                                ? 'Business Model'
                                                                : 'Preferred Business Models'
                                                        } *`}
                                                        inputAutoComplete="off"
                                                        size="small"
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleBusinessModelChange(
                                                                'business_model_ids',
                                                                data
                                                            );
                                                        }}
                                                        options={businessModel}
                                                        inputPlaceholder="Search business model"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="preferred_sector_ids"
                                            render={({ onChange }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="preferred_sector_ids"
                                                        value={preferred_sector}
                                                        label={`${
                                                            isStartup
                                                                ? 'Startup Sector'
                                                                : 'Preferred Sectors'
                                                        } *`}
                                                        inputAutoComplete="off"
                                                        size="small"
                                                        options={sectorsList}
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleSectorsChange(
                                                                'preferred_sector_ids',
                                                                data
                                                            );
                                                        }}
                                                        inputPlaceholder="Search sectors"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="preferred_stage"
                                            render={({ onChange }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="preferred_stage_ids"
                                                        value={preferred_stage}
                                                        label={
                                                            <div className="d-flex position-relative">
                                                                <span>
                                                                    {isStartup
                                                                        ? 'Currently fundraising stage'
                                                                        : 'Preferred Stages  *'}
                                                                </span>
                                                            </div>
                                                        }
                                                        inputAutoComplete="off"
                                                        size="small"
                                                        disabled={
                                                            is_not_fundraising
                                                        }
                                                        options={
                                                            CURRENTLY_RAISING_LIST
                                                        }
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleCurrentFundingStageChange(
                                                                'preferred_stage_ids',
                                                                data
                                                            );
                                                        }}
                                                        inputPlaceholder="Search stages"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="sdg_sectors"
                                            render={({ onChange }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="sdg_sectors"
                                                        value={sdgGoals}
                                                        label={
                                                            <span>
                                                                SDG Goals{' '}
                                                                <InfoIconComponent />
                                                            </span>
                                                        }
                                                        size="small"
                                                        inputAutoComplete="off"
                                                        // disableCloseOnSelect={
                                                        //     !smallScreen
                                                        // }
                                                        // paperClass={
                                                        //     style.autocompleteBackground
                                                        // }
                                                        options={sdgGoalsList}
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleSdgGoalsChange(
                                                                'sdg_sectors_ids',
                                                                data
                                                            );
                                                        }}
                                                        inputPlaceholder="Search sdg goals"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        <Controller
                                            name="regions_ids"
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id=""
                                                        value={regions}
                                                        label={
                                                            isStartup
                                                                ? 'Active Regions *'
                                                                : 'Preferred Regions *'
                                                        }
                                                        size="small"
                                                        inputAutoComplete="off"
                                                        // paperClass={
                                                        //     style.autocompleteBackground
                                                        // }
                                                        // disableCloseOnSelect={
                                                        //     !smallScreen
                                                        // }
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleRegionsChange(
                                                                'regions_ids',
                                                                data
                                                            );
                                                        }}
                                                        options={regionsList}
                                                        inputPlaceholder="Search regions"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="investment_geos_ids"
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="investment_geos_ids"
                                                        value={investment_geos}
                                                        label={`${
                                                            isStartup
                                                                ? 'Startup active geographies'
                                                                : 'Preferred Geographies'
                                                        } *`}
                                                        size="small"
                                                        inputAutoComplete="off"
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleHQGeographiesChange(
                                                                'investment_geos_ids',
                                                                data
                                                            );
                                                        }}
                                                        options={countriesList}
                                                        inputPlaceholder="Search Geographies to invest in"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <Button
                                variant="outlined"
                                color="inherit"
                                size="medium"
                                className="mt-2"
                                onClick={clearAllFilter}
                            >
                                <Restore className={style.clearIcon} />
                                Reset
                            </Button>
                        </div>
                    ) : null}
                </Popover>
            ) : null}
        </div>
    );
};

export default connect(null, {
    GetStartupTypeMaster,
    GetBusinessModelMaster,
    GetInvestmentsTypeMaster,
    GetSectors,
    GetStages,
    GetSdgGoals,
    GetRegions,
    GetOneFirm,
    GetFirmsList,
    CommonDispatcher,
    GetFirmsClassify,
    GetEcosystemTypeMaster
})(FirmsFilterModal);
