import {
    Button,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import useStateRef from 'react-usestateref';
import { UpdatePost } from '../Common';
import DragAndDrop from '../../../DragAndDrop';
import countriesArray from './../../../../utils/countries.json';
import sectorsArray from './../../../../utils/sectors.json';
import { RootState } from '../../../../redux/store';
import {
    CommonDispatcher,
    UploadImages
} from '../../../../redux/Common/action';
import { UPDATE_WIZARD_FORM_DATA } from '../../../../redux/Deals/actionTypes';
import RenderCheckboxComponent from '../../../InputComponents/CheckboxComponent';
import RenderSelectField from '../../../InputComponents/SelectField/RenderSelectField';
import RenderAutocompleteTextField from '../../../InputComponents/AutocompleteTextField';
import { SHOW_SNACKBAR } from '../../../../redux/Common/actionTypes';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import { isValidURL, onDropCallback } from '../../../../utils/common';
import { UpdateDeal } from '../../../../redux/Deals/actions';
import { useCookies } from 'react-cookie';
import style from './Style.module.scss';

const StepOneComponent = ({
    equity_id: post_id,
    CommonDispatcher,
    UpdateDeal,
    UploadImages
}: any) => {
    const state = useSelector((store: RootState) => store);
    const activeStep = state?.deals?.wizard?.currentStep || 0;
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const companyFoundedList = state?.commonAppData?.foundedList || [];

    const [errors, setError, errorRef] = useStateRef({}) as [any, any, any];
    const {
        control,
        reset,
        errors: formError,
        trigger,
        getValues,
        formState: { isValid }
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    const [company_name, setCompanyName] = useState('');
    const [website, setWebsite] = useState('');
    const [foundation_year, setCompanyFounded] = useState();
    const [sectors, setSectors] = useState([]);
    const [hq_geos, setHqGeo] = useState([]);
    const [hqGeoSize, setHqGeoSize] = useState<number>(0);
    const MAX_HQ_GEO_TAGS_SIZE = 2;
    const [operational_geos, setOperationalGeos] = useState([]);
    const [b2b, setB2B] = useState(false);
    const [b2c, setB2C] = useState(false);
    const [b2b2c, setB2B2C] = useState(false);
    const [technology, setTechnology] = useState(false);
    const [impact, setImpact] = useState(false);
    const [description, setDescription] = useState('');

    const [isLoading, setLoading] = useState(false);
    const steps = state?.deals?.wizard?.formSteps || [];
    const errorStep = state?.deals?.wizard?.errorStep || [];
    const deal_data = state?.deals?.deal_data || {};

    const [sectorsList, setSectorsList] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [images, setImages, imagesRef] = useStateRef([]);
    const [progress, setProgress, progressRef] = useStateRef(0);

    useEffect(() => {
        let obj: any = { ...deal_data };

        if (obj.hq_geos && obj.hq_geos.length) {
            obj.hq_geos = [
                ...countriesArray.filter((d) => obj.hq_geos.includes(d?.id))
            ].map((d) => ({
                title: d?.title,
                value: d?.id
            }));
            setHqGeo(obj.hq_geos);
        }
        if (obj.ops_geos && obj.ops_geos.length) {
            obj.ops_geos = [
                ...countriesArray.filter((d) => obj.ops_geos.includes(d?.id))
            ].map((d) => ({
                title: d?.title,
                value: d?.id
            }));
            setOperationalGeos(obj.ops_geos);
        }
        if (obj.sectors && obj.sectors.length) {
            obj.sectors = [
                ...sectorsArray.filter((d) => obj.sectors.includes(d?.id))
            ].map((d) => ({
                title: d?.title,
                value: d?.id
            }));
            setSectors(obj.sectors);
        }

        if (post_id) {
            obj?.company_logo &&
                setImages(
                    [obj?.company_logo].map((d, i) => ({ id: i + 1, src: d }))
                );
        } else {
            obj?.company_logo && setImages(obj?.images);
        }
        reset(obj);

        let sectorsData = [];

        sectorsArray.forEach((d) => {
            sectorsData.push({ title: d?.title, value: d?.id });
        });
        setSectorsList(sectorsData);
        let countriesData = [];
        countriesArray.forEach((d) => {
            countriesData.push({ title: d?.title, value: d?.id });
        });
        setCountriesList(countriesData);
    }, [deal_data]);

    useEffect(() => {
        if (activeStep > 0) setWizardError();
    }, [errorStep]);

    const handleNext = () => {
        next();
        onSave();
    };

    const next = () => {
        if (activeStep < steps?.length) {
            CommonDispatcher(UPDATE_WIZARD_FORM_DATA, {
                currentStep: activeStep + 1
            });
        }
    };

    const setWizardError = () => {
        let errors = { ...errorStep, [activeStep]: !isValid };
        CommonDispatcher(UPDATE_WIZARD_FORM_DATA, { errorStep: errors });
    };

    const handleSectorsChange = (value) => {
        setSectors(value);
    };

    const handleHQGeographiesChange = (value) => {
        setHqGeo(value);
        setHqGeoSize(value.length as number);
    };

    const handleOperationalGeosChange = (value) => {
        setOperationalGeos(value);
    };

    const getData = (data) => {
        return data && data.length ? data.map((d) => d?.value) : [];
    };

    const setBusinessModal = (value, type) => {
        switch (type) {
            case 'b2b':
                setB2B(value);
                break;
            case 'b2c':
                setB2C(value);
                break;
            case 'b2b2c':
                setB2B2C(value);
                break;
        }
    };

    const setStartupType = (value, type) => {
        switch (type) {
            case 'technology':
                setTechnology(value);
                break;
            case 'impact':
                setImpact(value);
                break;
        }
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getFormValues(getValues());
        data.equity_id = post_id || deal_data?.equity_id;
        if (data?.target_raise) {
            data.target_raise = data?.target_raise.split(',').join('');
        }
        if (data?.pre_money) {
            data.pre_money = data?.pre_money.split(',').join('');
        }
        if (data?.soft_circled) {
            data.soft_circled = data?.soft_circled.split(',').join('');
        }
        if (data?.last12_month_revenue) {
            data.last12_month_revenue = data?.last12_month_revenue
                .split(',')
                .join('');
        }
        if (data?.next12_month_revenue) {
            data.next12_month_revenue = data?.next12_month_revenue
                .split(',')
                .join('');
        }
        if (data?.equity_id) {
            setLoading(true);
            UpdatePost(
                UpdateDeal,
                CommonDispatcher,
                data,
                UploadImages,
                setLoading,
                next,
                token
            );
        }
    };

    const getFormValues = (obj) => {
        obj.hq_geos =
            obj?.hq_geos && obj?.hq_geos?.length ? getData(obj?.hq_geos) : null;
        obj.ops_geos =
            obj?.ops_geos && obj?.ops_geos?.length
                ? getData(obj?.ops_geos)
                : null;
        obj.sectors =
            obj?.sectors && obj?.sectors?.length ? getData(obj?.sectors) : null;
        if (images && images?.length) {
            obj.images = images;
            obj.company_logo = images[0]?.src;
        }
        let data = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            setError({ ...errorRef.current, [key]: !value });
            data[key] = value;
        });
        return data;
    };

    const renderCheckboxError = (hasError, errorMessage) => {
        if (hasError) {
            return (
                <FormHelperText error={true} className={hasError ? 'mb-1' : ''}>
                    {errorMessage}
                </FormHelperText>
            );
        }
    };

    const handleDeleteImage = (id) => {
        if (id) {
            setImages(images.filter((d) => d.id !== id));
        }
    };

    const onDrop = useCallback(async (files) => {
        if (!(imagesRef?.current && imagesRef?.current?.length)) {
            onDropCallback(
                files,
                imagesRef,
                setProgress,
                progressRef,
                setIsActive,
                setImages
            );
        } else {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: 'Only 1 image is allowed to upload'
            });
        }
    }, []);

    const type = formError?.website?.type;
    const required = type === 'required';
    const validate = type === 'validate';
    const websiteError = required
        ? 'Website is mandatory.'
        : validate
        ? 'Invalid website url.'
        : '';

    return (
        <Container maxWidth="sm">
            <Grid container spacing={3} className="mb-5">
                <Grid item xs={12} md={10} sm={10} className="m-auto pb-5">
                    <div className="">
                        <form onChange={() => trigger()}>
                            <div data-aos="fade-left" data-aos-duration="200">
                                <Grid container spacing={3} className="mb-5">
                                    <Grid item xs={12} className="py-1">
                                        <Controller
                                            name="company_logo"
                                            as={
                                                <DragAndDrop
                                                    onDrop={onDrop}
                                                    accept={
                                                        'image/png, image/jpg, image/jpeg'
                                                    }
                                                    images={images}
                                                    isLoading={isActive}
                                                    deleteImage={
                                                        handleDeleteImage
                                                    }
                                                    maxFiles={1}
                                                    multiple={false}
                                                    label="Upload logo (1 image)"
                                                />
                                            }
                                            control={control}
                                            defaultValue={''}
                                            rules={{
                                                required: false
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="company_name"
                                            as={
                                                <RenderTextField
                                                    id="company_name"
                                                    value={company_name}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="Company name *"
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.company_name
                                                    ? 'mb-1'
                                                    : ''
                                            }
                                        >
                                            {errors?.company_name &&
                                                'Company name is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="py-1">
                                        <Controller
                                            name="website"
                                            as={
                                                <RenderTextField
                                                    id="website"
                                                    value={website}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="Website *"
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true,
                                                validate: (link) => {
                                                    return isValidURL(link);
                                                }
                                            }}
                                        />
                                        <FormHelperText
                                            error={required || validate}
                                            className={
                                                required || validate
                                                    ? 'mb-1'
                                                    : ''
                                            }
                                        >
                                            {websiteError}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        <List
                                            subheader={
                                                <ListSubheader
                                                    disableSticky={true}
                                                    className="px-0"
                                                >
                                                    Startup Type *
                                                </ListSubheader>
                                            }
                                            className="p-0 mt-0"
                                        >
                                            <ListItem className="px-0 pt-0">
                                                <ListItemText primary="Technology" />
                                                <ListItemSecondaryAction>
                                                    <Controller
                                                        name={`technology`}
                                                        render={({
                                                            onChange,
                                                            value
                                                        }) => {
                                                            return (
                                                                <RenderCheckboxComponent
                                                                    id={`technology`}
                                                                    name={`technology`}
                                                                    checked={
                                                                        value
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                                ?.target
                                                                                ?.checked
                                                                        );
                                                                        setStartupType(
                                                                            e
                                                                                ?.target
                                                                                ?.checked,
                                                                            'technology'
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            );
                                                        }}
                                                        control={control}
                                                        defaultValue={false}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem className="px-0 pt-0">
                                                <ListItemText primary="Impact" />
                                                <ListItemSecondaryAction>
                                                    <Controller
                                                        name={`impact`}
                                                        render={({
                                                            onChange,
                                                            value
                                                        }) => {
                                                            return (
                                                                <RenderCheckboxComponent
                                                                    id={`impact`}
                                                                    name={`impact`}
                                                                    checked={
                                                                        value
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                                ?.target
                                                                                ?.checked
                                                                        );
                                                                        setStartupType(
                                                                            e
                                                                                ?.target
                                                                                ?.checked,
                                                                            'impact'
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            );
                                                        }}
                                                        control={control}
                                                        defaultValue={false}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                        {renderCheckboxError(
                                            errors?.technology &&
                                                errors?.impact,
                                            'Startup Type is mandatory'
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        <Controller
                                            name="foundation_year"
                                            as={
                                                <RenderSelectField
                                                    id="foundation_year"
                                                    label="Company founded *"
                                                    value={foundation_year}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-company-founded"
                                                    dropdownListHeight={250}
                                                    dataArray={
                                                        companyFoundedList
                                                    }
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.foundation_year
                                                    ? 'mb-1'
                                                    : ''
                                            }
                                        >
                                            {errors?.foundation_year &&
                                                'Foundation year is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        <Controller
                                            name="sectors"
                                            render={({ onChange }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="sectors"
                                                        value={sectors}
                                                        label="Sectors *"
                                                        inputAutoComplete="off"
                                                        paperClass={
                                                            style.autocompleteBackground
                                                        }
                                                        options={sectorsList}
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleSectorsChange(
                                                                data
                                                            );
                                                        }}
                                                        inputPlaceholder="Search sectors"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.sectors ? 'mb-1' : ''
                                            }
                                        >
                                            {errors?.sectors &&
                                                'Sectors are mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        <Controller
                                            name="hq_geos"
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="hq_geos"
                                                        value={hq_geos}
                                                        label="HQ Geography (up to 2 countries)*"
                                                        paperClass={
                                                            style.autocompleteBackground
                                                        }
                                                        inputAutoComplete="off"
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleHQGeographiesChange(
                                                                data
                                                            );
                                                        }}
                                                        options={
                                                            hqGeoSize <
                                                            MAX_HQ_GEO_TAGS_SIZE
                                                                ? countriesList
                                                                : []
                                                        }
                                                        inputPlaceholder="Search HQ Geography"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.hq_geos ? 'mb-1' : ''
                                            }
                                        >
                                            {errors?.hq_geos &&
                                                'HQ Geography is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        <Controller
                                            name="ops_geos"
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id=""
                                                        value={operational_geos}
                                                        label="Operational Geographies *"
                                                        inputAutoComplete="off"
                                                        paperClass={
                                                            style.autocompleteBackground
                                                        }
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                            handleOperationalGeosChange(
                                                                data
                                                            );
                                                        }}
                                                        options={countriesList}
                                                        inputPlaceholder="Search operational geographies"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.ops_geos ? 'mb-1' : ''
                                            }
                                        >
                                            {errors?.ops_geos &&
                                                'Operational geos is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        <List
                                            subheader={
                                                <ListSubheader
                                                    disableSticky={true}
                                                    className="px-0"
                                                >
                                                    Business Modal *
                                                </ListSubheader>
                                            }
                                            className="p-0 mt-0"
                                        >
                                            <ListItem className="px-0 pt-0">
                                                <ListItemText primary="B2B" />
                                                <ListItemSecondaryAction>
                                                    <Controller
                                                        name={`b2b`}
                                                        render={({
                                                            onChange,
                                                            value
                                                        }) => {
                                                            return (
                                                                <RenderCheckboxComponent
                                                                    id={`b2b`}
                                                                    name={`b2b`}
                                                                    checked={
                                                                        value
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                                ?.target
                                                                                ?.checked
                                                                        );
                                                                        setBusinessModal(
                                                                            e
                                                                                ?.target
                                                                                ?.checked,
                                                                            'b2b'
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            );
                                                        }}
                                                        control={control}
                                                        defaultValue={false}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>

                                            <ListItem className="px-0 pt-0">
                                                <ListItemText primary="B2C" />
                                                <ListItemSecondaryAction>
                                                    <Controller
                                                        name={`b2c`}
                                                        render={({
                                                            onChange,
                                                            value
                                                        }) => {
                                                            return (
                                                                <RenderCheckboxComponent
                                                                    id={`b2c`}
                                                                    name={`b2c`}
                                                                    checked={
                                                                        value
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                                ?.target
                                                                                ?.checked
                                                                        );
                                                                        setBusinessModal(
                                                                            e
                                                                                ?.target
                                                                                ?.checked,
                                                                            'b2c'
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            );
                                                        }}
                                                        control={control}
                                                        defaultValue={false}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>

                                            <ListItem className="px-0 pt-0">
                                                <ListItemText primary="B2B2C" />
                                                <ListItemSecondaryAction>
                                                    <Controller
                                                        name={`b2b2c`}
                                                        render={({
                                                            onChange,
                                                            value
                                                        }) => {
                                                            return (
                                                                <RenderCheckboxComponent
                                                                    id={`b2b2c`}
                                                                    name={`b2b2c`}
                                                                    checked={
                                                                        value
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                                ?.target
                                                                                ?.checked
                                                                        );
                                                                        setBusinessModal(
                                                                            e
                                                                                ?.target
                                                                                ?.checked,
                                                                            'b2b2c'
                                                                        );
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            );
                                                        }}
                                                        control={control}
                                                        defaultValue={false}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                        {renderCheckboxError(
                                            errors?.b2b &&
                                                errors?.b2c &&
                                                errors?.b2b2c,
                                            'Business Modal is mandatory'
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        <Controller
                                            name="description"
                                            as={
                                                <RenderTextField
                                                    id="description"
                                                    minRows={5}
                                                    multiline={true}
                                                    value={description}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    label="Description *"
                                                />
                                            }
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.description
                                                    ? 'mb-1'
                                                    : ''
                                            }
                                        >
                                            {errors?.description &&
                                                'Description is mandatory.'}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="mt-5 d-flex align-items-center justify-content-end">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={onSave}
                                    disabled={isLoading || required || validate}
                                    className={`fs-16 text-inherit ${
                                        !(required || validate)
                                            ? 'btn-submit-style'
                                            : ''
                                    }`}
                                >
                                    Next{' '}
                                    {isLoading ? (
                                        <CircularProgress
                                            size="1.3rem"
                                            className="circular-progress"
                                        />
                                    ) : null}
                                </Button>
                            </div>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default connect(null, { CommonDispatcher, UploadImages, UpdateDeal })(
    StepOneComponent
);
