import {
    Button,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Add, CloudUpload, Delete } from '@material-ui/icons';
import useStateRef from 'react-usestateref';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { CommonDispatcher } from '../../../../redux/Common/action';
import {
    SET_FUND_EQUITY_DEAL_DATA,
    SET_FUND_EQUITY_DEAL_FORM_ERROR,
    UPDATE_FUNDS_WIZARD_FORM_DATA
} from '../../../../redux/FundDeals/actionTypes';
import { validateThirdStep } from '../Common/validate';
import {
    DOCUMENT_MIME_TYPES,
    MAX_DOC_UPLOAD_SIZE
} from '../../../../utils/constants';
import { SHOW_SNACKBAR } from '../../../../redux/Common/actionTypes';
import { OPEN_ALERT_MODAL } from '../../../../redux/ModalReducer/actionTypes';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import RenderCreatableAutocomplete from '../../../InputComponents/CreatableAutocomplete';
import { UpdateDeal } from '../../../../redux/FundDeals/actions';
import { UpdatePost } from '../Common';
import { UploadImages, UploadDocument } from '../../../../redux/Common/action';
import { getQuery } from '../../../../utils/common';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const linksTypeList = [
    { title: 'Investment Intro', value: 'one_pager' },
    { title: 'Investment Deck', value: 'investment_deck' },
    { title: 'PPM', value: 'PPM Model' },
    { title: 'Subscription Agreement', value: 'Data Room' },
    { title: 'Advisory Agreement', value: 'advisory_agreement' },
    { title: 'Track Record', value: 'track_record' },
    { title: 'Competition slide', value: 'competition_slide' }
];

const StepFiveComponent = ({
    CommonDispatcher,
    UploadDocument,
    UpdateDeal,
    UploadImages
}: any) => {
    const history = useHistory();
    const query = getQuery(history);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const post_id = query.get('post_id');
    const state = useSelector((store: RootState) => store);
    const [isLoading, setLoading] = useState(false);
    const [selectedDocumentFileName, setSelectedDocumentFileName] = useState(
        {}
    );
    const activeStep = state?.FundDeals?.wizard?.currentStep || 0;
    const steps = state?.FundDeals?.wizard?.formSteps || [];
    const errorStep = state?.FundDeals?.wizard?.errorStep || [];
    const deal_data = state?.FundDeals?.deal_data || {};
    const deal_data_error = state?.FundDeals?.deal_data_error || {};
    const [errors, setError, errorRef] = useStateRef({
        ...deal_data_error[activeStep]
    }) as [any, any, any];

    const { control, reset, setValue, getValues, register } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    const {
        fields: documentFields,
        append: documentAppend,
        remove: removeDocumentFields
    } = useFieldArray({
        control,
        name: 'documents'
    });
    const fieldsToValidate = ['documents'];

    useEffect(() => {
        let obj: any = {};
        Object.entries(deal_data).forEach(([k]) => {
            if (fieldsToValidate.includes(k)) {
                obj[k] = deal_data[k];
            }
        });
        if (deal_data?.documents && deal_data?.documents?.length) {
            obj.documents = deal_data?.documents.filter(
                (d) => Boolean(d?.name) && Boolean(d?.doc_url)
            );
        }
        if (!obj?.documents || (obj?.documents && !obj?.documents.length)) {
            obj.documents = [
                {
                    name: '',
                    doc_url: '',
                    doc_name: ''
                }
            ];
        }
        reset(obj);
    }, [deal_data]);

    useEffect(() => {
        if (activeStep > 4) setWizardError();
    }, [errorStep]);

    const setWizardError = () => {
        CommonDispatcher(SET_FUND_EQUITY_DEAL_FORM_ERROR, {
            [activeStep]: errorRef?.current
        });
        const errItemsCount = Object.keys(errorRef?.current).filter(
            (d) => errorRef?.current[d]
        )?.length;
        let errors = { ...errorStep, [activeStep]: errItemsCount };
        CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
            errorStep: errors
        });
    };

    const next = () => {
        if (activeStep < steps?.length) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep + 1
            });
        }
    };

    const handleBack = (flag = false) => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep - 1
            });
        }
        let data: any = getFormValues(getValues());
        const err = validateThirdStep(data);
        setError(err);
        if (data?.documents && data?.documents.length) {
            data.documents = data?.documents.filter(
                (d) => Boolean(d?.name) && Boolean(d?.doc_url)
            );
        } else data.documents = [];
        CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, data);
        flag && setWizardError();
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getFormValues(getValues());
        data.equity_id = post_id || deal_data?.equity_id;

        if (data?.documents && data?.documents?.length) {
            data.documents = data?.documents
                .map((d) => {
                    d.name =
                        typeof d?.name === 'string' ? d?.name : d?.name?.title;
                    return d;
                })
                .filter((d) => Boolean(d?.name) && Boolean(d?.doc_url));
        } else data.documents = [];
        const err = validateThirdStep(data);
        setError(err);
        CommonDispatcher(SET_FUND_EQUITY_DEAL_FORM_ERROR, {
            [activeStep]: errorRef?.current
        });
        if (data?.equity_id) {
            setLoading(true);
            UpdatePost(
                (data) => UpdateDeal(token, data),
                CommonDispatcher,
                data,
                UploadImages,
                setLoading,
                next,
                setWizardError
            );
        }
    };

    const getFormValues = (obj) => {
        let data = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            if (key === 'documents') {
                if (value && Array.isArray(value) && value?.length) {
                    data[key] = value
                        .map((d) => {
                            d.name =
                                typeof d?.name === 'string'
                                    ? d?.name
                                    : d?.name?.title;
                            return d;
                        })
                        .filter((d) => Boolean(d?.name) || Boolean(d?.doc_url));
                } else data[key] = [];
            } else {
                data[key] = value;
            }
        });
        return data;
    };

    const validateDocLink = (index) => {
        const inValidDocuments = errors?.inValidDocuments;
        if (inValidDocuments && inValidDocuments.length) {
            const invalidUrl = inValidDocuments?.[index]?.doc_url
                ? 'Doc link is mandatory'
                : inValidDocuments?.[index]?.inValidDocUrl
                ? 'Invalid doc link'
                : null;
            return (
                <FormHelperText
                    error={true}
                    className={invalidUrl ? 'visible' : 'invisible'}
                >
                    {invalidUrl}
                </FormHelperText>
            );
        }
    };

    const uploadDoc = (index, documentName, { target }) => {
        if (documentName) {
            if (target?.files && target?.files?.length) {
                const file = target?.files?.[0];
                const size = file?.size / 1024 / 1024; //converting bytes to MB. Here, file?.size is in bytes
                const type = file?.type;
                if (size > MAX_DOC_UPLOAD_SIZE) {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: `The file size can not exceed ${MAX_DOC_UPLOAD_SIZE}MB`,
                        hideAlert: true,
                        error: true
                    });
                    return;
                } else if (!DOCUMENT_MIME_TYPES.includes(type)) {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Unsupported type document uploaded',
                        hideAlert: true,
                        error: true
                    });
                } else {
                    const fileReader = new FileReader();
                    const name = file?.name;
                    setSelectedDocumentFileName({
                        ...selectedDocumentFileName,
                        [index]: {
                            ...selectedDocumentFileName?.[index],
                            isLoading: true,
                            is_uploaded: true,
                            name
                        }
                    });
                    fileReader.readAsDataURL(file);
                    fileReader.onload = (e) => {
                        let data: any = getFormValues(getValues());
                        const equity_id = post_id || deal_data?.equity_id;
                        let formData = new FormData();
                        formData.append('type', 'FUND');
                        formData.append('equity_id', equity_id);
                        formData.append(
                            'name',
                            documentName || data?.documents?.[index]?.name
                        );
                        formData.append('files', file as any, name);
                        UploadDocument(formData).then((r) => {
                            if (r?.type === 'success') {
                                const file_url = r?.response?.file_url;
                                if (file_url) {
                                    setValue(
                                        `documents[${index}].doc_url`,
                                        file_url
                                    );
                                    setSelectedDocumentFileName({
                                        ...selectedDocumentFileName,
                                        [index]: {
                                            ...selectedDocumentFileName?.[
                                                index
                                            ],
                                            isLoading: false,
                                            file_url,
                                            is_uploaded: true,
                                            name
                                        }
                                    });
                                    let documents =
                                        data?.documents &&
                                        data?.documents.length
                                            ? data?.documents.map((d, idx) => {
                                                  if (idx === index) {
                                                      d.is_uploaded = true;
                                                      d.doc_url = file_url;
                                                      d.doc_name = name;
                                                      d.name =
                                                          documentName ||
                                                          data?.documents?.[
                                                              index
                                                          ]?.name;
                                                  }
                                                  return d;
                                              })
                                            : [
                                                  {
                                                      is_uploaded: true,
                                                      doc_url: file_url,
                                                      doc_name: name,
                                                      name:
                                                          documentName ||
                                                          data?.documents?.[
                                                              index
                                                          ]?.name
                                                  }
                                              ];
                                    CommonDispatcher(
                                        SET_FUND_EQUITY_DEAL_DATA,
                                        { documents }
                                    );
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message:
                                            'Document uploaded successfully'
                                    });
                                } else {
                                    setSelectedDocumentFileName({
                                        ...selectedDocumentFileName,
                                        [index]: {
                                            ...selectedDocumentFileName?.[
                                                index
                                            ],
                                            isLoading: false,
                                            is_uploaded: false,
                                            name
                                        }
                                    });
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: 'Document URL not available',
                                        hideAlert: true,
                                        error: true
                                    });
                                }
                            } else if (
                                r?.type === 'error' &&
                                r?.response?.data?.error
                            ) {
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message:
                                        r?.response?.data?.error ||
                                        'Failed to upload the doc',
                                    hideAlert: true,
                                    error: true
                                });
                                setSelectedDocumentFileName({
                                    ...selectedDocumentFileName,
                                    [index]: {
                                        ...selectedDocumentFileName?.[index],
                                        isLoading: false,
                                        is_uploaded: true,
                                        name
                                    }
                                });
                            } else {
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: 'Failed to upload the doc',
                                    hideAlert: true,
                                    error: true
                                });
                                setSelectedDocumentFileName({
                                    ...selectedDocumentFileName,
                                    [index]: {
                                        ...selectedDocumentFileName?.[index],
                                        isLoading: false,
                                        is_uploaded: true,
                                        name
                                    }
                                });
                            }
                        });
                    };
                }
            }
        } else {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: `Name of the attachment is missing`,
                hideAlert: true,
                error: true
            });
        }
    };

    const removeDocument = (index) => {
        removeDocumentFields(index);
        setSelectedDocumentFileName({
            ...selectedDocumentFileName,
            [index]: null
        });
        let documents: any = deal_data?.documents;
        documents.splice(index, 1);
        CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, { documents });
        CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
    };

    return (
        <Container maxWidth="md">
            <Grid item xs={12} sm={12} md={10} className="m-auto pb-5">
                <div className="">
                    <form>
                        <div data-aos="fade-left" data-aos-duration="200">
                            <Grid container spacing={2} className="mb-3">
                                <Grid
                                    item
                                    xs={12}
                                    className="py-1 d-flex align-items-center"
                                >
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        className="colorPrimary font-weight-bold fs-16 mr-3"
                                    >
                                        Documents * :
                                    </Typography>

                                    <FormHelperText error={true}>
                                        {errors?.documents &&
                                            !errors?.documents.length &&
                                            'At least 1 document is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                className="my-1 bgColorLightGray"
                            >
                                <Grid item xs={12} className="py-1">
                                    {documentFields.map((item, index) => {
                                        const nameError =
                                            errors?.inValidDocuments?.[index]
                                                ?.name;
                                        return (
                                            <div
                                                key={item?.id}
                                                className="appCard p-3 mb-4"
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={4}>
                                                        <Controller
                                                            name={`documents[${index}].name`}
                                                            render={({
                                                                onChange,
                                                                value
                                                            }) => {
                                                                return (
                                                                    <div className="w-100">
                                                                        <RenderCreatableAutocomplete
                                                                            id={`documents[${index}].name`}
                                                                            value={
                                                                                value ||
                                                                                []
                                                                            }
                                                                            label={`Attachment name ${
                                                                                index +
                                                                                1
                                                                            }`}
                                                                            inputAutoComplete="off"
                                                                            options={
                                                                                linksTypeList
                                                                            }
                                                                            onBlur={(
                                                                                e: any
                                                                            ) => {
                                                                                const value =
                                                                                    e
                                                                                        ?.target
                                                                                        ?.value;
                                                                                onChange(
                                                                                    {
                                                                                        title: value,
                                                                                        value: value
                                                                                    }
                                                                                );
                                                                            }}
                                                                            onChange={(
                                                                                e,
                                                                                data
                                                                            ) => {
                                                                                onChange(
                                                                                    data
                                                                                );
                                                                            }}
                                                                            inputPlaceholder="Search or Add new Attachment Info"
                                                                        />
                                                                    </div>
                                                                );
                                                            }}
                                                            control={control}
                                                            defaultValue={
                                                                item?.name ||
                                                                null
                                                            }
                                                        />
                                                        <FormHelperText
                                                            error={true}
                                                            className={
                                                                nameError
                                                                    ? 'mb-1'
                                                                    : ''
                                                            }
                                                        >
                                                            {nameError &&
                                                                'Attachment name is mandatory'}
                                                        </FormHelperText>
                                                    </Grid>
                                                    {selectedDocumentFileName?.[
                                                        index
                                                    ]?.is_uploaded ||
                                                    item?.is_uploaded ? (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={5}
                                                            className="d-flex flex-direction-column"
                                                        >
                                                            <label>
                                                                Attached
                                                                document
                                                            </label>
                                                            <label
                                                                className="font-weight-bold colorBlue h-100 line-clamp-2"
                                                                style={{
                                                                    wordBreak:
                                                                        'break-all',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center'
                                                                }}
                                                            >
                                                                <a
                                                                    href={
                                                                        selectedDocumentFileName?.[
                                                                            index
                                                                        ]
                                                                            ?.file_url ||
                                                                        item?.doc_url
                                                                    }
                                                                    className="text-decoration-none text-hover-underline"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {selectedDocumentFileName?.[
                                                                        index
                                                                    ]?.name ||
                                                                        item?.doc_name}
                                                                </a>
                                                            </label>
                                                        </Grid>
                                                    ) : (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={5}
                                                        >
                                                            <Controller
                                                                name={`documents[${index}].doc_url`}
                                                                as={
                                                                    <RenderTextField
                                                                        id={`documents[${index}].doc_url`}
                                                                        inputContainerClassName={
                                                                            'w-100'
                                                                        }
                                                                        autoComplete="off"
                                                                        placeholder="e.g. www.dropbox.com or Upload"
                                                                        label={`Enter Links (Intralinks/Dropbox)`}
                                                                    />
                                                                }
                                                                control={
                                                                    control
                                                                }
                                                                inputRef={
                                                                    register
                                                                }
                                                                defaultValue={
                                                                    item?.doc_url ||
                                                                    null
                                                                }
                                                            />
                                                            {validateDocLink(
                                                                index
                                                            )}
                                                        </Grid>
                                                    )}
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={3}
                                                        className="d-flex align-items-center justify-content-center flex-direction-column"
                                                    >
                                                        <input
                                                            accept={DOCUMENT_MIME_TYPES.toString()}
                                                            id={`file[${index}]`}
                                                            className="d-none"
                                                            onChange={(e) =>
                                                                uploadDoc(
                                                                    index,
                                                                    item?.name ||
                                                                        getValues()
                                                                            ?.documents?.[
                                                                            index
                                                                        ]?.name
                                                                            ?.title,
                                                                    e
                                                                )
                                                            }
                                                            type="file"
                                                        />
                                                        <div
                                                            className="d-flex align-items-center justify-content-center
                                                    border-bottom pb-1"
                                                        >
                                                            <span
                                                                className={`${
                                                                    errors?.documents &&
                                                                    errors
                                                                        ?.documents
                                                                        .length
                                                                        ? 'my-3'
                                                                        : 'mt-3'
                                                                } mt-xs-0`}
                                                            >
                                                                Or
                                                            </span>
                                                            <label
                                                                htmlFor={`file[${index}]`}
                                                                className={`d-flex align-items-center ml-2
                                                           ${
                                                               errors?.documents &&
                                                               errors?.documents
                                                                   .length
                                                                   ? 'my-3'
                                                                   : 'mt-3'
                                                           } mt-xs-0`}
                                                            >
                                                                <Button
                                                                    variant="text"
                                                                    component="span"
                                                                    className="float-right px-2"
                                                                    disabled={
                                                                        selectedDocumentFileName?.[
                                                                            index
                                                                        ]
                                                                            ?.isLoading
                                                                    }
                                                                    size="small"
                                                                >
                                                                    <CloudUpload className="mr-2" />{' '}
                                                                    Upload
                                                                    {selectedDocumentFileName?.[
                                                                        index
                                                                    ]
                                                                        ?.isLoading ? (
                                                                        <CircularProgress
                                                                            size="1.3rem"
                                                                            className="circular-progress ml-2"
                                                                        />
                                                                    ) : null}
                                                                </Button>
                                                            </label>
                                                        </div>
                                                        <span className="mt-1 fs-13">
                                                            Max 5MB limit
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            color="secondary"
                                                            variant="text"
                                                            type="button"
                                                            className="float-right px-2"
                                                            size="small"
                                                            onClick={() => {
                                                                if (
                                                                    selectedDocumentFileName?.[
                                                                        index
                                                                    ]
                                                                        ?.is_uploaded ||
                                                                    item?.is_uploaded
                                                                ) {
                                                                    CommonDispatcher(
                                                                        OPEN_ALERT_MODAL,
                                                                        {
                                                                            isOpen: true,
                                                                            title: 'Alert',
                                                                            description:
                                                                                'Are you sure you want to delete this document?',
                                                                            data: {
                                                                                disableBackdropClick:
                                                                                    true
                                                                            },
                                                                            submitTitle:
                                                                                'Yes, Delete',
                                                                            cancelTitle:
                                                                                'No',
                                                                            callBack:
                                                                                async () => {
                                                                                    removeDocument(
                                                                                        index
                                                                                    );
                                                                                }
                                                                        }
                                                                    );
                                                                } else {
                                                                    removeDocument(
                                                                        index
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Delete className="mr-2" />{' '}
                                                            Delete
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="mb-3">
                                <Grid item xs={12} className="mt-3">
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        type="button"
                                        size="small"
                                        disabled={documentFields?.length >= 10}
                                        className="float-right"
                                        onClick={() => {
                                            documentAppend({
                                                name: '',
                                                doc_url: '',
                                                doc_name: ''
                                            });
                                        }}
                                    >
                                        <Add className="mr-2" />
                                        Add a new document
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="mt-5 py-4 border-top mb-sm-12 d-flex align-items-center justify-content-center btn-submit-post w-100">
                            <Button
                                color="secondary"
                                variant="text"
                                type="button"
                                className={`fs-16 text-inherit`}
                                onClick={() => handleBack(true)}
                            >
                                Back
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onSave}
                                disabled={isLoading}
                                className="fs-16 text-inherit btn-submit-style ml-5"
                            >
                                Next{' '}
                                {isLoading ? (
                                    <CircularProgress
                                        size="1.3rem"
                                        className="circular-progress"
                                    />
                                ) : null}
                            </Button>
                        </div>
                    </form>
                </div>
            </Grid>
        </Container>
    );
};

export default connect(null, {
    CommonDispatcher,
    UploadDocument,
    UpdateDeal,
    UploadImages
})(StepFiveComponent);
