import axios from 'axios';
import { API_PATH } from '../../../../constants';
import { SHOW_SNACKBAR } from '../../../../redux/Common/actionTypes';
import { SET_FUND_EQUITY_DEAL_DATA } from '../../../../redux/FundDeals/actionTypes';

export const PublishPost = (
    PublishFundEquityPost,
    CommonDispatcher,
    payload,
    UploadImages,
    setLoading,
    next,
    setWizardError
) => {
    payload.is_draft = true;

    let data: any;
    if (payload?.company_logo) {
        const { images, company_logo, currentStep, ...restData } = payload;
        data = restData;
    } else {
        const { images, currentStep, ...restData } = payload;
        data = restData;
        data.company_logo = null;
    }

    PublishFundEquityPost(data).then((r) => {
        if (r?.type === 'success') {
            payload.equity_id = r?.response?.equity_id;
            CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, payload);
            uploadImagesData(
                UploadImages,
                CommonDispatcher,
                r?.response?.equity_id,
                payload,
                setLoading,
                next,
                setWizardError
            );
        } else {
            setLoading(false);
        }
    });
};

export const UpdatePost = (
    UpdateStartupEquityPost,
    CommonDispatcher,
    payload,
    UploadImages,
    setLoading,
    next,
    setWizardError,
    is_draft = true,
    isLastStep = false
) => {
    payload.is_draft = is_draft;
    let data: any;
    if (payload?.company_logo) {
        const { images, currentStep, company_logo_short, ...restData } =
            payload;
        if (
            payload?.images &&
            payload?.images.length &&
            payload?.images?.[0]?.src.startsWith('data')
        ) {
            let {
                images,
                currentStep,
                company_logo,
                company_logo_short,
                ...restAfterRemovingLogo
            } = payload;
            data = restAfterRemovingLogo;
        } else {
            restData.company_logo = payload?.company_logo_short;
            data = restData;
        }
    } else {
        const { images, currentStep, company_logo_short, ...restData } =
            payload;
        data = restData;
        if (!isLastStep) {
            // data.company_logo = null;
        }
    }
    UpdateStartupEquityPost(data).then((r) => {
        if (r?.type === 'success') {
            payload.equity_id = r?.response?.equity_id;
            uploadImagesData(
                UploadImages,
                CommonDispatcher,
                r?.response?.equity_id,
                payload,
                setLoading,
                next,
                setWizardError
            );
        } else if (r?.type === 'error' && r?.response?.data?.error) {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: r?.response?.data?.error,
                hideAlert: true,
                error: true
            });
            setLoading(false);
        } else {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: 'Failed to update the deal',
                hideAlert: true,
                error: true
            });
            setLoading(false);
        }
    });
};

const uploadImagesData = (
    UploadImages,
    CommonDispatcher,
    equity_id,
    payload,
    setLoading,
    next,
    setWizardError
) => {
    const images = payload?.images;
    if (
        images &&
        images?.length &&
        images[0]?.apiName &&
        payload?.currentStep !== 4
    ) {
        UploadImages(getImages(images, equity_id)).then((d) => {
            payload.company_logo = d?.response?.file_url;
            CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, payload);
            setLoading(false);
            if (d?.type === 'success') {
                next();
                setWizardError();
            }
        });
    } else {
        CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, payload);
        setLoading(false);
        next();
        setWizardError();
    }
};

const getImages = (images, equity_id) => {
    let formData = new FormData();
    formData.append('type', 'FUND');
    formData.append('equity_id', equity_id);
    images.forEach((image: any) => {
        formData.append('files', image.file as any, image.apiName);
    });
    return formData;
};

export const investingAssetClassData = async (token) => {
    try {
        const config = {
            method: 'get',
            url: `${API_PATH}/metadata/funding_investing_asset_class_master`,
            headers: { Authorization: token }
        };
        let response = (await axios(config as any))?.data?.data
            ?.FundingInvestingAssetClassMaster;
        if (response && response?.length) {
            response = response
                .map((d) => ({
                    title: d?.name,
                    value: d?.id
                }))
                .sort((a, b) => a?.value - b?.value);
        }

        return response;
    } catch (error) {
        return { type: 'failed', response: error?.response };
    }
};
export const GetBusinessModelMaster = async (token) => {
    try {
        const config = {
            method: 'get',
            url: `${API_PATH}/metadata/buisness_model_master`,
            headers: { Authorization: token }
        };
        let response = (await axios(config as any))?.data?.data?.buisnessModel;
        if (response && response?.length) {
            response = response
                .map((d) => ({
                    title: d?.name,
                    value: d?.id
                }))
                .sort((a, b) => a?.value - b?.value);
        }

        return response;
    } catch (error) {
        return { type: 'failed', response: error?.response };
    }
};
export const GetStagesTarget = async (token) => {
    try {
        const config = {
            method: 'get',
            url: `${API_PATH}/metadata/stages`,
            headers: { Authorization: token }
        };
        let response = (await axios(config as any))?.data?.data?.stages;
        if (response && response?.length) {
            response = response
                .map((d) => ({
                    title: d?.label,
                    value: d?.funding_stage_id
                }))
                .sort((a, b) => a?.value - b?.value);
        }

        return response;
    } catch (error) {
        return { type: 'failed', response: error?.response };
    }
};

export const GetSdgGoals = async (token) => {
    try {
        const config = {
            method: 'get',
            url: `${API_PATH}/metadata/sdg-sectors`,
            headers: { Authorization: token }
        };
        let response = (await axios(config as any))?.data?.data?.stages;
        if (response && response?.length) {
            response = response
                .map((d) => ({
                    title: d?.label,
                    value: d?.sdg_sector_id
                }))
                .sort((a, b) => a?.value - b?.value);
        }

        return response;
    } catch (error) {
        return { type: 'failed', response: error?.response };
    }
};
export const GetRegions = async (token) => {
    try {
        const config = {
            method: 'get',
            url: `${API_PATH}/metadata/regions`,
            headers: { Authorization: token }
        };
        let response = (await axios(config as any))?.data?.data?.regions;
        if (response && response?.length) {
            response = response
                .map((d) => ({
                    id: d?.region_id,
                    title: d?.label,
                    countries: d?.countries
                }))
                .sort((a, b) => a?.value - b?.value);
        }

        return response;
    } catch (error) {
        return { type: 'failed', response: error?.response };
    }
};
