import { Chip, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import { ACCOUNT_TYPE } from '../../../utils/constants';
import { VerifyNow } from '../Common/VerifyNow';
import {
    AssignmentTurnedIn,
    FileCopy,
    NoteAdd,
    NoteAddOutlined
} from '@material-ui/icons';
import { CommonDispatcher } from '../../../redux/Common/action';
import { connect } from 'react-redux';
import { ADD_ADDRESS_BOOK_NOTE_MODAL } from '../../../redux/ModalReducer/actionTypes';
import style from './Style.module.scss';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { copyToClipboard } from '../../../utils/common';

const TableCells = ({
    row,
    renderMenu,
    handleVerify,
    CommonDispatcher
}: any) => {
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleAddNoteModal = (type, title, address_book) => {
        CommonDispatcher(type, {
            isOpen: true,
            title,
            data: {
                disableBackdropClick: true,
                data: { address_book }
            },
            submitTitle: 'Save',
            cancelTitle: 'Cancel'
        });
    };
    const renderAddNote = (d) => {
        return (
            <div className={style.addNote}>
                <IconButton
                    aria-label="edit"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleAddNoteModal(
                            ADD_ADDRESS_BOOK_NOTE_MODAL,
                            `Note`,
                            d
                        );
                    }}
                >
                    <NoteAddOutlined fontSize="medium" className="colorGray" />
                </IconButton>
            </div>
        );
    };

    const renderNote = (d) => {
        return (
            <div className={style.noteAdded}>
                <IconButton
                    aria-label="edit"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleAddNoteModal(
                            ADD_ADDRESS_BOOK_NOTE_MODAL,
                            `Note`,
                            d
                        );
                    }}
                >
                    <NoteAdd fontSize="medium" className="colorPurple" />
                </IconButton>
            </div>
        );
    };
    const handleCopy = (data) => {
        const flag = copyToClipboard(data);
        CommonDispatcher(SHOW_SNACKBAR, {
            isShowing: !!flag,
            message: 'Copied to clipboard',
            hideAlert: true,
            error: true
        });
    };
    return (
        <>
            <TableCell size={'small'} onClick={(e) => e.stopPropagation()}>
                {renderMenu(row?.user_id, row?.user_name, row)}
            </TableCell>
            <TableCell style={{ minWidth: '100px' }} size={'small'}>
                {moment(row?.createdAt).format('DD MMM, YYYY')}
            </TableCell>
            <TableCell size={'small'}>
                <div className="d-flex flex-direction-column align-items-center">
                    {row?.ecosytem_type_text ? (
                        <div className="mb-2">{row?.ecosytem_type_text}</div>
                    ) : null}
                    {row?.type ? (
                        <Chip
                            size="small"
                            label={row?.type}
                            color={
                                row?.type === 'INVESTOR'
                                    ? 'primary'
                                    : 'secondary'
                            }
                            clickable
                        />
                    ) : (
                        '-'
                    )}
                </div>
            </TableCell>
            <TableCell size={'small'}>
                {row?.admin_status ? (
                    <Chip
                        size="small"
                        label={row?.admin_status}
                        color={row?.admin_status ? 'primary' : 'secondary'}
                        clickable
                    />
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell size={'small'}>
                <Chip
                    size="small"
                    label={row?.is_profile_complete ? 'Complete' : 'Incomplete'}
                    color={row?.is_profile_complete ? 'primary' : 'secondary'}
                    clickable
                />
            </TableCell>
            <TableCell size={'small'}>{row?.country || '-'}</TableCell>
            <TableCell size={'small'}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-direction-column">
                        {row?.full_name || '-'}
                        {row?.is_easy_submission_done ? (
                            <span className="mt-2 d-flex align-items-center colorPurple">
                                <AssignmentTurnedIn
                                    fontSize={'small'}
                                    className="mr-2"
                                />
                                Easy Submission
                            </span>
                        ) : null}
                    </div>
                    {!isTabletScreen ? (
                        <>{row?.note ? renderNote(row) : renderAddNote(row)}</>
                    ) : null}
                </div>
            </TableCell>
            <TableCell size={'small'}>{row?.company_name || '-'}</TableCell>
            <TableCell size={'small'} onClick={(e) => e.stopPropagation()}>
                <div className="d-flex flex-direction-column align-items-center">
                    <div>
                        {row?.email || '-'}
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopy(row?.email);
                            }}
                            className=" ml-1"
                        >
                            <FileCopy />
                        </IconButton>
                    </div>
                    {row?.email ? (
                        <span className="mt-2">
                            <VerifyNow
                                status={row?.status}
                                handleVerify={() =>
                                    handleVerify(
                                        {
                                            user_id: row?.user_id,
                                            page:
                                                row?.type ===
                                                ACCOUNT_TYPE?.ECOSYSTEM
                                                    ? 6
                                                    : 4,
                                            status: 'VERIFIED'
                                        },
                                        'EMAIL'
                                    )
                                }
                            />
                        </span>
                    ) : null}
                </div>
            </TableCell>
            <TableCell size={'small'} onClick={(e) => e.stopPropagation()}>
                <div className="d-flex flex-direction-column align-items-center">
                    {row?.phone || '-'}
                    {row?.phone ? (
                        <span className="mt-2">
                            <VerifyNow
                                status={row?.phone_status}
                                handleVerify={() =>
                                    handleVerify(
                                        {
                                            user_id: row?.user_id,
                                            phone_status: 'VERIFIED'
                                        },
                                        'PHONE'
                                    )
                                }
                            />
                        </span>
                    ) : null}
                </div>
            </TableCell>
        </>
    );
};
export default connect(null, {
    CommonDispatcher
})(TableCells);
