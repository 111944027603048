import { Table } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { MoreVert } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROWS_PER_PAGE, UsersTab } from '../../../constants';
import { OpenModal } from '../../../redux/Common/action';
import {
    AFFILIATED_MODAL,
    OPEN_ALERT_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import {
    DeleteUser,
    GetUsersList,
    UpdateUserPermission,
    UpdateUserProfile
} from '../../../redux/Users/actions';
import { getQuery } from '../../../utils/common';
import {
    ACTIVE,
    AWAITING_APPROVAL,
    DECLINED,
    ENV,
    LIVE_DOMAIN,
    SUSPENDED,
    TEST_DOMAIN
} from '../../../utils/constants';
import { CommonTableStyle } from '../../Common/CommonTableStyle';
import TableHeaderFilter from '../../Common/TableHeaderFilter';
import { OpenAlert, USERS_MENU_OPTIONS } from '../Common';
import { CommonPaginationAction } from '../Common/CommonPaginationAction';
import TableCells from '../TableCells';

const AwaitingApprovalComponent = ({
    sectionBaseUrl,
    isMainFilterApplied,
    getAllTabsData,
    GetUsersList,
    UpdateUserProfile,
    UpdateUserPermission,
    OpenModal,
    DeleteUser,
    loading
}: any) => {
    const classes = CommonTableStyle();
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(isMainFilterApplied);
    const [selectedUser, setSelectedUser] = useState<any>({});
    console.log(`🚀 ~ selectedUser:`, selectedUser);
    const tableHeader = useSelector(
        (store: RootState) => store?.users.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.users.unApprovedUsersList
    );
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const history = useHistory();
    const query = getQuery(history);
    const type = query.get('type');
    const keyword = query.get('keyword');
    const [page, setPage] = useState(0);
    const count = listingsData?.count;
    const total_pages = listingsData?.total_pages;

    useEffect(() => {
        if (
            !isFilterApplied &&
            (keyword || type || typeof page !== 'undefined')
        ) {
            getData({
                keyword,
                type,
                page: page + 1,
                tab: UsersTab.TYPE_UN_APPROVED
            });
        }
        // eslint-disable-next-line
    }, [keyword, type, page]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const getData = (data) => {
        data.size = ROWS_PER_PAGE;
        setIsLoading(true);
        GetUsersList(token, data).then(() => {
            setIsLoading(false);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setIsFilterApplied(false);
    };

    const CommonComponent = () => (
        <CommonPaginationAction
            count={count}
            page={page}
            setPage={setPage}
            total_pages={total_pages}
        />
    );

    const handleChange = (value, selectedUser) => {
        switch (value) {
            case 'profile':
                const url =
                    ENV === 'DEVELOPMENT'
                        ? `${TEST_DOMAIN}/${selectedUser?.user_name}`
                        : `${LIVE_DOMAIN}/${selectedUser?.user_name}`;
                window.open(url, '_blank');
                break;
            case 'editUser':
                history.push(`/users/edit-user/${selectedUser?.user_id}`);
                break;
            case 'editStrategy':
                history.push(`/users/edit-strategy/${selectedUser?.user_id}`);
                break;
            case 'Affiliated':
                OpenModal(AFFILIATED_MODAL, {
                    title: 'Add Affiliation',
                    isOpen: true,
                    data: { selectedUser }
                });
                break;
            case 'approve':
                OpenAlert({
                    title: 'Approve',
                    description: 'Are you sure you want to approve this user?',
                    submitTitle: 'Approve',
                    token,
                    payload: {
                        user_id: selectedUser?.user_id,
                        admin_status: ACTIVE
                    },
                    tab: UsersTab.TYPE_UN_APPROVED,
                    OpenModal,
                    UpdateUserProfile,
                    getData: getAllTabsData,
                    keyword,
                    type,
                    page
                });
                break;
            case 'decline':
                OpenAlert({
                    title: 'Decline',
                    description: 'Are you sure you want to decline this user?',
                    submitTitle: 'Decline',
                    token,
                    payload: {
                        user_id: selectedUser?.user_id,
                        admin_status: DECLINED
                    },
                    tab: UsersTab.TYPE_UN_APPROVED,
                    OpenModal,
                    UpdateUserProfile,
                    getData: getAllTabsData,
                    keyword,
                    type,
                    page
                });
                break;
            case 'suspend':
                OpenAlert({
                    title: 'Suspend',
                    description: 'Are you sure you want to Suspend this users?',
                    submitTitle: 'Suspend',
                    token,
                    tab: UsersTab.TYPE_UN_APPROVED,
                    payload: {
                        user_id: selectedUser?.user_id,
                        admin_status: SUSPENDED
                    },
                    OpenModal,
                    UpdateUserProfile,
                    getData: getAllTabsData,
                    keyword,
                    type,
                    page
                });
                break;
            case 'delete':
                if (
                    window?.confirm(
                        'Are you sure you want to delete this user?'
                    )
                ) {
                    DeleteUser(token, selectedUser?.user_id)
                        .then(() =>
                            getAllTabsData({
                                keyword,
                                type,
                                page,
                                tab: UsersTab.TYPE_UN_APPROVED
                            })
                        )
                        .catch(() => {
                            alert('Something went wrong.');
                        });
                }
                break;
        }
    };

    const renderMenu = (user_id, user_name, row) => {
        return (
            <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) =>
                        handleClick(e, {
                            user_id,
                            user_name,
                            ...row
                        })
                    }
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    elevation={0}
                    PaperProps={{
                        style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            border: '1px solid rgb(0 106 194 / 20%)'
                        }
                    }}
                >
                    {USERS_MENU_OPTIONS.map((option, i) => (
                        <MenuItem
                            key={option?.value}
                            onClick={() => {
                                handleChange(option?.value, selectedUser);
                                setAnchorEl(null);
                            }}
                            disabled={
                                option?.value === 'approve' &&
                                !(
                                    selectedUser?.admin_status ===
                                        AWAITING_APPROVAL &&
                                    selectedUser?.is_profile_complete &&
                                    selectedUser?.status === 'VERIFIED'
                                )
                            }
                        >
                            {option?.title}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    };

    const handleVerify = (payload, type) => {
        OpenAlert({
            title: `Verify ${type === 'EMAIL' ? 'Email' : 'Phone'}`,
            description: `Are you sure you want to verify ${
                type === 'EMAIL' ? 'email' : 'phone'
            }?`,
            submitTitle: 'Verify',
            token,
            payload,
            OpenModal,
            UpdateUserProfile,
            getData,
            keyword,
            type,
            page,
            tab: UsersTab.TYPE_UN_APPROVED
        });
    };

    const handleStoryPost = (allow_story_post, user_id) => {
        if (allow_story_post !== null) {
            OpenAlert({
                title: `${
                    allow_story_post ? 'Activate' : 'Deactivate'
                } Post Stories`,
                description: `Are you sure you want to ${
                    allow_story_post ? 'Activate' : 'Deactivate'
                } Post Stories for this user?`,
                submitTitle: `${allow_story_post ? 'Activate' : 'Deactivate'}`,
                token,
                payload: {
                    user_id,
                    allow_story_post
                },
                OpenModal,
                UpdateUserProfile,
                getData,
                keyword,
                type,
                page,
                tab: UsersTab.TYPE_UN_APPROVED
            });
        }
    };

    const handleCommonUpdater = ({
        title,
        description,
        submitTitle,
        payload
    }) => {
        OpenModal(OPEN_ALERT_MODAL, {
            isOpen: true,
            title,
            description,
            data: { disableBackdropClick: true },
            submitTitle,
            cancelTitle: 'No',
            callBack: () => {
                UpdateUserPermission(token, payload)
                    .then(() =>
                        getData({
                            keyword,
                            type,
                            page: page + 1,
                            tab: UsersTab.TYPE_UN_APPROVED
                        })
                    )
                    .catch(() => {
                        OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
                    });
            }
        });
    };

    return (
        <Grid container spacing={0} className="h-100">
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <TableHeaderFilter sectionBaseUrl={sectionBaseUrl} />
                    {loading || isLoading ? (
                        <div className="mainLoader">
                            <div className="loader" />
                        </div>
                    ) : (
                        <>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {tableHeader?.map((column) => (
                                                <TableCell key={column.id}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listingsData?.posts &&
                                        listingsData?.posts.length ? (
                                            listingsData?.posts?.map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row?.user_id}
                                                        style={{
                                                            padding: 0,
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => {
                                                            history.push(
                                                                `/users/view/${row?.user_id}`
                                                            );
                                                        }}
                                                    >
                                                        <TableCells
                                                            row={row}
                                                            renderMenu={
                                                                renderMenu
                                                            }
                                                            handleVerify={
                                                                handleVerify
                                                            }
                                                            handleStoryPost={
                                                                handleStoryPost
                                                            }
                                                            handleCommonUpdater={
                                                                handleCommonUpdater
                                                            }
                                                        />
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={
                                                        tableHeader.length + 1
                                                    }
                                                    className="dataNotAvailable"
                                                >
                                                    Data not available
                                                </td>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className={classes.footer}
                                hidden={
                                    listingsData?.posts &&
                                    !listingsData?.posts.length
                                }
                                rowsPerPageOptions={[]}
                                component="div"
                                count={count}
                                rowsPerPage={listingsData?.page_size}
                                page={page}
                                ActionsComponent={CommonComponent}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
export default connect(null, {
    GetUsersList,
    UpdateUserProfile,
    OpenModal,
    UpdateUserPermission,
    DeleteUser
})(AwaitingApprovalComponent);
